import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaFileCsv } from "react-icons/fa6";
import "../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Select from "react-select";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import sampleCSVFile from "../CSVSample/OrderDetailsData.csv";
import { OrderListTable } from "../store/tableConstant";
import * as XLSX from "xlsx";
import { processOnData } from "./DataImport/DataImportProcess.js";
import TopShowMessage from "../component/TopShowMessage";
import Modal from "react-modal";
import { FaPlusCircle, FaEye, FaTrash } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import {
  FaRegTimesCircle,
  FaRegEye,
  FaSave,
  FaRegFilePdf,
} from "react-icons/fa";
import Swal from "sweetalert2";
import { formatDate } from "../component/common.js";

const customStylesDistributor = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "40%",
    padding: 20,
  },
};

const customStylesDisbursement = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "25%",
    padding: 20,
    maxHeight: '80vh',
    overflowY: 'auto',
    padding: '20px'
  },
};

const closeButtonStyle = {
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  zIndex: 1010,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
  control: (provided) => ({
    ...provided,
    border: "none", // Remove border
    boxShadow: "none", // Remove the box shadow
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none", // Remove the dropdown arrow
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none", // Remove the separator line
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 150, // Adjust as needed
  }),
};

function OrderDetailsPage(props) {
  // const userId = props && props.location.state && props.location.state.userId;
  // const orderId = props && props.location.state && props.location.state.orderId;
  const userRole = localStorage.getItem("USER_ROLE");
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get("userId");
  const orderId = searchParams.get("orderId");
  const [merchantUserData, setMerchantUserData] = useState();
  const [orderImageData, setOrderImageData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userInvoiceData, setUserInvoiceData] = useState();
  const [modalIsDisbursementApproval, setModalIsDisbursementApproval] = React.useState(false);
  const [disbursementApproval, setDisbursementApproval] = useState();
  const [approvalStatus, setApprovalStatus] = useState("Pending");
  const [invoiceUserData,setInvoiceUserData] = useState();
  const [modalIsQuotationOpen, setModalIsQuotationOpen] = useState(false);
  const [modalIsDisbursement, setModalIsDisbursement] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [distributorUserData, setDistributorUserData] = useState([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [viewOrderImageModal, setViewOrderImageModal] = useState();
  const [totalRoiRows, setTotalRoiRows] = useState([{ id: 1 }]);
  const [errors, setErrors] = useState({});
  const [selectedDistributor, setSelectedDistributor] = useState();
  const [selectedBrands, setSelectedBrands] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [brandListData, setBrandListData] = useState();
  const [invoiceDetailsData, setInvoiceDetailsData] = useState();
  const [distributorResponseData, setDistributorResponseData] = useState();
  const [invoiceDistributorData, setInvoiceDistributorData] = useState([]);
  const [selectedDistributorName, setSelectedDistributorName] = useState(null);
  const [programData, setProgramData] = useState([]);
  const [disbursementInvoiceId, setDisbursementInvoiceId] = useState();
  const [checkFiles, setCheckFiles] = useState([]);
  const [fileInputs, setFileInputs] = useState([{ id: 1 }]);
  const [disbusApproval, setDisbusApproval] = useState([]);
  const [files, setFiles] = useState([]);
  console.log("invoiceDistributorData++++",invoiceDistributorData)
  const [formData, setFormData] = useState({
    orderQuotation: [
      {
        brandName: null,
        item: null,
        categoryName: null,
        quantity: null,
        rate: null,
        sGST: "9",
        cGST: "9",
        totalAmount: null,
        // mrp: null,
        distributorId: null,
        brandId: null,
        productCategoryId: null,
        buyingPricePerItem: null,
        totalBuyingAmount: null,
        totalSellingAmount: null,
        margin: null,
        listingPrice: null,
        promotionalDiscount: null,
        discountToMerchant: null,
        discountToDaqi: null,
        isGstApplicable: 1,
      },
    ],
  });
  console.log("invoiceDetailsData++++", disbursementInvoiceId);

  const [formDistributorData, setFormDistributorData] = useState({
    invoiceNumber: "",
    invoiceAmount: "",
    invoiceDate: "",
    invoiceFile: "",
    invoiceFileBase64: "",
  });
  const [formDisbursementData, setFormDisbursementData] = useState({
    disbursedDate: "",
    utr: "",
    disbursementAmount: "",
    disbursementInvoiceId: "",
    disbursementDistributor: "",
    toDistributorDisbursementAmount: "",
    totalDisbursementAmount: "",
    lenderName: "",
  });


  const handleChange = (e) => {
    const { name, files } = e.target;
    if (name === "invoiceFile" && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result.replace(
          "data:application/pdf;base64,",
          ""
        );
        if (base64String) {
          getFileInvoiceData(base64String);
        }
        setFormDistributorData({
          ...formDistributorData,
          invoiceFileBase64: base64String,
        });
      };
    } else {
      setFormDistributorData({
        ...formDistributorData,
        [name]: e.target.value,
      });
    }
  };

  const handleDisbursedChange = (e) => {
    const { name } = e.target;
    setFormDisbursementData({
      ...formDisbursementData,
      [name]: e.target.value,
    });
  };

  const updateDisbursementApproval = async () => {
    let filterData = {
      orderId: orderId,
      userId: userId,
      isDisbursementApproved: approvalStatus,
    };
    setIsLoadingOn(true);
    const tempData = await postData("order/updateDisbursementApproval", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setDisbursementApproval(false);
      setShowTopMessage(false);
      closeModal();
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };

  const handleUpdateDisbursedInvoice = async (e) => {
    e.preventDefault();
    if (formDisbursementData.disbursementAmount >= 50000 && checkFiles.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please upload at least 1 and up to 5 files for disbursement amount is greater than or equal to 50000',
      });
      return;
    }

    if (formDisbursementData) {
      const checkDocument = checkFiles.map(file => file.base64);
      let tempDataSend = {
        invoiceId: disbursementInvoiceId,
        utr: formDisbursementData.utr,
        disbursedDate: formDisbursementData.disbursedDate,
        checkDocument: checkDocument,
        tableReference: "ORDER",
      };
      setIsLoadingOn(true);
      const tempData = await postData(`loan/setDisbursement`, tempDataSend);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        getDetailsDataDistributor(orderId);
        setModalIsDisbursement(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
      }
    }
  };

  const handleFileChange = (e, inputId) => {
    const selectedFiles = Array.from(e.target.files);
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

    if (selectedFiles.length + checkFiles.length > 5) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can upload a maximum of 5 files only.',
      });
      return;
    }

    const invalidFiles = selectedFiles.filter((file) => !allowedTypes.includes(file.type));
    if (invalidFiles.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please select valid file formats (.pdf, .jpg, .jpeg, .png)',
      });
      e.target.value = '';
      return;
    }

    const promises = selectedFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64Files) => {
        setCheckFiles((prevFiles) => [...prevFiles, ...base64Files.map((base64) => ({ inputId, base64 })),]);
      })
      .catch((error) => {
        console.error('Error reading file:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while reading the file',
        });
      });
  };

  const handleAddFileInput = () => {
    if (fileInputs.length < 5) {
      setFileInputs((prevInputs) => [
        ...prevInputs,
        { id: prevInputs[prevInputs.length - 1].id + 1 },
      ]);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'You can only upload a maximum of 5 files.',
      });
    }
  };

  const handleRemoveFileInput = (inputId) => {
    // Remove the input field
    setFileInputs((prevInputs) =>
      prevInputs.filter((input) => input.id !== inputId)
    );

    // Remove the corresponding files associated with the inputId
    setCheckFiles((prevFiles) =>
      prevFiles.filter((file) => file.inputId !== inputId)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formDistributorData) {
      let tempDataSend = {
        userId: userId,
        referenceId: invoiceDetailsData.orderId,
        referenceTable: "ORDER",
        distributorId: invoiceDetailsData.distributorId,
        invoiceAmount: formDistributorData.invoiceAmount,
        invoiceDate: formDistributorData.invoiceDate,
        invoiceNumber: formDistributorData.invoiceNumber,
        file: formDistributorData.invoiceFileBase64,
      };
      setIsLoadingOn(true);
      const tempData = await postData(
        `documents/insertInvoiceWithoutExtraction`,
        tempDataSend
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setActionModalOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
        getDetailsDataDistributor(orderId);
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: tempData?.data?.errors.msg || "Somthing went wrong",
        });
      }
    }
  };

  const handleSelectChange = async (key, selectedOption, index) => {
    console.log("select---+++", key, selectedOption);
    const value =
      selectedOption && selectedOption.value !== undefined
        ? selectedOption.value
        : selectedOption;

    if (key === "distributorId") {
      await getBrandsData(value);
    }
    if (key === "distributorId" || key === "brandName") {
      const distributorId =
        key === "distributorId"
          ? value
          : formData.orderQuotation[index].distributorId;
      const brandId =
        key === "brandName" ? value : formData.orderQuotation[index].brandId;

      if (distributorId && brandId) {
        await getProductCategoryData(distributorId, brandId);
      }
    }
    if (
      key === "distributorId" ||
      key === "brandName" ||
      key === "categoryName"
    ) {
      const distributorId =
        key === "distributorId"
          ? value
          : formData.orderQuotation[index].distributorId;
      const brandId =
        key === "brandName" ? value : formData.orderQuotation[index].brandId;
      const productCategoryId =
        key === "categoryName"
          ? value
          : formData.orderQuotation[index].productCategoryId;

      if (distributorId && brandId && productCategoryId) {
        // await getProgramBrands(distributorId, brandId, productCategoryId);
        let programRes = await getProgramBrands(
          distributorId,
          brandId,
          productCategoryId
        );
        console.log(" testvv0", programRes);
        if (programRes.statusCode === 200) {
          setProgramData((prevState) => [...prevState, ...programRes.data]);
        } else {
          setProgramData(null);
        }
      }
    }

    setFormData((prevState) => {
      if (prevState.orderQuotation) {
        return {
          ...prevState,
          orderQuotation: prevState.orderQuotation.map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [key]: selectedOption.label,
                brandId: key === "brandName" ? value : item.brandId,
                productCategoryId:
                  key === "categoryName" ? value : item.productCategoryId,
                distributorId:
                  key === "distributorId" ? value : item.distributorId,
              };
            }
            return item;
          }),
        };
      }
      return prevState;
    });
  };

  const handleUpdateRowChange = (event, index, field) => {
    console.log(" programData 0 ", programData);
    const { name, value } = event.target;
    setFormData((prevState) => {
      const updatedOrderQuotation = [...prevState.orderQuotation];
      updatedOrderQuotation[index] = {
        ...updatedOrderQuotation[index],
        [name]: value,
      };

      const {
        quantity,
        rate,
        sGST,
        cGST,
        buyingPricePerItem,
        listingPrice,
        promotionalDiscount,
      } = updatedOrderQuotation[index];
      let tempPromotionalDiscount = promotionalDiscount
        ? promotionalDiscount
        : 0;
      let tmpRate;
      let tempBuyingItem;
      let tempTotalAmount;
      let tempMargin;
      let totalbuying;

      if (Array.isArray(programData) && programData.length > 0) {
        let data = programData[index];

        if (data) {
          let tempdiscountToMerchant =
            Number(data.discountToMerchant || 0) +
            Number(tempPromotionalDiscount || 0);
          tmpRate =
            listingPrice - listingPrice * (tempdiscountToMerchant / 100);
          tempBuyingItem =
            listingPrice -
            (listingPrice * (Number(data.discountToDaqi) || 0)) / 100;
          tempTotalAmount = Number(quantity) * tmpRate;
          totalbuying = Number(tempBuyingItem) * Number(quantity);
          tempMargin = tempTotalAmount - totalbuying;

          updatedOrderQuotation[index] = {
            ...updatedOrderQuotation[index],
            rate: tmpRate,
            totalAmount: tempTotalAmount,
            totalBuyingAmount: totalbuying,
            margin: tempMargin,
            discountToMerchant: data.discountToMerchant,
            discountToDaqi: data.discountToDaqi,
          };

          console.log(`tmpRate: ${tmpRate}`);
          console.log(`tempBuyingItem: ${tempBuyingItem}`);
          console.log(`tempTotalAmount: ${tempTotalAmount}`);
          console.log(`tempMargin: ${tempMargin}`);
        } else {
          console.error(`Data at index ${index} is undefined.`);
        }
      } else {
        console.error("programData is not a valid array or is empty.");
      }

      return {
        ...prevState,
        orderQuotation: updatedOrderQuotation,
      };
    });
  };

  const totalRoiAddRow = () => {
    const newRow = { id: totalRoiRows.length + 1 };
    setTotalRoiRows([...totalRoiRows, newRow]);
    setFormData((prevState) => ({
      ...prevState,
      orderQuotation: [
        ...prevState.orderQuotation,
        {
          brandName: null,
          item: null,
          categoryName: null,
          quantity: null,
          rate: null,
          sGST: "9",
          cGST: "9",
          totalAmount: null,
          distributorId: null,
          brandId: null,
          productCategoryId: null,
          // buyingPricePerItem: null,
          totalBuyingAmount: null,
          // totalSellingAmount: null,
          margin: null,
          listingPrice: null,
          promotionalDiscount: null,
          discountToMerchant: null,
          discountToDaqi: null,
          isGstApplicable: 1,
        },
      ],
    }));
  };

  const totalRoiDeleteRow = (id, index) => {
    const updatedRows = totalRoiRows.filter((row) => row.id !== id);
    setTotalRoiRows(updatedRows);

    setFormData((prevState) => ({
      ...prevState,
      orderQuotation: prevState.orderQuotation.filter(
        (row, innerIndex) => innerIndex !== index
      ),
    }));
  };

  const viewImageOrder = (image) => {
    setViewOrderImageModal(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalIsQuotationOpen(false);
    setActionModalOpen(false);
    setDisbursementApproval(false);
    setModalIsDisbursementApproval(false);
    setModalIsDisbursement(false);
  };

  const handleOpenModalDetails = (item) => {
    if (item) {
      setFormDistributorData({
        invoiceNumber: item ? item.invoiceNumber : "",
        invoiceAmount: item ? item.invoiceAmount : "",
        invoiceDate: item ? item.invoiceDate : "",
        invoiceFile: "",
        invoiceFileBase64: "",
      });
      setInvoiceDetailsData(item);
    }
    setActionModalOpen(true);
  };

  async function DistributorGetData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/distributorUser/1");
    setIsLoadingOn(false);
    const tempDistData = [];
    if (tempData.statusCode == 200) {
      for (let i = 0; i < tempData.data.records.length; i++) {
        const element = tempData.data.records[i];
        tempDistData.push({
          value: element.id,
          label: element.name + "-" + element.id,
        });
      }
      setDistributorUserData(tempDistData);
    }
  }
  async function getProgramBrands(distributorId, brandId, productCategoryId) {
    setIsLoadingOn(true);
    // alert("Test");
    console.log("branID", brandId);
    console.log("distributorId", distributorId);
    console.log("productCategoryId", productCategoryId);
    const tempData = await getData(
      `users/getProgramBrands/nothing?distributorId=${distributorId}&brandId=${brandId}&productCategoryId=${productCategoryId}`
    );
    setIsLoadingOn(false);
    // const actualData = tempData.data[0];
    // const discountToDaqi = actualData.discountToDaqi;
    // console.log("NewAPI",discountToDaqi);
    return tempData;
  }
  async function getProductCategoryData(distributorId, brandId) {
    setIsLoadingOn(true);
    const tempData = await getData(
      `users/getProductCategoryForProgram/nothing?distributorId=${distributorId}&brandId=${brandId}`
    );
    setIsLoadingOn(false);
    console.log(brandId);
    console.log(distributorId);
    const tempDistData = [];
    if (tempData.statusCode === 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        tempDistData.push({
          value: element.id,
          label: element.name,
        });
      }
      setProductCategoryData(tempDistData);
    } else {
      Swal.fire({
        icon: "error",
        title: "No Product CategoryId Data For This Distributor and Brand",
      });
    }
  }
  async function getBrandsData(distributorId) {
    setIsLoadingOn(true);
    const tempData = await getData(
      `users/getBrandForProgram/nothing?distributorId=${distributorId}`
    );
    console.log(distributorId);

    setIsLoadingOn(false);
    const tempDistData = [];
    if (tempData.statusCode === 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        tempDistData.push({
          value: element.id,
          label: element.name,
        });
        console.log(tempDistData);
      }
      setBrandListData(tempDistData);
    }
  }

  useEffect(() => {
    DistributorGetData();
  }, []);

  const validateForm = () => {
    const errors = {};

    if (!formData.orderQuotation.every((order) => order.distributorId)) {
      errors.distributorId = "Please Select Distributor";
    }
    if (!formData.orderQuotation.every((order) => order.brandName)) {
      errors.brandName = "Please Select Brand Name";
    }
    if (!formData.orderQuotation.every((order) => order.item)) {
      errors.item = "Please Enter Item";
    }
    if (!formData.orderQuotation.every((order) => order.categoryName)) {
      errors.categoryName = "Please Select Category";
    }
    if (!formData.orderQuotation.every((order) => order.quantity)) {
      errors.quantity = "Please Enter Quantity";
    }
    if (!formData.orderQuotation.every((order) => order.rate)) {
      errors.rate = "Please Enter Rate";
    }

    if (!formData.orderQuotation.every((order) => order.totalBuyingAmount)) {
      errors.totalBuyingAmount = "Please Enter Total Buying Amount";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleShowDisbursementData = (item) => {
    setFormDisbursementData({
      disbursementDistributor: item ? item.distributorName : "",
      disbursementInvoiceId: item ? item.invoiceId : "",
      disbursementAmount: item ? item.invoiceAmount : "",
      toDistributorDisbursementAmount: item ? item.toDistributorDisbursementAmount : "",
      totalDisbursementAmount: item ? item.totalDisbursementAmount : "",
      lenderName: item ? item.lenderName : "",
    });
    setModalIsDisbursement(true);
  };

  const handelUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("Testing------");
    if (validateForm()) {
      let filterData = {
        orderId: orderId,
        userId: userId,
        orderQuotation: formData.orderQuotation,
      };
      console.log("With GST Array", filterData)
      let notificationData = {
        title: "Daqi",
        body: `Dear ${merchantUserData.shopName}, You received a Quotation. Kindly accept and raise the invoice. Thanks team Daqi`,
        userId: [userId],
      };

      console.log("filterData+++++==", filterData);
      setIsLoadingOn(true);
      const tempData = await postData(`users/insertOrderNew`, filterData);
      // console.log("filterData Resposne", tempData);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        getDetailsDataDistributor(orderId);
        const notifydata = await postData(
          `users/notification`,
          notificationData
        );

        if (notifydata.statusCode == 200) {
          console.log("NotifyData", notifydata.statusCode);
        }
        setShowTopMessage(true);
        setTimeout(() => {
          setShowTopMessage(false);
        }, 3000);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data inserted successfully!",
        }).then((result) => {
          if (result.isConfirmed) {
            closeModal();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: tempData?.data?.errors.msg || "Failed to insert data.",
        });
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  async function merchantGetData() {
    setIsLoadingOn(true);
    const tempData = await getData(`users/getUserShopByOrderId/${orderId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data[0]);
      setUserInvoiceData(tempData.data[0].document);
    }
  }


  const getDetailsDataDistributor = async (orderId) => {
    setIsLoadingOn(true);
    const res = await getData(`distributor/getOrdersDistributors/params?orderId=${orderId}`);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      const distributorData = res.data.map(item => ({
        ...item,
        statusName: merchantUserData?.statusName || null,
      }));
      setInvoiceDistributorData(distributorData);
      setDistributorResponseData(res.data.length);      
      if (res.data && res.data.length > 0) {
        setInvoiceUserData(res.data[0].invoicePath || null);
        setDisbusApproval(res.data[0].isDisbursementApproved != null ? res.data[0].isDisbursementApproved : "Pending");
      }
    }
  };

  const formatter = new Intl.NumberFormat("en-IN");
  
  useEffect(() => {
    merchantGetData();
    getDetailsDataDistributor(orderId);
  }, [orderId]);

  const getFileInvoiceData = async (fileData) => {
    let tempData = {
      file: fileData,
    };
    setIsLoadingOn(true);
    const res = await postData(`documents/invoiceData`, tempData);
    console.log("filterData Resposne", res);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      let invoiceDetails = res.data[0];
      let formattedDate = new Date(invoiceDetails.invoiceDate)
        .toISOString()
        .split("T")[0];
      setFormDistributorData((prevData) => ({
        ...prevData,
        invoiceDate: formattedDate,
        invoiceNumber: invoiceDetails.invoiceNumber
          ? invoiceDetails.invoiceNumber
          : invoiceDetailsData.invoiceNumber,
      }));
    }
  };

  async function merchantOrderImage() {
    let tempDocument = {
      userId: userId,
      referenceId: orderId,
      tableReference: "ORDER",
    };
    setIsLoadingOn(true);
    const tempData = await postData(`documents/getDocuments`, tempDocument);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setOrderImageData(tempData.data);
    }
  }

  useEffect(() => {
    merchantOrderImage();
  }, []);

  const data = useMemo(
    () => [
      {
        distId: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
        data1: "9988776786",
        action: "9988776786",
      },
    ],
    []
  );

  const openUploadQuotation = () => {
    setModalIsQuotationOpen(true);
  };

  const openDisbursementApproval = () => {
    setApprovalStatus(disbusApproval); // Reset to Pending when opening the modal
    setModalIsDisbursementApproval(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Distributor Name",
        accessor: "distributorName",
      },
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
      },
      {
        Header: "Invoice Amount",
        accessor: "invoiceAmount",
        Cell: ({ value }) => {
          const numericValue = Number(value);
          if (!isNaN(numericValue)) {
            const roundedValue = numericValue.toFixed(2);
              return formatter.format(roundedValue);
          } else {
              return value || "-";
          }
      },
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        Cell: ({ cell }) => {
          const invoiceDate = cell.row.original.invoiceDate;
          return <span>{invoiceDate && formatDate(invoiceDate)}</span>;
        },
      },
      {
        Header: "Disbursement Date",
        accessor: "disbursedDate",
        Cell: ({ cell }) => {
          const disbursedDate = cell.row.original.disbursedDate;
          return <span>{disbursedDate && formatDate(disbursedDate)}</span>;
        },
      },
      {
        Header: "Document",
        accessor: "invoicePath",
        Cell: ({ row }) => {
          let invoiceData = row.original.invoicePath;
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {invoiceData && (
                <button
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    window.open(row.original.invoicePath, "_blank")
                  }
                >
                  <FaRegFilePdf />
                </button>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const file = cell.row.original.invoicePath;
          const disbursedDate = cell.row.original.disbursedDate;
          let status = cell.row.original.status;
          return (
            <>
              {disbursedDate ? (
                <div className="dibsursedTagShow">Disbursed</div>
              ) : (
                <div
                  className="loanIdDownloadBtnDiv text-center"
                  style={{ padding: "5px !important;" }}
                >
                  {file ? (
                    <>
                      {status && status == 37 ? (
                        <button
                          className="btn btn-primary mb-1"
                          onClick={() => {
                            handleShowDisbursementData(cell.row.original);
                            setDisbursementInvoiceId(
                              cell.row.original.invoiceId
                            );
                        
                          }}
                        >
                          <span style={{ fontSize: 14 }}>Add Disbursement</span>
                        </button>
                      ) : null}
                      <button
                        className="btn-sm btn-warning"
                        onClick={() => {
                          if (
                            userRole === "Admin" ||
                            userRole === "Operation"
                          ) {
                            handleOpenModalDetails(cell.row.original);
                          }
                        }}
                      >
                        <span>Update Invoice</span>
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn-sm btn-primary`}
                      onClick={() => {
                        if (userRole === "Admin" || userRole === "Operation") {
                          handleOpenModalDetails(cell.row.original);
                        }
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Add Invoice</span>
                    </button>
                  )}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Cheque Documents",
        accessor: "",
        Cell: ({ cell }) => {
          const fileUrls = cell.row.original.checkDocument;
          
          return (
            <div className="text-center">
              {fileUrls && Array.isArray(fileUrls) && fileUrls.length > 0 ? (
                fileUrls.map((url, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => window.open(url, '_blank')}
                    style={{ fontSize: 22, color: "#6E12F9", border: "none", background: "none", margin: "0 5px" }}
                    title={`Download file ${index + 1} for ${cell.row.original.distributorName}`}
                  >
                    <FaRegFilePdf />
                  </button>
                ))
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
      }                 
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="noMarginSection">
      {isLoadingOn ? <Loader /> : ""}
      <div>
        <div className="container-fluid">
          <div className="row alignmentTable topOrderHeaderBorder mb-3">
            <div className="col-md-10">
              <h5 style={{ marginBottom: 0 }}>Order Details</h5>
            </div>

            {/* <div className="col-md-2 text-end">
              <button
                className="btn btnAddCredit"
                onClick={() => {
                  setModalIsDisbursement(true);
                }}
              >
                Set Disbursement
              </button>
            </div> */}
            {(invoiceUserData !== null && distributorResponseData > 0 && merchantUserData?.statusName == "Approved") ? (
              <div className="col-md-2 text-end">
                <button
                  style={{ fontSize: 12 }}
                  className="btn btnAddCredit"
                  onClick={() => {
                    openDisbursementApproval();
                  }}
                >
                  Disbursement Approval
                </button>
              </div>
            ) : "" }

            {merchantUserData && merchantUserData.statusName == "Approved" ? (
              ""
            ) : (
              <div className="col-md-2 text-end">
                <button
                  style={{ fontSize: 11 }}
                  className="btn btnAddCredit"
                  onClick={() => {
                    openUploadQuotation();
                  }}
                >
                  Upload Quotation
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <section className="orderDetailsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-2 showWholeBorder">
              <div className="row">
                {orderImageData &&
                  orderImageData.map((item, index) => (
                    <div key={index} className="col-md-6">
                      <div className="mngimag">
                        <div className="imagemsngOrder">
                          <div className="viewoDoenload">
                            <button
                              className="btnviewImage"
                              onClick={() => viewImageOrder(item.filePath)}
                            >
                              <FaEye />
                            </button>
                            <button className="btnviewImage">
                              <a href={item.filePath} target="_blank">
                                <FaDownload />
                              </a>
                            </button>
                          </div>
                          <img src={item.filePath} alt="img" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className="basicDetailsUser">
                <table className="userDetailsTableInfo">
                  <tr>
                    <th>Name :</th>
                    <td>{merchantUserData && merchantUserData.shopName}</td>
                  </tr>
                  <tr>
                    <th>Mobile :</th>
                    <td>{merchantUserData && merchantUserData.mobileNumber}</td>
                  </tr>
                  <tr>
                    <th>Mer. Ref No. :</th>
                    <td>
                      {merchantUserData &&
                        merchantUserData.merchantReferenceNumber}
                    </td>
                  </tr>
                  <tr>
                    <th>Order Date:</th>
                    <td>{merchantUserData && merchantUserData.orderDate}</td>
                  </tr>
                  <tr>
                    <th>Order Status:</th>
                    <td>
                      {merchantUserData && (
                        <span
                          className={
                            merchantUserData.statusName == "Approved"
                              ? "text-success"
                              : merchantUserData.statusName == "Processing"
                              ? "text-warning"
                              : "text-danger"
                          }
                        >
                          {merchantUserData && merchantUserData.statusName}
                        </span>
                      )}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Establishment Year :</th>
                    <td>
                      {merchantUserData &&
                        merchantUserData.businessEstablishmentYear}
                    </td>
                  </tr>
                  <tr>
                    <th>FOS Name :</th>
                    <td>{merchantUserData && merchantUserData.fosName ? merchantUserData.fosName : "Not Available"}</td>
                  </tr>
                  <tr>
                    <th>Address :</th>
                    <td>
                      {merchantUserData &&
                        `${merchantUserData.shopNumberAndBuildingName},
                    ${merchantUserData.address1},
                    ${merchantUserData.address2},
                    ${merchantUserData.city},
                    ${merchantUserData.state},
                    ${merchantUserData.shopPinCode},
                     `}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        // data-bs-toggle="collapse"
                        // data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        // aria-controls="panelsStayOpen-collapseOne"
                      >
                        Distributor Invoice Details
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      {invoiceDistributorData &&
                      invoiceDistributorData.length > 0 ? (
                        <div className="accordion-body">
                          <Table
                            columns={columns}
                            data={invoiceDistributorData}
                          />
                        </div>
                      ) : (
                        <h5 style={{ padding: 10 }}>No Data Found</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------- form row ---------------------- */}
        </div>
      </section>
      {/* order qutation modal */}
      <Modal
        isOpen={modalIsQuotationOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtnOrder" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div>
          {isLoadingOn ? <Loader /> : ""}
          <form>
            <div className="row customrowChangeOrderDetilas">
              <div className="col-md-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        Sr No.
                      </th>
                      <th>
                        Distributor<span>*</span>
                      </th>
                      <th>
                        Brand Name<span>*</span>
                      </th>
                      <th>
                        Select Category<span>*</span>
                      </th>
                      <th>
                        GST Applicable<span>*</span>
                      </th>
                      <th>
                        Item<span>*</span>
                      </th>
                      <th>
                        Quantity<span>*</span>
                      </th>
                      <th>
                        Listing Price<span>*</span>
                      </th>
                      <th>
                        Rate<span>*</span>
                      </th>
                      <th>
                        Total Amount<span>*</span>
                      </th>
                      <th>
                        Total Buying Amount<span>*</span>
                      </th>
                      <th>
                        Margin<span>*</span>
                      </th>
                      <th>
                        (%) to Merchant<span>*</span>
                      </th>
                      <th>
                        (%) for Daqi<span>*</span>
                      </th>
                      <th>
                        Promo Discount (%)<span>*</span>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {totalRoiRows &&
            totalRoiRows.map((row, index) => ( */}
                    {formData.orderQuotation.map((row, index) => (
                      <tr key={row.id} className="rowHeight">
                        <td style={{textAlign:"center"}}>{index + 1}.</td>
                        <td>
                          <Select
                            placeholder="Select"
                            name="distributorId"
                            options={distributorUserData}
                            styles={customStyles}
                            onChange={(selectedOption) => {
                              let selecteddistributorName =
                                selectedOption.label.split("-")[0];
                              console.log(
                                "Selected item:",
                                selecteddistributorName
                              );
                              setSelectedDistributor(selectedOption);
                              setSelectedDistributorName(
                                selecteddistributorName
                              );
                              handleSelectChange(
                                "distributorId",
                                selectedOption,
                                index
                              );
                            }}
                          />
                          {errors.distributorId && (
                            <div className="text-danger">
                              {errors.distributorId}
                            </div>
                          )}
                        </td>
                        <td>
                          <Select
                            placeholder="Select"
                            name="brandName"
                            options={brandListData}
                            styles={customStyles}
                            onChange={(selectedOption) => {
                              setSelectedBrands(selectedOption);
                              handleSelectChange(
                                "brandName",
                                selectedOption,
                                index
                              );
                            }}
                          />
                          {errors.brandName && (
                            <div className="text-danger">
                              {errors.brandName}
                            </div>
                          )}
                        </td>
                        <td>
                          <Select
                            className="AddingRowCss"
                            placeholder="Select"
                            name="categoryName"
                            options={productCategoryData}
                            styles={customStyles}
                            onChange={(selectedOption) => {
                              setSelectedCategory(selectedOption);
                              handleSelectChange(
                                "categoryName",
                                selectedOption,
                                index
                              );
                            }}
                          />
                          {errors.categoryName && (
                            <div className="text-danger">
                              {errors.categoryName}
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                          <input
                            type="checkbox"
                            name="isGstApplicable"
                            checked={row.isGstApplicable === 1}
                            onChange={(event) => {
                              const value = event.target.checked ? 1 : 0;
                              handleUpdateRowChange(
                                { target: { name: "isGstApplicable", value } },
                                index,
                                "orderQuotation"
                              );
                            }}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            // placeholder="Enter Item"
                            className="form-control AddingRowCss"
                            name="item"
                            value={row.item}
                            onChange={(event) =>
                              handleUpdateRowChange(
                                event,
                                index,
                                "orderQuotation"
                              )
                            }
                          />
                          {errors.item && (
                            <div className="text-danger">{errors.item}</div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            // placeholder="Enter Quantity"
                            className="form-control AddingRowCss"
                            name="quantity"
                            value={row.quantity}
                            onChange={(event) =>
                              handleUpdateRowChange(
                                event,
                                index,
                                "orderQuotation"
                              )
                            }
                          />
                          {errors.quantity && (
                            <div className="text-danger">{errors.quantity}</div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            // placeholder="Enter Listing Price"
                            className="form-control AddingRowCss"
                            name="listingPrice"
                            value={row.listingPrice}
                            onChange={(event) =>
                              handleUpdateRowChange(
                                event,
                                index,
                                "orderQuotation"
                              )
                            }
                          />
                          {errors.listingPrice && (
                            <div className="text-danger">
                              {errors.listingPrice}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="rate"
                            value={row.rate}
                            readOnly
                          />
                          {errors.rate && (
                            <div className="text-danger">{errors.rate}</div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="totalAmount"
                            value={row.totalAmount}
                            readOnly
                          />
                          {errors.totalAmount && (
                            <div className="text-danger">
                              {errors.totalAmount}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="totalBuyingAmount"
                            value={row.totalBuyingAmount}
                            readOnly
                          />
                          {errors.totalBuyingAmount && (
                            <div className="text-danger">
                              {errors.totalBuyingAmount}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="margin"
                            value={row.margin}
                            readOnly
                          />
                          {errors.margin && (
                            <div className="text-danger">{errors.margin}</div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="discountToMerchant"
                            value={row.discountToMerchant}
                            readOnly
                          />
                          {errors.discountToMerchant && (
                            <div className="text-danger">
                              {errors.discountToMerchant}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control AddingRowCssReadonly"
                            name="discountToDaqi"
                            value={row.discountToDaqi}
                            readOnly
                          />
                          {errors.discountToDaqi && (
                            <div className="text-danger">
                              {errors.discountToDaqi}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            // placeholder="Enter Promotional Discount"
                            className="form-control AddingRowCss"
                            name="promotionalDiscount"
                            value={row.promotionalDiscount}
                            onChange={(event) =>
                              handleUpdateRowChange(
                                event,
                                index,
                                "orderQuotation"
                              )
                            }
                          />
                          {errors.promotionalDiscount && (
                            <div className="text-danger">
                              {errors.promotionalDiscount}
                            </div>
                          )}
                        </td>
                        <td>
                          {index === 0 ? (
                            <div
                              className="proPlusIconGreen"
                              onClick={totalRoiAddRow}
                            >
                              <FaPlusCircle />
                              {/* <p>Add More Data</p> */}
                            </div>
                          ) : (
                            <div
                              className=""
                              style={{ color: "red", fontSize: "20px" }}
                              onClick={() => totalRoiDeleteRow(row.id, index)}
                            >
                              <MdDelete />
                              {/* <p>Delete this Row</p> */}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-success"
                    onClick={handelUpdateSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {/* order qutation modal */}

      {/* ------------- image View Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div className="modalUserImage">
          <img src={viewOrderImageModal} alt="modalImage" />
        </div>
      </Modal>

      {/* ------------- invoice View Modal ---------------------- */}
      <Modal
        isOpen={actionModalOpen}
        onRequestClose={closeModal}
        style={customStylesDistributor}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-2">
              <label htmlFor="invoiceFile">Select File</label>
              <input
                type="file"
                // value={formDistributorData.invoiceFile}
                onChange={handleChange}
                accept="application/pdf"
                name="invoiceFile"
                className="form-control"
                id="invoiceFile"
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="invoiceNumber">Invoice Number</label>
              <input
                type="text"
                value={formDistributorData.invoiceNumber}
                onChange={handleChange}
                name="invoiceNumber"
                className="form-control"
                id="invoiceNumber"
                placeholder="Enter Number"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="invoiceAmount">Invoice Amount</label>
              <input
                type="text"
                value={formDistributorData.invoiceAmount}
                onChange={handleChange}
                name="invoiceAmount"
                className="form-control"
                id="invoiceAmount"
                placeholder="Enter Amount"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="invoiceDate">Invoice Date</label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formDistributorData.invoiceDate}
                onChange={handleChange}
                name="invoiceDate"
                className="form-control"
                id="invoiceDate"
              />
            </div>
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {/* -------------------- Set Disbursement -------------------- */}
      <Modal
        isOpen={modalIsDisbursement}
        onRequestClose={closeModal}
        style={customStylesDisbursement}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal} style={closeButtonStyle}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleUpdateDisbursedInvoice}>
            <div className="form-group mt-2">
              <label htmlFor="disbursementDistributor">Distributor Name</label>
              <input
                type="text"
                disabled
                value={formDisbursementData.disbursementDistributor}
                onChange={handleDisbursedChange}
                name="disbursementDistributor"
                className="form-control"
                id="disbursementDistributor"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursementInvoiceId">Invoice Number</label>
              <input
                type="number"
                disabled
                value={formDisbursementData.disbursementInvoiceId}
                onChange={handleDisbursedChange}
                name="disbursementInvoiceId"
                className="form-control"
                id="disbursementInvoiceId"
                placeholder=""
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="lenderName">Lender Name</label>
              <input
                type="text"
                disabled
                value={formDisbursementData.lenderName}
                onChange={handleDisbursedChange}
                name="lenderName"
                className="form-control"
                id="lenderName"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursementAmount">Distributor Disbursement Amount</label>
              <input
                type="number"
                disabled
                value={formDisbursementData.disbursementAmount}
                onChange={handleDisbursedChange}
                name="disbursementAmount"
                className="form-control"
                id="disbursementAmount"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="totalDisbursementAmount">Total Disbursement Amount</label>
              <input
              disabled
                type="text"
                value={formDisbursementData.totalDisbursementAmount}
                onChange={handleDisbursedChange}
                name="totalDisbursementAmount"
                className="form-control"
                id="totalDisbursementAmount"
                placeholder=""
              />
            </div>

            <div className="form-group mt-2">
              {fileInputs.map((input, index) => (
                <div className="row mb-0" key={input.id}>
                  <div className="col-md-11">
                    <div className="mb-2">
                      <label htmlFor={`checkDocument_${input.id}`} className="form-label">
                        {index === 0 ? 'Cheque (*)' : 'Cheque'}
                      </label>
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png"
                        onChange={(e) => handleFileChange(e, input.id)}
                        name={`checkDocument_${input.id}`}
                        className="form-control"
                        id={`checkDocument_${input.id}`}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    {index === 0 ? (
                      <div className="proPlusIconGreen" onClick={handleAddFileInput}>
                        <FaPlusCircle />
                      </div>
                    ) : (
                      <div className="deletedIcon" onClick={() => handleRemoveFileInput(input.id)}>
                        <FaTrash />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="form-group mt-2">
              <label htmlFor="disbursedDate">Disbursed Date</label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formDisbursementData.disbursedDate}
                onChange={handleDisbursedChange}
                name="disbursedDate"
                className="form-control"
                id="disbursedDate"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="utr">UTR Number</label>
              <input
                type="text"
                value={formDisbursementData.utr}
                onChange={handleDisbursedChange}
                name="utr"
                className="form-control"
                id="utr"
                placeholder="Enter Number"
              />
            </div>
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsDisbursementApproval}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Disbursement Approval Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Disbursement Approval</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">

              <button
                onClick={() => setApprovalStatus("Pending")}
                className={approvalStatus === "Pending" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "Pending" ? "#a2c11c" : "initial", color: "Black" }}
              >
                Pending
              </button>

              <button
                onClick={() => setApprovalStatus("Yes")}
                className={approvalStatus === "Yes" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "Yes" ? "#a2c11c" : "initial", color: "Black" }}
              >
                Yes
              </button>

              <button
                onClick={() => setApprovalStatus("No")}
                className={approvalStatus === "No" ? "selected option" : "option"}
                style={{ backgroundColor: approvalStatus === "No" ? "#a2c11c" : "initial", color: "Black" }}
              >
                No
              </button>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={updateDisbursementApproval} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default OrderDetailsPage;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                  <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'left',
                    }}
                  >
                  </th>
                </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                {footerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                    ))}
                  </tr>
                ))}
              </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
