import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch, FaRegFilePdf, FaRegFileExcel } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { FaVoteYea } from "react-icons/fa";
import { FaDownload, FaRegEye } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import { FaFileCsv } from "react-icons/fa";
import { CSVLink } from "react-csv";
import {
  formatDateTimeString,
  formatNumberWithCommasAndDecimals,
} from "../component/common";
import { FaFileDownload } from "react-icons/fa";
import { QueryClientProvider, QueryClient } from "react-query";
import TablePagination from "../component/TablePagination";
import { TableFooterPagination } from "../component/TableFooterPagination";
import Swal from "sweetalert2";
import ReactTable from "../pages/ReactTable";

const queryClient = new QueryClient();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
  },
};

function OrderList() {
  const statusName = {
    10: "Decline ",
    11: "Delete",
    14: "Duplicate",
    5: "Approved",
  };

  const [merchantUserData, setMerchantUserData] = useState();
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [statusModalIsOpen, setStatusModalIsOpen] = React.useState(false);
  const [orderQuotationOpen, setOrderQuotationOpen] = React.useState(false);
  const [saveOrderFlag, setSaveOrderFlag] = React.useState(false);
  const [saveOrderFlagItem, setSaveOrderFlagItem] = React.useState(null);
  const [showAleart, setshowAleart] = useState(false);
  const [showAleartRemark, setshowAleartRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [IsAccept, setIsAccept] = useState(false);
  const [IsReject, setIsReject] = useState(false);
  const [IsApproved, setIsApproved] = useState(false);
  const [IsDuplicate, setIsDuplicate] = useState(false);
  const [IsSuspended, setIsSuspended] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [userOrderId, setUserOrderId] = useState();
  const [userUserId, setUserUserId] = useState();
  const [orderQuotationData, setOrderQuotationData] = useState();
  const [statusApproved, setStatusApproved] = useState(false);
  const [quotationUserId, setquotationUserId] = useState([]);
  const [selectedImagesBase64, setSelectedImagesBase64] = useState();
  const [base64Image, setBase64Image] = useState("");
  const [shopUserId, setShopUserId] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionStatusType, setOptionStatusType] = useState();
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const userRole = localStorage.getItem("USER_ROLE");
  const [checkedItems, setCheckedItems] = useState({
    loanApplicationForm: false,
    agreement: false,
    kfs: false,
    osv: false,
    cam: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const Rejecthandle = () => {
    setIsAccept(false);
    setIsReject(true);
    setIsSuspended(false);
    setIsApproved(false);
    setIsDuplicate(false);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const Suspendedhandel = () => {
    setIsAccept(false);
    setIsReject(false);
    setIsSuspended(true);
    setIsApproved(false);
    setIsDuplicate(false);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };
  const Approvedhandle = () => {
    setIsAccept(false);
    setIsReject(false);
    setIsSuspended(false);
    setIsApproved(true);
    setIsDuplicate(false);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };
  const Duplicatehandel = () => {
    setIsAccept(false);
    setIsReject(false);
    setIsSuspended(false);
    setIsApproved(false);
    setIsDuplicate(true);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setOrderQuotationOpen(false);
    setSaveOrderFlag(false);
    setSaveOrderFlagItem(null);
    setCheckedItems({
      loanApplicationForm: false,
      agreement: false,
      kfs: false,
      osv: false,
      cam: false,
    });
  };

  const statusModalOpen = (user) => {
    if (user.statusName == "Processing") {
      setStatusApproved(true);
    } else {
      setStatusApproved(false);
    }
    setUserOrderId(user.orderId);
    setUserUserId(user.userId);
    setIsOpen(true);
  };

  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setOptionStatusType("");
    setSelectedStatusOption("");
    setSearchBoxValue("");
    merchantGetData();
    setSelectedOption(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );
  const formatter = new Intl.NumberFormat("en-IN");
  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";

    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    merchantGetData(pageIndex, pageSize, column.id, newOrderBy);
  };

  const history = useHistory();
  const UserOptions = async () => {
    setIsLoadingOn(true);
    try {
      const userData = await getData("usershop/getAllUserShop", {});
      if (userData.statusCode === 200) {
        const options = userData.data.map((user) => ({
          value: user.userId,
          label: user.shopName,
        }));
        setUserOptions(options);
      } else {
        console.error("Failed to get  data:", userData.statusCode);
        Swal.fire({
          title: "Error!",
          message: userData?.data?.errors.msg || "Something went wrong!!!",
        });
      }
    } catch (error) {
      console.error("Error fetching  data:", error);
    } finally {
      setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    UserOptions();
  }, []);

  async function merchantGetData(
    page = 0,
    pageSize = 10,
    sortBy = "",
    orderBy = ""
  ) {
    setIsLoadingOn(true);
    let apiUrl = `order/getOrders?page=${page + 1}&pageSize=${pageSize}`;

    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }

    setIsLoadingOn(false);
    try {
      const tempData = await postData(apiUrl, {orderBy: 'DESC'});
      if (tempData.statusCode === 200) {
        setMerchantUserData(tempData.data.records);
        setPageCount(tempData.data.pagination.totalPagesCount);
        setTotalRecords(tempData.data.pagination.totalRecords);
        setErrorMessage("");
        let tempOptionMerchant = [];
        for (let i = 0; i < tempData.data.records.length; i++) {
          const element = tempData.data.records[i];
          //   console.log("merchantUserData",element)
          if (element.userId) {
            tempOptionMerchant.push({
              value: element.userId,
              label: element.shopName,
            });
          }
          setquotationUserId(tempOptionMerchant);
        }
      } else {
        console.error("Failed to get data:", tempData.statusCode);
        Swal.fire({
          title: "Error!",
          message: tempData?.data?.errors.msg || "Something went wrong!!!",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingOn(false);
    }
  }

  const handelAddCreditLitmi = () => {
    setStatusModalIsOpen(true);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedImages || selectedImages.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select an Image!",
      });
    }
    if (!shopUserId) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select User!",
      });
    }
    let tmpDocumentData = {
      userId: shopUserId,
      referenceId: 0,
      tableReference: "ORDER",
      documentTypeId: 13,
      parentUserId: shopUserId,
      fileType: "image",
      file: selectedImagesBase64,
    };
    setIsLoadingOn(true);
    let res = await postData("documents/uploadDocument", tmpDocumentData);
    console.log("uploadDocument---", res);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      setShowTopMessage(true);
      setStatusModalIsOpen(false);
      setTimeout(() => {
        setShowTopMessage(false);
      }, 3000);
      setSelectedImages(null);
      setSelectedImagesBase64(null);
      setShopUserId(null);
    } else {
      Swal.fire({
        title: "Error!",
        message: res?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  const handleSubmitOrderFlag = async () => {
    if (saveOrderFlagItem && checkedItems) {
      let tmpDocumentData = {
        userId: saveOrderFlagItem?.userId,
        orderId: saveOrderFlagItem?.orderId,
        loanApplicationForm: checkedItems?.loanApplicationForm,
        agreement: checkedItems?.agreement,
        kfs: checkedItems?.kfs,
        osv: checkedItems?.osv,
        cam: checkedItems?.cam,
      };
      console.log("tempData++", tmpDocumentData);
      setIsLoadingOn(true);
      let res = await postData("order/saveOrderFlag", tmpDocumentData);
      console.log("uploadDocument---", res);
      setIsLoadingOn(false);
      if (res.statusCode === 200) {
        merchantGetData(pageIndex, pageSize, sortBy, orderBy);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated order Flag.",
        });
        setSaveOrderFlag(false);
        setCheckedItems({
          loanApplicationForm: false,
          agreement: false,
          kfs: false,
          osv: false,
          cam: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Please Select User!",
        });
      }
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);
  const handleImageChange = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    console.log("imagearray---", imagesArray);
    setSelectedImages(imagesArray);
    const base64Array = [];

    imagesArray.forEach((image) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        const strippedImageData = base64String.replace(
          "data:image/jpeg;base64,",
          ""
        );
        setBase64Image(strippedImageData);
        base64Array.push(strippedImageData);
        if (base64Array.length === imagesArray.length) {
          setSelectedImagesBase64(base64Array);
        }
      };
      reader.readAsDataURL(image);
    });
  };
  const closeStatusModal = () => {
    setStatusModalIsOpen(false);
  };

  const updateOrderStatus = async () => {
    let filterData = {
      orderId: userOrderId,
      userId: userUserId,
      status: approvalStatus,
      remark: remark,
    };
    setIsLoadingOn(true);
    const tempData = await postData("order/updateOrderStatus", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setIsOpen(false);
      setShowTopMessage(true);

      let tempMerchantData = [];
      for (let i = 0; i < merchantUserData.length; i++) {
        const element = merchantUserData[i];
        // console.log(" element ", element);
        if (userOrderId == element.orderId) {
          element.status = approvalStatus;
          element.statusName = statusName[`${approvalStatus}`];
        }
        tempMerchantData.push(element);
      }
      setMerchantUserData(tempMerchantData);
      setTimeout(() => {
        setShowTopMessage(false);
      }, 3000);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  useEffect(() => {
    merchantGetData(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType == "Merchant ID") {
      filterData = { userShopId: searchBoxValue, orderBy:"DESC" };
    } else if (merchantRefrenceType == "Mobile No.") {
      filterData = { mobileNumber: searchBoxValue, orderBy:"DESC" };
    } else if (merchantRefrenceType == "Merchant Name") {
      filterData = { name: searchBoxValue, orderBy:"DESC" };
    } else {
      alert("Please Select Any Option");
      return;
    }

    if (optionStatusType && optionStatusType != "All") {
      filterData.invoice_status = optionStatusType;
    }

    setIsLoadingOn(true);
    const tempData = await postData("order/getOrders", filterData);
    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };
  const handleInputChange = (e) => {
    // alert("zmhfgsdhsdf");
    if (merchantRefrenceType === "Mobile No.") {
      const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };
  const downloadDumpData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("order/getExcelOrderList/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      const downloadLink = document.createElement("a");
      downloadLink.href = tempData.data.Location;
      downloadLink.target = "_blank";
      downloadLink.rel = "noopener noreferrer";
      downloadLink.click();
    } else {
      Swal.fire({
        title: "Error!",
        message: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  const handleGetOrderAgreement = async (orderId) => {
    setIsLoadingOn(true);
    const response = await getData(
      `order/order-agreement-file?orderId=${orderId}`
    );
    setIsLoadingOn(false);
    if (response.statusCode === 200) {
      if (response?.data?.[0]?.orderAgreement) {
        const downloadLink = document.createElement("a");
        downloadLink.href = response.data[0].orderAgreement;
        downloadLink.target = "_blank";
        downloadLink.rel = "noopener noreferrer";
        downloadLink.click();
      } else {
        // alert("Order agreement not found.");
        Swal.fire({
          title: "Error!",
          message: "Order agreement not found!",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        message: response?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  const handleGetOrdersCreditNote = async (orderId) => {
    setIsLoadingOn(true);
    const response = await getData(
      `order/getOrdersCreditNote?orderId=${orderId}`
    );
    setIsLoadingOn(false);
    if (response.statusCode === 200) {
      if (response?.data?.Location) {
        const downloadLink = document.createElement("a");
        downloadLink.href = response.data.Location;
        downloadLink.target = "_blank";
        downloadLink.rel = "noopener noreferrer";
        downloadLink.click();
      } else {
        Swal.fire({
          title: "Error!",
          message: "Order Credit not found!",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        message: response?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  const handleGetCamReportData = async (orderId) => {
    setIsLoadingOn(true);
    const response = await getData(`order/getCamFile?orderId=${orderId}`);
    setIsLoadingOn(false);
    if (response.statusCode === 200) {
      if (response?.data[0]?.camFile) {
        const downloadLink = document.createElement("a");
        downloadLink.href = response?.data[0]?.camFile;
        downloadLink.target = "_blank";
        downloadLink.rel = "noopener noreferrer";
        downloadLink.click();
      } else {
        Swal.fire({
          icon: "error",
          text: "No data found!",
        });
      }
    } else {
      Swal.fire({
        icon: "Error",
        title: "Error!",
        text: "Something went wrong!!!",
      });
    }
  };

  const openModalQuationData = async (orderId) => {
    // console.log("dfsdfkhdkf123----", orderId)
    setOrderQuotationOpen(true);
    setIsLoadingOn(true);
    let tempData = await getData(`order/getOrderDetailByOrderId/${orderId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setOrderQuotationData(tempData.data);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong!!!",
      });
    }
  };

  const displayMerchantInfo = (user) => {
    const newTab = window.open(
      "/OrderDetailsPage?userId=" + user.userId + "&orderId=" + user.orderId,
      "_blank"
    );
    if (!newTab) {
      history.push({
        pathname: "/OrderDetailsPage",
        state: { userId: user.userId, orderId: user.orderId },
      });
    }
  };
  const handleRemoveImage = (indexToRemove) => {
    setSelectedImagesBase64((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const gotoMerchantPage = (userId) => {
    // console.log("dfsdfkhdkf123----", userId)
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/merchantDetails?userId=" + userId;
    } else {
      history.push({
        pathname: "/merchantDetails",
        state: { userId: userId },
      });
    }
  };

  const orderQuotationColumns = useMemo(() => [
    {
      Header: "Sr. No.",
      accessor: (row, i) => i + 1,
      Cell: ({ cell }) => (
        <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
          {cell.row.index + 1}.
        </span>
      ),
    },
    {
      Header: "Order Id",
      accessor: "orderId",
    },
    {
      Header: "Brand",
      accessor: "brand",
      Footer: <div className="tblFooterColor">Total Amount</div>,
    },
    {
      Header: "Item",
      accessor: "item",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Rate",
      accessor: "rate",
      Cell: ({ cell }) => {
        const rate = cell.row.original.rate;
        return (
          <>
            <span>{rate && rate.toFixed(2)}</span>
          </>
        );
      },
    },

    {
      Header: "Gst Applicable",
      accessor: "isGstApplicable",
      Cell: ({ cell }) => {
        const GstApplicable = cell.row.original.isGstApplicable;
        return (
          <>
            <span>{GstApplicable == 1 ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      Header: "Total Amount",
      accessor: "amountWithGST",

      Cell: ({ value }) => {
        const numericValue = Number(value);
        if (!isNaN(numericValue)) {
          return formatNumberWithCommasAndDecimals(numericValue);
        } else {
          return value || "-";
        }
      },
      Footer: (info) => {
        const totalAmount = info.rows.reduce(
          (sum, row) => sum + row.values.amountWithGST,
          0
        );

        return (
          <div className="tblFooterColor">
            {totalAmount && formatNumberWithCommasAndDecimals(totalAmount)}
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "OrderDetailDateTime",
      Cell: ({ cell }) => {
        return (
          <>
            <span>
              {cell.row.original.OrderDetailDateTime &&
                formatDateTimeString(cell.row.original.OrderDetailDateTime)}
            </span>
          </>
        );
      },
    },
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.index + 1 + pageIndex * pageSize}.
          </span>
        ),
      },
      {
        Header: "Order Code",
        accessor: "orderCode",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.orderCode}
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      displayMerchantInfo(cell.row.original);
                    }
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.orderCode}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Merchant Id",
        accessor: "userId",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.userShopId}
                  onClick={() => gotoMerchantPage(cell.row.original.userId)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.userId}
                  </span>
                </button>
              </div>
            </>
          );
        },
      },
      {
        Header: "Shop Name",
        accessor: "shopName",
      },
      {
        Header: "Date",
        accessor: "createdDate",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.createdDate &&
                formatDateTimeString(cell.row.original.createdDate)}
            </>
          );
        },
      },
      {
        Header: "Time",
        accessor: "createdTime",
      },
      {
        Header: "Download",
        accessor: "",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() =>
                    handleGetOrderAgreement(cell.row.original.orderId)
                  }
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                  }}
                  title={`Download order agreement for ${cell.row.original.orderCode}`}
                >
                  <FaRegFilePdf />
                </button>

                <button
                  type="button"
                  onClick={() =>
                    handleGetOrdersCreditNote(cell.row.original.orderId)
                  }
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                  }}
                  title={`Download order's credit note for ${cell.row.original.orderCode}`}
                >
                  <FaRegFileExcel />
                </button>

                <button
                  type="button"
                  onClick={() =>
                    handleGetCamReportData(cell.row.original.orderId)
                  }
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                  }}
                  title={`Download Cam Report for ${cell.row.original.orderCode}`}
                >
                  <FaRegFileExcel />
                </button>
              </div>
            </>
          );
        },
      },
      // {
      //   Header: "Disbursement Approval",
      //   accessor: "isDisbursementApproved",
      //   Cell: ({ cell }) => {
      //     return (
      //       <>
      //         {cell.row.original.isDisbursementApproved
      //           ? cell.row.original.isDisbursementApproved
      //           : "Pending"}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Status",
        accessor: "statusName",
        Cell: ({ cell }) => {
          // console.log("cell.row.original---",cell.row.original.statusName)
          const status = cell.row.original.statusName;
          return (
            <>
              <div className="loanIdDownloadBtnDiv text-center">
                <button
                  style={{ border: "none" }}
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      statusModalOpen(cell.row.original);
                    }
                  }}
                  className={`badge badge-pill ${
                    status === "Order Disbursed"
                      ? "badge-success"
                      : status === "Merchant Approval Pending"
                      ? "badge-warning"
                      : status === "Quotation Pending"
                      ? "badge-danger"
                      : status === "Delete"
                      ? "badge-secondary"
                      : "badge-primary"
                  }`}
                  value={cell.row.original.statusName}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.statusName}
                  </span>
                </button>
              </div>
            </>
          );
        },
      },

      {
        Header: "Mode",
        accessor: "paymentType",
        Cell: ({ cell }) => {
          const status = cell.row.original.paymentType;
          return (
            <>
              <div className="text-left">
                <span>
                  {cell.row.original.paymentType == "loan"
                    ? "Credit"
                    : cell.row.original.paymentType == "cash"
                    ? "COD"
                    : cell.row.original.paymentType == "directPayment"
                    ? "Direct Payment"
                    : "-"}
                </span>
              </div>
            </>
          );
        },
      },

      {
        Header: "Action",
        accessor: "csvPath",
        Cell: ({ cell }) => {
          console.log("cell.row.original+++", cell.row.original.status);
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="loanIdDownloadBtnDiv text-center">
                <a
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    cursor: "pointer",
                    marginRight: 20,
                  }}
                  href={cell.row.original.csvPath}
                >
                  {cell.row.original.csvPath == null ? "" : <FaDownload />}
                </a>
                <button
                  onClick={() =>
                    openModalQuationData(cell.row.original.orderId)
                  }
                  style={{
                    fontSize: 22,
                    color: "#6E12F9",
                    border: "none",
                    background: "none",
                  }}
                >
                  <FaRegEye />
                </button>
              </div>
              {cell?.row?.original?.status == 30 && (
                <div className="loanIdDownloadBtnDiv text-center">
                  <button
                    title="Order Flag"
                    onClick={() => {
                      setSaveOrderFlagItem(cell.row.original);
                      setSaveOrderFlag(true);
                    }}
                    style={{
                      fontSize: 22,
                      color: "#007bff",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <FaVoteYea />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    // state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const merchantOption = [
    { value: "Merchant ID", label: "Merchant ID" },
    { value: "Mobile No.", label: "Mobile No." },
    { value: "Merchant Name", label: "Merchant Name" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Updated Details." />
      ) : (
        ""
      )}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Upload Order Quotation" />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Order List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Status"
                name="role"
                options={[
                  { label: "All", value: "All" },
                  { label: "Pending", value: "Pending" },
                  { label: "Uploaded", value: "Uploaded" },
                ]}
                value={selectedStatusOption}
                onChange={(e) => {
                  setOptionStatusType(e.value);
                  setSelectedStatusOption(e);
                }}
              />
            </div>

            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOption}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOption(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={merchantRefrenceType === "Mobile No." ? "tel" : "text"}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //     setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center" onClick={searchManualData}>
              <button className="searchTableData">
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            {(userRole === "Admin" || userRole === "Operation") && (
              <>
                <div className="col-md-2 text-end">
                  <button
                    className="btn btnUploadQuotationss"
                    onClick={() => {
                      if (userRole === "Admin" || userRole === "Operation") {
                        handelAddCreditLitmi();
                      }
                    }}
                  >
                    <FaPlusCircle />
                  </button>
                </div>

                {/* <div className="col-md-1 text-end">
                                    {merchantUserData && (
                                        <button
                                            id="CSV"
                                            type="button"
                                            className="font-weight-bold download-csv transformBtn acceptDataCss">


                                            <CSVLink
                                                data={merchantUserData}
                                                filename={"OrderList.csv"}
                                                target="_blank"
                                            >
                                                <span className="svg-icon svgSetting">
                                                    <FaFileCsv />
                                                </span>
                                            </CSVLink>
                                        </button>
                                    )}
                                </div> */}
                {/* <div className="col-md-1 pl-0">
                                    {
                                        userRole === "Admin" ? <button
                                        onClick={downloadDumpData}
                                        id="CSV"
                                        type="button"
                                        className="font-weight-bold download-csv transformBtn acceptDataCss">
                                        <a href='javascript:void(0)'>
                                            <FaFileDownload />
                                        </a>
                                    </button>
                                    : null
                                    }
                                    
                                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
          <ReactTable
            columns={columns}
            data={merchantUserData}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage}
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        )}
        {/* <QueryClientProvider client={queryClient}>
                    <TablePagination
                        // searchManualData={filterDataInfo}
                        columns={columns}
                        // resetFiter={resetFilterData}
                    />
                </QueryClientProvider> */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Order Status</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">
              <button
                onClick={Suspendedhandel}
                htmlFor="option-3"
                className={
                  IsSuspended
                    ? "suspendedMng option option-3"
                    : "option option-3"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("10");
                  }}
                >
                  Decline
                </span>
              </button>

              <button
                onClick={Rejecthandle}
                htmlFor="option-2"
                className={
                  IsReject ? "rejectlisth option option-2" : "option option-2"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("11");
                  }}
                >
                  Delete
                </span>
              </button>

              {statusApproved && (
                <button
                  onClick={Approvedhandle}
                  htmlFor="option-2"
                  className={
                    IsApproved
                      ? "approvedlisth option option-4"
                      : "option option-4"
                  }
                >
                  <span
                    onClick={() => {
                      setApprovalStatus("5");
                    }}
                  >
                    Approved
                  </span>
                </button>
              )}

              <button
                onClick={Duplicatehandel}
                htmlFor="option-2"
                className={
                  IsDuplicate
                    ? "duplicatelisth option option-5"
                    : "option option-5"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("14");
                  }}
                >
                  Duplicate
                </span>
              </button>
            </div>
          </div>

          <div className="remardi">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Remark</label>
              <textarea
                onChange={(event) => {
                  // console.log(" testing ", event.target.value);
                  setRemark(event.target.value);
                }}
                value={remark}
                className="form-control textacont"
                rows="3"
              ></textarea>
              {showAleart ? (
                <p className="requiredRemark">Remark is required...</p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={updateOrderStatus} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>
      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={statusModalIsOpen}
        onRequestClose={closeStatusModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeStatusModal}>
          <FaRegTimesCircle />
        </button>
        <div className="orderUploadModal">
          <div className="mb-3">
            <label htmlFor="userId" className="form-label">
              Select User:
            </label>
            <Select
              placeholder="Select Search Type"
              name="role"
              options={userOptions}
              onChange={(e) => {
                setShopUserId(e.value);
              }}
            />
            <label htmlFor="userId" className="form-label mt-3">
              Upload Order:
            </label>
            <div className="custom-file-upload mb-3">
              <label htmlFor="images" className="form-label">
                Select Images
              </label>
              <input
                type="file"
                id="images"
                name="images"
                onChange={handleImageChange}
                multiple
                accept="image/*"
                aria-describedby="textHelp"
              />
            </div>
            {selectedImages && selectedImages.length > 0 && (
              <div className="mb-3">
                <label className="form-label">Uploaded Images:-</label>
                <div className="image-grid">
                  {selectedImages.map((image, index) => (
                    <div key={index} className="uploaded-image-container">
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        className="close-btn"
                      >
                        X
                      </button>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Uploaded Image ${index}`}
                        className="uploaded-image"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="text-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-success"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}

      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={saveOrderFlag}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {isLoadingOn ? <Loader /> : null}
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Update Order Flag</h4>
        <div className="modalUserStatusQuatation orderFlagUpdate">
          <label>
            <input
              type="checkbox"
              name="loanApplicationForm"
              checked={checkedItems.loanApplicationForm}
              onChange={handleCheckboxChange}
            />
            Loan Application Form
          </label>

          <label>
            <input
              type="checkbox"
              name="agreement"
              checked={checkedItems.agreement}
              onChange={handleCheckboxChange}
            />
            Agreement
          </label>

          <label>
            <input
              type="checkbox"
              name="kfs"
              checked={checkedItems.kfs}
              onChange={handleCheckboxChange}
            />
            KFS
          </label>
          <label>
            <input
              type="checkbox"
              name="osv"
              checked={checkedItems.osv}
              onChange={handleCheckboxChange}
            />
            OSV
          </label>

          <label>
            <input
              type="checkbox"
              name="cam"
              checked={checkedItems.cam}
              onChange={handleCheckboxChange}
            />
            CAM
          </label>
          {checkedItems &&
            Object.values(checkedItems).some((value) => value === true) && (
              <div className="submitButtonOrderFlag">
                <button onClick={handleSubmitOrderFlag}>Submit</button>
              </div>
            )}
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}

      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={orderQuotationOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {isLoadingOn ? <Loader /> : null}
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Order Quotation List</h4>
        <div className="modalUserStatusQuatation">
          {orderQuotationData && (
            <Table columns={orderQuotationColumns} data={orderQuotationData} />
          )}
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default OrderList;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table compone

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="orderTableFooter">
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
