import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import TopShowMessage from "../component/TopShowMessage";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const ProductAddData = () => {
  const history = useHistory();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  const [formData, setFormData] = useState({
    productName: "",
    productCategory: "",
    brand: "",
    size: "",
    color: "",
    mrp: "",
    discount: null,
    productId: "",
  });
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const numberFields = [
    "discount",
  ];

  const parseToNumber = (formData) => {
    const parsedData = { ...formData };
    numberFields.forEach((field) => {
      if (parsedData[field] !== "") {
        parsedData[field] = parseFloat(parsedData[field]);
      }
    });
    return parsedData;
  };

const sendData = async () => {
    const parsedFormData = parseToNumber(formData);
    setIsLoadingOn(true);
    const res = await postData(`electrician/addProductCatalog`, parsedFormData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      history.push("/ProductList");
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data updated successfully!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

    const handleUpdateChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        }));
    };

  const loadFormDataForEditing = async (id) => {
    try {
      setIsLoadingOn(true);
      const programDataResponse = await getData(
        `electrician/getProductCatalogList?id=${id}`
      );
      if (programDataResponse.statusCode == 200) {
      const data = programDataResponse.data.records[0];
      setFormData(data);
      } else {
        console.error("Error loading data:", programDataResponse.message);
      }
      setIsLoadingOn(false);
    } catch (error) {
      console.error("Error loading program data:", error);
      setIsLoadingOn(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendData();
  };
  
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      loadFormDataForEditing(id);
    }
  }, [id]);

  console.log("formData ===", formData);

return (
    <>
    {showTopMessage && <TopShowMessage message="You Have Successfully Submitted Product Data." />}
    {isLoadingOn && <Loader />}
    <div className="container">
        <h3 className="programHead">{isEditMode ? "Update Data" : "Add Product"}</h3>
        <form onSubmit={handleSubmit}>
        <div className="row">
            <h4 className="programDetailsDekho">Product Details</h4>
            <hr />
            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="productName" className="form-label">
                Product Name<span>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter Product Name"
                className="form-control"
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={handleUpdateChange}
                required
                />
            </div>
            </div>

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="productCategory" className="form-label">
                Product Category<span>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter Product Category"
                className="form-control"
                id="productCategory"
                name="productCategory"
                value={formData.productCategory}
                onChange={handleUpdateChange}
                required
                />
            </div>
            </div>

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="brand" className="form-label">
                Brand<span>*</span>
                </label>
                <input
                type="text"
                placeholder="Enter Brand"
                className="form-control"
                id="brand"
                name="brand"
                value={formData.brand}
                onChange={handleUpdateChange}
                required
                />
            </div>
            </div>

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="size" className="form-label">
                Size
                </label>
                <input
                type="text"
                placeholder="Enter Size"
                className="form-control"
                id="size"
                name="size"
                value={formData.size}
                onChange={handleUpdateChange}
                />
            </div>
            </div>
        </div>

        <div className="row">

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="mrp" className="form-label">
                MRP<span>*</span>
                </label>
                <input
                type="number"
                placeholder="Enter MRP"
                className="form-control"
                id="mrp"
                name="mrp"
                value={formData.mrp}
                onChange={handleUpdateChange}
                required
                />
            </div>
            </div>

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="discount" className="form-label">
                Discount
                </label>
                <input
                type="number"
                placeholder="Enter Discount"
                className="form-control"
                id="discount"
                name="discount"
                value={formData.discount}
                onChange={handleUpdateChange}
                />
            </div>
            </div>

            <div className="col-md-3 changeProductPro">
            <div className="mb-3">
                <label htmlFor="color" className="form-label">
                Color
                </label>
                <input
                type="text"
                placeholder="Enter Color"
                className="form-control"
                id="color"
                name="color"
                value={formData.color}
                onChange={handleUpdateChange}
                />
            </div>
            </div>
        </div>

        <div className="text-center">
            <button type="submit" className="btn btn-success">
            {isEditMode ? "Update" : "Submit"}
            </button>
        </div>
        </form>
    </div>
    </>
);

};

export default ProductAddData;