import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Swal from "sweetalert2";
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";
import { FaSearch, FaFileDownload } from "react-icons/fa";
import Table from "../pages/ReactTable"
import { useHistory } from "react-router-dom";

const LeadMerchantList = () => {
  const [leadMerchantData, setLeadMerchantData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [categoryName, setCategoryName] = useState(""); 
  const [subCategoryName, setSubCategoryName] = useState(""); 


  const inputRef = useRef();
  const history = useHistory();
  const getLeadMerchantList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);
    let apiUrl = `users/getMerchantLeads/1?page=${page + 1}&pageSize=${pageSize}`;
      if (sortBy && orderBy) {
          apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
      }
    const tempData = await getData(apiUrl)
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      const merchantLead = tempData.data.records
      setLeadMerchantData(merchantLead);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
    else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      })
    }
  };

  useEffect(() => {
    getLeadMerchantList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const downloadDumpData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("users/getExcelLeadMerchant/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      const downloadLink = document.createElement("a");
      downloadLink.href = tempData.data.Location;
      downloadLink.target = "_blank";
      downloadLink.rel = "noopener noreferrer";
      downloadLink.click();
    }
  };

  const displayDistributorInfo = (leadId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/LeadMerchantDetails?leadId=" + leadId;
    } else {
      history.push({
        pathname: "/LeadMerchantDetails",
        state: { leadId: leadId },
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1}.</span>
        ),
      },
      {
        Header: "Lead Ref Number",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.id}
                  onClick={() => displayDistributorInfo(cell.row.original.id)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {"L-" + cell.row.original.id}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Merchant Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact",
        accessor: "mobileNumber",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Category",
        accessor: "categoryName",
      },
      {
        Header: "Sub Category",
        accessor: "subCategoryName",
      },
      {
        Header: "Interest Status",
        accessor: "isInterested",
      },
      {
        Header: "Reason",
        accessor: "reason",
      },
      {
        Header: "Lead Created By",
        accessor: "employeeName",
      },
    ],
    [pageIndex,pageSize]
  );

  const merchantOption = [
    { value: "MobileNumber", label: "Mobile Number" },
    { value: "email", label: "Email" },
    // { value: "shopType", label: "Shop Type" },
    // { value: "shopName", label: "Shop Name" },
  ];
  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getLeadMerchantList();
    setSelectedOptionStatus(null);
  };

  const handleInputChange = (e) => {
    if (merchantRefrenceType === 'Mobile Number') {
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
        setSearchBoxValue(value);
    } else {
        setSearchBoxValue(e.target.value);
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
  } else {
    setErrorMessage("");
      setPageIndex(page);
  }
  };

  const handleSort = (column) => {
      let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getLeadMerchantList(pageIndex, pageSize, column.id, newOrderBy);
  };

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "MobileNumber") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    }
    if (merchantRefrenceType === "email") {
      filterData = {
        email: searchBoxValue,
      };
    }if (merchantRefrenceType === "shopType") {
      filterData = {
        shopType: searchBoxValue,
      };
    }if (merchantRefrenceType === "shopName") {
      filterData = {
        shopName: searchBoxValue,
      };
    }
    else {
      // alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`users/getMerchantLeads/1?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setLeadMerchantData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      });
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Lead Merchant List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="product"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={merchantRefrenceType === "Mobile Number" ? "tel" : "text"}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //     setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>

            <div className="col-md-3 pl-0 text-end">
              <button
                onClick={downloadDumpData}
                id="CSV"
                type="button"
                className="font-weight-bold download-csv transformBtn acceptDataCss"
              >
                <a href="javascript:void(0)">
                  <FaFileDownload />
                </a>
              </button>
            </div>

          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {leadMerchantData && (
          <Table 
          columns={columns} 
          data={leadMerchantData} 
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage} 
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
          />
        )}
      </div>
    
    </>
  )
}

export default LeadMerchantList

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined);
        }}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}
