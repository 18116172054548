import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Loader from "../component/Loader";
import Swal from "sweetalert2";
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";

const StyledTableContainer = styled('div')({
  overflowY: 'hidden',
  overflowX: 'auto',
});
  
const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  borderRadius: '10px', // Curve borders
  border: '1px solid #e0e0e0',
  '& thead tr': {
    backgroundColor: '#f5f5f5', // Light gray for header
  },
});
  
const StyledTableHead = styled('thead')({});

const StyledTableBodyRow = styled(TableRow)({
  '&:nth-of-type(even)': {
    backgroundColor: '#fafafa', // Alternate row color for light theme
  },
  '&:hover': {
    backgroundColor: '#f0f0f0', // Highlight on hover
  },
});  

function OnboardingStatusSummary() {
  const [userDashboardData, setUserDashboardData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [todayDate, setTodayDate] = useState(null);
  const [filterData, setFilterData] = useState(''); // Added to store the filter status
  const [apiErrorMsg, setApiErrorMsg] = useState();

  const formatter = new Intl.NumberFormat("en-IN");

  const statusOption = [
    { value: 'overall', label: 'Overall' },
    { value: 'today', label: 'Today' },
  ];

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
    ],
    []
  );

  const inputRef = useRef();

  const getOnboardingStatusData = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    setTodayDate(today);
    setIsLoadingOn(true);

    let apiUrl = `dashboard/onboardingStatusSummary`;

    if (filterData === 'today') {
      apiUrl = `dashboard/onboardingStatusSummary?fromDate=${today}&toDate=${today}`;
    }

    else if (filterData === 'overall') {
      apiUrl = `dashboard/onboardingStatusSummary`;
    }

    else if (fromDate && !toDate) {
      apiUrl = `dashboard/onboardingStatusSummary?fromDate=${fromDate}&toDate=${today}`;
    }

    else if (fromDate && toDate) {
      apiUrl = `dashboard/onboardingStatusSummary?fromDate=${fromDate}&toDate=${toDate}`;
    }

    try {
      const tempData = await getData(apiUrl);
      if (tempData.statusCode === 200) {
        setUserDashboardData(tempData.data);
        setTotalRecords(tempData.total);
        setErrorMessage("");
      } else {
        setApiErrorMsg(tempData?.response?.data?.errors.msg || "Something went wrong");
        Swal.fire({
          title: "Error!",
          text: tempData?.response?.data?.errors.msg || "Something went wrong",
        });
      }
    } catch (error) {
      setApiErrorMsg("API Error");
    } finally {
      setIsLoadingOn(false);
    }
  };

  useEffect(() => {
    getOnboardingStatusData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Onboarding Status",
        accessor: "onboardingStatus",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Approval Pending",
        accessor: "Approval Pending",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Limit Assigned",
        accessor: "Limit Assigned",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Rejected",
        accessor: "Rejected",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Total",
        accessor: "Total",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
    ],
    [pageIndex, pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const resetFilters = () => {
    setFromDate(null);
    setToDate(null);
    setFilterData('');
    window.location.reload();
  };

  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setToDate(formattedDate);
  };

  return (
    <div>
      {isLoadingOn && <Loader />}
      <div className="row alignmentTable">
        <div className="col-md-9">
        </div>
      </div>
      <div>
        <div className="centre">
          <h4 style={{ padding: '16px', textAlign: 'center' }}>Onboarding Status Summary</h4>
        </div>
      </div>
      <div className='customHeaderTable'>
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select 
                defaultValue={statusOption[0]}
                placeholder="Onboarding Status"
                name="role"
                options={statusOption}
                onChange={(e) => setFilterData(e.value)}
              />
            </div>
            <div className="col-md-3">
              <label>From :</label>&nbsp;
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={fromDate ? new Date(fromDate) : null}
                onChange={handleFromDateChange}
                placeholderText="Select Start Date"
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-3">
              <label>To :</label>&nbsp;
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={toDate ? new Date(toDate) : null}
                onChange={handleToDateChange}
                placeholderText="Select End Date"
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-2">
              <button className="searchTableData" onClick={getOnboardingStatusData}>
                <FaSearch /> Search
              </button>
            </div>
            <div className="col-md-2">
              <button className='resetTableData' onClick={resetFilters}>
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      {userDashboardData?.length > 0 ? (
        <TableComponent 
          columns={columns} 
          data={userDashboardData} 
          fromDate={fromDate} 
          toDate={toDate}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          getOnboardingStatusData={getOnboardingStatusData}
        />
      ) : (
        <p className="errorMessage">{apiErrorMsg}</p>
      )}
    </div>
  );
}

export default OnboardingStatusSummary;

const TableComponent = ({ columns, data }) => {
  return (
    <Paper elevation={3} style={{ padding: '10px' }}>
      
      <StyledTableContainer>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.accessor} style={{ fontWeight: 'bold', border: '2px solid #e0e0e0', backgroundColor: '#f67555', color: 'white' }}>
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableBodyRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.accessor} style={{ border: '1px solid #e0e0e0' }}>
                    {row[column.accessor] === null || row[column.accessor] === undefined
                      ? "-"
                      : row[column.accessor]}
                  </TableCell>
                ))}
              </StyledTableBodyRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Paper>
  );
};
