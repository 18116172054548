import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
    useSortBy,
} from "react-table";
import { FaSearch, } from 'react-icons/fa';
import { HiRefresh } from "react-icons/hi";
import '../css/makeTable.css';
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import TopShowMessage from '../component/TopShowMessage';
import Swal from "sweetalert2";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: "none",
        padding: "0px",
        minWidth: "30%",
    },
};



function AddMerchantNotification() {

    const [merchantUserData, setMerchantUserData] = useState();
    const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
    const [merchantRefrenceType, setMerchantRefrenceType] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [searchBoxValue, setSearchBoxValue] = useState();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [showTopMessage, setShowTopMessage] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("")
    const [notificationBody, setNotificationBody] = useState("")
    const [checkedRows, setCheckedRows] = useState({});
    const [checkedUserIds, setCheckedUserIds] = useState([]);

    // console.log("checkedUserIds-----", checkedUserIds);

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length;
        const [value, setValue] = React.useState(globalFilter);
        const onChange = useAsyncDebounce((value) => {
            setGlobalFilter(value || undefined);
        }, 200);

        return (
            <span>
                Search:{" "}
                <input
                    value={value || ""}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    className="searchFilter"
                />
            </span>
        );
    }
    const handleInputChange = (e) => {
        if (merchantRefrenceType === "userId" || "mobileNumber") {
            const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
            setSearchBoxValue(value);
        } else {
            setSearchBoxValue(e.target.value);
        }
    };
    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length;

        return (
            <div>
                <input
                    value={filterValue || ""}
                    onChange={(e) => {
                        setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                    }}
                    // placeholder={`Search ${count} records...`}
                    className="searchFilter"
                />
                <i className="flaticon-search searchIcon"></i>
            </div>
        );
    }

    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id]);
            });
            return [...options.values()];
        }, [id, preFilteredRows]);

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        );
    }

    function SliderColumnFilter({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) {
        // Calculate the min and max
        // using the preFilteredRows

        const [min, max] = React.useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
            preFilteredRows.forEach((row) => {
                min = Math.min(row.values[id], min);
                max = Math.max(row.values[id], max);
            });
            return [min, max];
        }, [id, preFilteredRows]);

        return (
            <>
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={filterValue || min}
                    onChange={(e) => {
                        setFilter(parseInt(e.target.value, 10));
                    }}
                />
                <button onClick={() => setFilter(undefined)}>Off</button>
            </>
        );
    }

    function NumberRangeColumnFilter({
        column: { filterValue = [], preFilteredRows, setFilter, id },
    }) {
        const [min, max] = React.useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
            preFilteredRows.forEach((row) => {
                min = Math.min(row.values[id], min);
                max = Math.max(row.values[id], max);
            });
            return [min, max];
        }, [id, preFilteredRows]);

        return (
            <div
                style={{
                    display: "flex",
                }}
            >
                <input
                    value={filterValue[0] || ""}
                    type="number"
                    onChange={(e) => {
                        const val = e.target.value;
                        setFilter((old = []) => [
                            val ? parseInt(val, 10) : undefined,
                            old[1],
                        ]);
                    }}
                    placeholder={`Min (${min})`}
                    style={{
                        width: "70px",
                        marginRight: "0.5rem",
                    }}
                />
                to
                <input
                    value={filterValue[1] || ""}
                    type="number"
                    onChange={(e) => {
                        const val = e.target.value;
                        setFilter((old = []) => [
                            old[0],
                            val ? parseInt(val, 10) : undefined,
                        ]);
                    }}
                    placeholder={`Max (${max})`}
                    style={{
                        width: "70px",
                        marginLeft: "0.5rem",
                    }}
                />
            </div>
        );
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    // Our table component
    function Table({ columns, data }) {
        const filterTypes = React.useMemo(
            () => ({
                // Add a new fuzzyTextFilterFn filter type.
                fuzzyText: fuzzyTextFilterFn,
                // Or, override the default text filter to use
                // "startWith"
                text: (rows, id, filterValue) => {
                    return rows.filter((row) => {
                        const rowValue = row.values[id];
                        return rowValue !== undefined
                            ? String(rowValue)
                                .toLowerCase()
                                .startsWith(String(filterValue).toLowerCase())
                            : true;
                    });
                },
            }),
            []
        );

        const defaultColumn = React.useMemo(
            () => ({
                // Let's set up our default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        );

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow,
            //state,
            visibleColumns,
            preGlobalFilteredRows,
            setGlobalFilter,
            page, // Instead of using 'rows', we'll use page,
            // which has only the rows for the active page

            // The rest of these things are super handy, too ;)
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                defaultColumn, // Be sure to pass the defaultColumn option
                filterTypes,
                initialState: { pageIndex: 0, pageSize: 5 },
            },
            useFilters, // useFilters!
            useGlobalFilter, // useGlobalFilter!
            useSortBy, //sort data
            usePagination //use pagination
        );

        return (
            <>
                <div>
                    <table {...getTableProps()} className="dashboardTable">
                        <thead>

                            {headerGroups.map((headerGroup) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="tableHeader"
                                >
                                    <th>
                                        <input
                                            type="checkbox"
                                            // checked={Object.values(checkedRows).every((isChecked) => isChecked)}
                                            checked={checkedUserIds.length === rows.length}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                console.log("checked-----", checked)
                                                setCheckedUserIds(checked ? rows.map(row => row.original.userId) : []);
                                                setCheckedRows((prevCheckedRows) => {
                                                    const newCheckedRows = { ...prevCheckedRows };
                                                    rows.forEach((row, i) => {
                                                        newCheckedRows[i] = checked;
                                                    });
                                                    console.log("newCheckedRows----", newCheckedRows)
                                                    return newCheckedRows;
                                                });
                                            }}
                                        />
                                    </th>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            {column.render("Header")}
                                            {/* Add a sort direction indicator */}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? "↓"
                                                        : "↑"
                                                    : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}

                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {/* Add row checkbox */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={checkedRows[i] || false}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    const userId = rows[i].original.userId;
                                                    setCheckedRows((prevState) => ({
                                                        ...prevState,
                                                        [i]: checked,
                                                    }));
                                                    setCheckedUserIds((prevIds) => {
                                                        if (checked) {
                                                            return [...prevIds, userId];
                                                        } else {
                                                            return prevIds.filter((id) => id !== userId);
                                                        }
                                                    });
                                                }}
                                            />
                                        </td>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} className="tableTd">
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot className="orderTableFooter">
                            {footerGroups.map((group) => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map((column) => (
                                        <td {...column.getFooterProps()}>
                                            {column.render("Footer")}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                    <div className="row m-0">
                        <div className="pagination col-lg-12">
                            <div className="col-lg-2">
                                <button
                                    className="defaultPaginationButton"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {"<<"}
                                </button>{" "}
                                <button
                                    className="defaultPaginationButton"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {"<"}
                                </button>{" "}
                                <button
                                    className="defaultPaginationButton"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {">"}
                                </button>{" "}
                                <button
                                    className="defaultPaginationButton"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {">>"}
                                </button>{" "}
                            </div>
                            <div className="col-lg-2">
                                <span>
                                    Total Records <strong>{rows.length}</strong>{" "}
                                </span>
                            </div>
                            <div className="col-lg-2">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                            </div>
                            <div className="col-lg-3">
                                <span>
                                    Go to page{" "}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(page);
                                        }}
                                        className="gotoPage"
                                    />
                                </span>{" "}
                            </div>
                            <div className="col-lg-3">
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                    className="selectDisplayNoOfRecords"
                                >
                                    {[5, 10, 20, 30, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <br />
                </div>
            </>
        );

    }

    // Define a custom filter filter function!
    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue >= filterValue;
        });
    }

    // This is an autoRemove method on the filter function that
    // when given the new filter value and returns true, the filter
    // will be automatically removed. Normally this is just an undefined
    // check, but here, we want to remove the filter if it's not a number
    filterGreaterThan.autoRemove = (val) => typeof val !== "number";


    const inputRef = useRef();


    const resetFilters = () => {
        inputRef.current.value = '';
        setMerchantRefrenceType('');
        setSearchBoxValue('');
        updateOrderStatus();
        setSelectedOptionStatus(null);
    };


    const data = useMemo(
        () => [
            { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
        ],
        []
    );

    const searchManualData = async () => {
        let filterData = {};

        if (merchantRefrenceType === "userId") {
            filterData = {
                merchantId: searchBoxValue,
            };
        } else if (merchantRefrenceType === "mobileNumber") {
            filterData = {
                mobileNumber: searchBoxValue,
            };
        }
        else {
            alert("Please select any one option");
        }

        setIsLoadingOn(true);

        const queryParams = new URLSearchParams(filterData).toString();

        console.log("queryParams---", queryParams)
        console.log("filterData---", filterData)

        const tempData = await getData(`users/getMerchantList/1?${queryParams}`);
        // console.log("tempData---", tempData);

        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            setMerchantUserData(tempData.data.records);
        }
    };


    const history = useHistory();

    const updateOrderStatus = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("users/getMerchantList/1")
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setMerchantUserData(tempData.data.records);
        }
    }

    useEffect(() => {
        updateOrderStatus();
    }, [])


    const statusModalOpen = (item) => {
        setIsOpen(true);
    };


    const closeModal = () => {
        setIsOpen(false);
    }

    const handleSendNotification = async () => {
        if(!notificationTitle){
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please Enter Title!",
            })
        }
        if(!notificationBody){
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Please Enter Message!",
            })
        }
        let filterData = {
            "userId": checkedUserIds,
            "title": notificationTitle,
            "body": notificationBody
        };
        setIsLoadingOn(true);
        const tempData = await postData("users/notification", filterData)
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setIsOpen(false);
            setShowTopMessage(true);
            setTimeout(() => {
                setShowTopMessage(false);
            }, 3000);
        }
    }

    const columns = useMemo(
        () => [

            {
                Header: 'User Id',
                accessor: "userId",
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Mobile Number',
                accessor: 'mobileNumber',
            },
            {
                Header: 'Status',
                accessor: 'statusName',
                Cell: (cell) => {
                    const status = cell.row.original.statusName;
                    return (
                        <>
                            <div className='text-center'>
                                <button
                                style={{ border: "none"}}
                                className={`badge badge-pill 
                                    ${status == "Active"
                                          ? "badge-success"
                                          : status == "Suspended"
                                            ? "badge-warning"
                                            : "badge-danger"
                                        }`}
                                    value={cell.row.original.statusName}
                                >
                                    {cell.row.original.statusName ? cell.row.original.statusName : "-"}
                                </button>
                            </div>
                        </>
                    );
                }
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );
    const merchantOption = [
        { value: 'userId', label: 'User Id' },
        { value: 'mobileNumber', label: 'Mobile Number' },
    ]

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            {showTopMessage ? <TopShowMessage message="You Have Send Successfully Notification" /> : ""}
            <div className='customHeaderTable'>
                <div className="container-fluid">
                    <div className="row alignmentTable">
                        <div className="col-md-2">
                            <Select
                                placeholder="Select Search"
                                name="role"
                                options={merchantOption}
                                value={selectedOptionStatus}
                                onChange={(e) => {
                                    setMerchantRefrenceType(e.value);
                                    setSelectedOptionStatus(e)
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <input
                                type={merchantRefrenceType === "userId" || "mobileNumber" ? 'tel' : 'text'}
                                className='searchInputField'
                                ref={inputRef}
                                // onChange={(e) => {
                                //     setSearchBoxValue(e.target.value);
                                // }}
                                onChange={handleInputChange}
                                placeholder='Enter Details'
                                value={searchBoxValue}
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <button className='searchTableData'
                                onClick={searchManualData}
                            ><FaSearch /> Search</button>
                        </div>

                        <div className="col-md-2 text-center" onClick={resetFilters}>
                            <button className='resetTableData' ><HiRefresh /> Reset</button>
                        </div>
                        <div className="col-lg-4 text-end">
                            <button className='btn btnAddCredit'
                                onClick={() => {
                                    const userRoleCheck = localStorage.getItem("USER_ROLE");
                                    if (userRoleCheck === "Admin" || userRoleCheck === "Operation") {
                                        statusModalOpen();
                                    }
                                }}
                            >Add Notification</button>
                        </div>
                    </div>
                </div>


            </div>
            <div className='orderListDataFooter'>
                {merchantUserData && <Table columns={columns} data={merchantUserData} />}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className='modalCloseBtn' onClick={closeModal}><FaRegTimesCircle /></button>
                <h4 className='pleaseSelectStatus'>Send Notification</h4>
                <div className='modalUserPaymentStatus'>
                    <div className="group">
                        <label>Notification Title</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Notification Title"
                            aria-label="Notification Title"
                            aria-describedby="button-addon2"
                            onChange={(e) => {
                                setNotificationTitle(e.target.value);
                            }}
                        />

                        <label>Notification Body</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Notification Message"
                            aria-label="body"
                            aria-describedby="button-addon2"
                            onChange={(e) => {
                                setNotificationBody(e.target.value);
                            }}
                        />

                        <div style={{ textAlign: "center", marginTop: 8 }}>
                            <button
                                className="btn btn-success"
                                type="button"
                                id="button-addon2"
                                onClick={handleSendNotification}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* ------------- status View Modal ---------------------- */}

        </div>
    );
}

export default AddMerchantNotification;

