import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaFileCsv } from "react-icons/fa6";
import { FaSearch, FaRegFilePdf, FaRegFileExcel } from "react-icons/fa";
import "../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { formatDate, formatDateTimeSecond } from "../component/common.js";
import { FaPlusCircle } from "react-icons/fa";
import Select from "react-select";


const customStylesDisbursement = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "25%",
    padding: 20,
  },
};

function RepaymentDetails(props) {
  const userRole = localStorage.getItem("USER_ROLE");
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get("userId");
  const orderId = searchParams.get("orderId");
  const loanType = searchParams.get("loanType");
  const loanId = searchParams.get("loanId");
  const [paymentFile, setPaymentFile] = useState()
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsDisbursement, setModalIsDisbursement] = useState(false);
  const [invoiceDistributorData, setInvoiceDistributorData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [formDisbursementData, setFormDisbursementData] = useState({
    utr: "",
    PaymentDate: "",
    paymentMood: "",
    paymentAmount: "",
    paymentPlatform: "",
    comment: "",
  });

  console.log("formDisbursementData++++",formDisbursementData)
  const formatter = new Intl.NumberFormat("en-IN");
  const handleDisbursedChange = (e) => {
    const { name } = e.target;
    setFormDisbursementData({
      ...formDisbursementData,
      [name]: e.target.value,
    });
  };

  const handleCheckboxChange = (rowId, extraChargeTypeId) => {
    setSelectedRows(prevSelectedRows => {
      const newSelectedRows = new Set(prevSelectedRows); // Create a new Set
      const rowData = { repaymentAmountId: rowId, extraChargeTypeId }; // Object to track the selection
      const existingItem = [...newSelectedRows].find(item => item.repaymentAmountId === rowId && item.extraChargeTypeId === extraChargeTypeId);

      if (existingItem) {
        newSelectedRows.delete(existingItem); // Remove the row if it's already selected
      } else {
        newSelectedRows.add(rowData); // Add the row if it's not selected
      }
      return newSelectedRows; // Return the updated Set
    });
  };


  const handleUpdateDisbursedInvoice = async (e) => {
    e.preventDefault();
    
    if (!paymentFile) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please Upload the Payment Document.',
      });
      return;
    }

    if (formDisbursementData) {
      let tempDataSend = {
        userId: userId,
        orderId: orderId,
        receivedAmount: formDisbursementData.paymentAmount,
        utr: formDisbursementData.utr,
        paymentMode: formDisbursementData.paymentMood,
        paymentDate: formDisbursementData.PaymentDate,
        paymentPlatform: formDisbursementData.paymentPlatform,
        paymentFile: paymentFile,
        loanType: loanType,
        comment: formDisbursementData.comment,
      };
      setIsLoadingOn(true);
      const tempData = await postData(
        `repayment/without-payment-gateway`,
        tempDataSend
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        getDetailsDataDistributor();
        setModalIsDisbursement(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
      }
      else{
        Swal.fire({
            icon: "error",
            title: "Error!",
            text: tempData?.data?.errors?.msg || "Somthing went wrong",
          });
      }
    }
  };

  const closeModal = () => {
    setModalIsDisbursement(false);
  };

  const getLoanBurification = async () => {
    setIsLoadingOn(true);
    let apiUrl = `repayment/loanBifurcation?loanId=${loanId}`;

    const tempData = await getData(apiUrl);
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    
    if (tempData.statusCode === 200) {
      setFileData(tempData.data);  // The data from your API
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.message || "Something went wrong!!!"
      });
    }
  };

  const handleSubmit = async () => {
    if (selectedRows.size === 0) {
      Swal.fire("Error!", "Please select at least one item.", "error");
      return;
    }

    const waveOffList = [...selectedRows].map(row => ({
      extraChargeTypeId: row.extraChargeTypeId,
      repaymentAmountId: row.repaymentAmountId,
    }));

    const payload = {
      loanId: loanId, 
      waveOffList: waveOffList
    };

    try {
      const response = await postData('repayment/repaymentWaveOff', payload);
      if (response.statusCode === 200) {
        Swal.fire({
            title: "Success!",
            text: "Data submitted successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload(); 
          });
      } else {
        Swal.fire("Error!", response?.message || "Failed to submit data.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Something went wrong with the API call.", "error");
    }
  };

  const getDetailsDataDistributor = async () => {
    setIsLoadingOn(true);
    const res = await getData(
      `repayment/repaymentDetails?orderType=${
        loanType == "ORDER" ? "Order" : "FundMyInvoice"
      }&orderTypeId=${orderId}`
    );
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      setInvoiceDistributorData(res?.data[0]);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile && !['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(selectedFile.type)) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please select a valid file format (.pdf, .jpg, .jpeg, .png)',
      });
      e.target.value = '';
      return;
    }
  
    if (selectedFile) {
      const reader = new FileReader();
  
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; 
        setPaymentFile(base64String);
      };
  
      reader.readAsDataURL(selectedFile);
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while reading the file',
        });
      };
    }
  };

  useEffect(() => {
    getDetailsDataDistributor();
    getLoanBurification();
  }, [orderId]);

  const data = useMemo(
    () => [
      {
        distId: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
        data1: "9988776786",
        action: "9988776786",
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "",
        Cell: ({ cell }) => {
          const srNo = cell.row.index;
          return <span>{srNo + 1}</span>;
        },
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: ({ cell }) => {
          const rowId = cell.row.original.repaymentAmountId; // Assuming unique repaymentAmountId
          const extraChargeTypeId = cell.row.original.extraChargeTypeId; // Get extraChargeTypeId from row data
          return (
            <input
              type="checkbox"
              checked={[...selectedRows].some(
                row => row.repaymentAmountId === rowId && row.extraChargeTypeId === extraChargeTypeId
              )} // Check if the row is selected
              onChange={() => handleCheckboxChange(rowId, extraChargeTypeId)} // Handle checkbox click
            />
          );
        },
      },
      {
        Header: "Repay ID",
        accessor: "repaymentAmountId",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.repaymentAmountId}
          </span>
        ),
      },
      {
        Header: "Pending Type",
        accessor: "pendingType",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.pendingType}
          </span>
        ),
      },
      {
        Header: "Pending Repayment Amount",
        accessor: "pendingRepaymentAmount",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.pendingRepaymentAmount ?? "N/A"} {/* Formatting to 2 decimals */}
          </span>
        ),
      },
      {
        Header: "Extra Charge Type ID",
        accessor: "extraChargeTypeId",
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
            {cell.row.original.extraChargeTypeId ?? "N/A"} {/* Display N/A if null */}
          </span>
        ),
      },
    ],
    [selectedRows]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "",
        Cell: ({ cell }) => {
          const srNo = cell.row.index;
          return <span>{srNo + 1}</span>;
        },
      },
      {
        Header: "Mobile Number",
        accessor: "customerPhone",
      },
      {
        Header: "Order Amount",
        accessor: "orderAmount",
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        Cell: ({ cell }) => {
          const paymentDate = cell.row.original.paymentDate;
          return <span>{paymentDate && formatDate(paymentDate)}</span>;
        },
      },
      {
        Header: "Payment Mode",
        accessor: "paymentMode",
      },
      {
        Header: "Status",
        accessor: "paymentStatusName",
        Cell: ({ row }) => {
          let orderStatus = row.original.paymentStatusName;
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {orderStatus && (
                <sapn
                  class={`badge badge-pill ${
                    orderStatus == "Paid" ? "badge-success" : orderStatus == "Active" ? 'badge-warning' : "badge-danger"
                  }`}
                >
                  {orderStatus}
                </sapn>
              )}
            </div>
          );
        },
      },
      {
        Header: "Download",
        accessor: "",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    const fileUrl = cell.row.original.paymentFilePath;
                    if (fileUrl) {
                      window.open(fileUrl, '_blank'); // Opens the file in a new tab
                    } else {
                      alert("File not available for download.");
                    }
                  }}
                  style={{ fontSize: 22, color: "#6E12F9", border: "none", background: "none" }}
                  title={`Download payment file for ${cell.row.original.orderCode}`}
                >
                  <FaRegFilePdf />
                </button>
              </div>
            </>
          );
        },
      }      
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className="noMarginSection">
      {isLoadingOn ? <Loader /> : ""}
      <section className="orderDetailsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="text-end">
              <button
                className="btn btnUploadQuotationss"
                onClick={() => {
                  if (userRole === "Admin" || userRole === "Operation") {
                    setModalIsDisbursement(true);
                  }
                }}
              >
                <FaPlusCircle />
              </button>
            </div>
            <div className="col-md-12">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item customUserMearginRepay">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      aria-expanded="true"
                    >
                      Repayment Details
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body">
                      <div className="container-fluid">
                        
                        <div className="row">
                        <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {orderId}
                            </h4>
                            <p className="repaymentShowHeading">
                              Invoice/Order ID 
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {invoiceDistributorData?.userName
                                ? invoiceDistributorData.userName
                                : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Shop Owner Name
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              ₹{" "}
                                 {invoiceDistributorData
                              ? invoiceDistributorData?.creditLimit &&
                              formatter.format(Number(invoiceDistributorData?.creditLimit).toFixed(2))
                              : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Assigned Limit
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                                 ₹{" "}
                                 {invoiceDistributorData
                              ? invoiceDistributorData?.usedCreditLimit &&
                              formatter.format(Number(invoiceDistributorData?.usedCreditLimit).toFixed(2))
                              : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Utilized Limit
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {invoiceDistributorData?.disbursedDate
                                ? formatDate(
                                    invoiceDistributorData?.disbursedDate
                                  )
                                : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Disbursement Date
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              ₹{" "}
                                 {invoiceDistributorData
                              ? invoiceDistributorData?.repaymentAmount &&
                              formatter.format(Number(invoiceDistributorData?.repaymentAmount).toFixed(2))
                              : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Repayment Amount
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {/* {invoiceDistributorData?.orderAmount	|| "-"} */}
                              ₹{" "}
                                 {invoiceDistributorData
                              ? invoiceDistributorData?.orderAmount &&
                              formatter.format(Number(invoiceDistributorData?.orderAmount).toFixed(2))
                              : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Order Amount
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {/* {invoiceDistributorData?.paidAmount	|| "-"} */}
                              ₹{" "}
                                 {invoiceDistributorData
                              ? invoiceDistributorData?.repaidAmount &&
                              formatter.format(Number(invoiceDistributorData?.repaidAmount).toFixed(2))
                              : "-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Paid Amount
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {invoiceDistributorData?.status == 16 ? "Paid" : 
                              invoiceDistributorData?.status == 15 ? "Paid By Credit" :
                              invoiceDistributorData?.status == 17 ? "Partially Paid":
                              invoiceDistributorData?.status == 3 ? "Pending" :"-"}
                            </h4>
                            <p className="repaymentShowHeading">
                              Status
                            </p>
                          </div>
                          <div className="col-md-2 text-center">
                            <h4 className="repymentDetailsPay">
                              {invoiceDistributorData?.dueDate
                                ? formatDate(invoiceDistributorData?.dueDate)
                                : "-"}
                            </h4>
                            <p className="repaymentShowHeading">Due Date</p>
                          </div>
                          <div className="col-md-12 distributorNamePaymentChange">
                            <p className="repaymentDistributorMultiple">
                              Distributor :{" "}
                              {/* {invoiceDistributorData?.orderDetails &&
                                invoiceDistributorData.orderDetails.map(
                                  (item, index) => (
                                    <span key={index}>
                                      {index + 1}. {item.distributorName}{" "}
                                    </span>
                                  )
                                )} */}
                                 {/* {invoiceDistributorData?.distributorName
                                ? invoiceDistributorData.distributorName
                                : "-"} */}
                                {invoiceDistributorData?.orderDetails && invoiceDistributorData.orderDetails.length > 0
                                  ? invoiceDistributorData.orderDetails.map((item, index) => (
                                      <span key={index}>
                                        {index + 1}. {item.distributorName ? item.distributorName : "-"}{" "}
                                      </span>
                                    ))
                                  : invoiceDistributorData?.distributorName
                                  ? invoiceDistributorData.distributorName
                                  : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Invoice No.
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse show"
                  >
                    {invoiceDistributorData &&
                    invoiceDistributorData?.paymentData ? (
                      <div className="accordion-body">
                        <Table
                          columns={columns}
                          data={invoiceDistributorData?.paymentData}
                        />
                      </div>
                    ) : (
                      <h5 style={{ padding: 10 }}>No Data Found</h5>
                    )}
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                     Waive off
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse show"
                  >
                    {fileData.length > 0  ? (
                      <div className="accordion-body">
                        <Table
                          columns={columns2}
                          data={fileData}
                        />
                      </div>
                    ) : (
                      <h5 style={{ padding: 10 }}>No Data Found</h5>
                    )}
                  </div>
                  {
                    fileData.length > 0 && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button
                          onClick={handleSubmit}
                          style={{
                            marginBottom:"20px",
                            padding: "10px 20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                     )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------- Set Disbursement -------------------- */}
      <Modal
        isOpen={modalIsDisbursement}
        onRequestClose={closeModal}
        style={customStylesDisbursement}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="dataImageViewAction">
          <form onSubmit={handleUpdateDisbursedInvoice}>
            <div className="form-group mt-2">
              <label htmlFor="utr">UTR Number</label>
              <input
                type="text"
                value={formDisbursementData.utr}
                onChange={handleDisbursedChange}
                name="utr"
                className="form-control"
                id="utr"
                placeholder="Enter Number"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="PaymentDate">Payment Date</label>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={formDisbursementData.PaymentDate}
                onChange={handleDisbursedChange}
                name="PaymentDate"
                className="form-control"
                id="PaymentDate"
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="paymentAmount">Payment Amount</label>
              <input
                type="number"
                value={formDisbursementData.paymentAmount}
                onChange={handleDisbursedChange}
                name="paymentAmount"
                className="form-control"
                id="paymentAmount"
                placeholder="Enter Amount"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="paymentMood">Mode of Payment</label>
              <Select
                placeholder="Select Payment Mood"
                name="paymentMood"
                options={[
                  { label: "Cash", value: "Cash" },
                  { label: "UPI", value: "UPI" },
                  { label: "Bank transfer (RTGS/NEFT)", value: "Bank transfer (RTGS/NEFT)" },
                  { label: "Daqi app", value: "Daqi app" },
                ]}
                // value={formDisbursementData.paymentMood}
                onChange={(e) => {
                  handleDisbursedChange({
                    target: { name: "paymentMood", value: e.value },
                  });
                }}
                id="paymentMood"
              />
            </div>

            <div className="mt-2">
              <label htmlFor="PaymentFilePath">Payment Document (PDF, JPG, JPEG)</label>
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg"
                onChange={handleFileChange}
                id="PaymentFilePath"
                className="form-control"
              />
            </div>

            {/* <div className="mt-2">
              <label htmlFor="paymentPlatform">Payment Platform</label>
              <Select
                placeholder="Select Payment Mood"
                name="paymentPlatform"
                options={[
                  { label: "Gpay", value: "Gpay" },
                  { label: "phonepe", value: "phonepe" },
                  { label: "Bank Transfer", value: "Bank Transfer" },
                ]}
                // value={formDisbursementData.paymentMood}
                onChange={(e) => {
                  handleDisbursedChange({
                    target: { name: "paymentPlatform", value: e.value },
                  });
                }}
                id="paymentPlatform"
              />
            </div> */}

            <div className="form-group mt-2">
              <label htmlFor="comment">Comment</label>
              <input
                type="text"
                value={formDisbursementData.comment}
                onChange={handleDisbursedChange}
                name="comment"
                className="form-control"
                id="comment"
                placeholder="Remark...."
              />
            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default RepaymentDetails;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 1000 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );


  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>
    </>
  );
}


function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
