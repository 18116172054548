import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  usePagination,
} from "react-table";
import { FaSearch, FaFileUpload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import { formatDateTime, formatDateTimeString } from "../component/common";
import Swal from "sweetalert2";
import Table from "../pages/ReactTable";

const userRole = localStorage.getItem("USER_ROLE");
console.log("Test i UseEffect", userRole);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    maxWidth: "50%",
  },
};

function ProductList() {
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [file, setFile] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [productData, setProductData] = useState({});
  const [loanStatusData, setLoanStatusData] = useState();
  const [formData, setFormData] = useState({
    productName: "",
    productCategory: "",
    brand: "",
    size: "",
    color: "",
    mrp: "",
    discount: "",
  });

  useEffect(() => {
    if (productData) {
      setFormData({
        productName: productData.productName || "",
        productCategory: productData.productCategory || "",
        brand: productData.brand || "",
        size: productData.size || "",
        color: productData.color || "",
        mrp: productData.mrp || "",
        discount: productData.discount || "",
      });
    }
  }, [productData]);

  const handleInputChange = (e) => {
      setSearchBoxValue(e.target.value);
  };

  const UploadBulkProduct = async (event) => {
    event.preventDefault();
    if (file) {
      const bulkUploadResponse = await postData(`electrician/upsertProductList`, {
        file: file
      })
      console.log("bulkUploadResponse === ", bulkUploadResponse);
      if (bulkUploadResponse.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Uploaded successfully!",
        });
      }
      else {
        console.log('No file selected.');
      }
    }
  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && ![
          "text/csv",
        ].includes(selectedFile.type)) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select CSV file",
      });
      e.target.value = ''; // Clear the input
      return;
    }
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        setFile(base64String);
      };
      reader.readAsDataURL(selectedFile)
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  const handelUpdateSubmit = async (event) => {
    event.preventDefault();
    if (!formData.productName) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Product Name!",
      });
    }
    if (!formData.productCategory) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Product Category! ",
      });
    }
    if (!formData.brand) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Brand!",
      });
    }
    if (!formData.mrp) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter MRP!",
      });
    }

    let tempData = { 
    productId:"",
    ...formData };

    if (
      formData &&
      productData &&
      productData.name &&
      productData.name.length > 0
    ) {
      tempData.productId = productData.id;
      setIsLoadingOn(true);
      const res = await postData(`electrician/updateProductCatalog`, tempData);
      setIsLoadingOn(false);
      if (res.statusCode === 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: res.message || "Data updated successfully!",
        });
        updateOrderStatus();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: res?.data?.errors?.msg || "Something went wrong",
        });
      }
    } else {
      setIsLoadingOn(true);
      const res = await postData(`electrician/addProductCatalog`, formData);
      setIsLoadingOn(false);
      if (res.statusCode === 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: res.message || "Data inserted successfully!",
        });
        updateOrderStatus();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: res?.data?.errors?.msg || "Something went wrong",
        });
      }
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );
  const handleGotoPage = (page) => {
      if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
      let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    updateOrderStatus(pageIndex, pageSize, column.id, newOrderBy);
  };
  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "productName") {
        filterData = {
          productName: searchBoxValue,
        };
    } else if (merchantRefrenceType === "productCategory") {
      filterData = {
        productCategory: searchBoxValue,
      };
    } else if (merchantRefrenceType === "brand") {
      filterData = {
        brand: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`electrician/getProductCatalogList?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      });
    }
  };

  const updateOrderStatus = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);
    let apiUrl = `electrician/getProductCatalogList?page=${page + 1}&pageSize=${pageSize}`;
    
      if (sortBy && orderBy) {
          apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
      }
    const tempData = await getData(apiUrl)
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    updateOrderStatus(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const closeModal = () => {
    setProductData({});
    setIsOpen(false);
  };

  const statusModalOpen = (item) => {
    setLoanStatusData(item);
    setIsOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Product Category",
        accessor: "productCategory",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Size",
        accessor: "size",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{cell.row.original.size ? cell.row.original.size : "-"}</h7>
            </>
          );
        },
      },
      {
        Header: "Color",
        accessor: "color",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{cell.row.original.color ? cell.row.original.color : "-"}</h7>
            </>
          );
        },
      },
      {
        Header: "MRP",
        accessor: "mrp",
      },
      {
        Header: "Discount",
        accessor: "discount",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{cell.row.original.discount ? cell.row.original.discount : "-"}</h7>
            </>
          );
        },
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{formatDateTimeString(cell.row.original.createdAt)}</h7>
            </>
          );
        },
      },
      {
        Header: "Updated At",
        accessor: "updatedAt",
        Cell: ({ cell }) => {
          return (
            <>
              <h7>{formatDateTimeString(cell.row.original.updatedAt)}</h7>
            </>
          );
        },
      },
      {
        Header: 'Action',
        accessor: '',
        Cell: ({ cell }) => {
          return (
            <>
              <div
                className="loanIdDownloadBtnDiv d-flex justify-content-between align-items-center text-center"
                style={{ padding: "5px !important;" }}
              >
                <button
                  className={`btn-sm btn-success`}
                  onClick={() => {
                    statusModalOpen(cell.row.original);
                  }}
                >
                  <span style={{ fontWeight: "500" }}>View</span>
                </button>
                
                {/* { userRole === "Admin" || userRole === "Operation" && ( */}
                  <button
                    className="btn-sm btn-warning"
                    onClick={() => {
                      if (userRole === "Admin" || userRole === "Operation") {
                        const { id } = cell.row.original;
                        console.log("id ===", id);                        
                        window.open(
                          `/EditProduct?id=${id}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    <span>Edit</span>
                  </button>
                {/* )} */}

              </div>
            </>
          );
        },
      },
    ],
    [pageIndex,pageSize]
  );

  const {
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const merchantOption = [
    { value: "productName", label: "Product Name" },
    { value: "productCategory", label: "Product Category" },
    { value: "brand", label: "Brand" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Added Product" />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Product List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="product"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={"text"}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //     setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>

            <div className="col-md-3 text-center">
              <input
                type="file"
                accept=".csv, .xls, .xlsx"
                onChange={handleFileChange}
                id="fileInput"
                className="form-control"
              />
            </div>
            <div className="col-md-1">
              <button style={{backgroundColor:"#f7674c",Color:"white"}}
                onClick={UploadBulkProduct}
                className="btn"
              >
                Bulk <FaFileUpload />
              </button>
            </div>

            {/* <div className="col-lg-4 text-end">
              {userRole === "Admin" && (
                <button
                  className="btn btnAddCredit"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add
                </button>
              )}
            </div> */}
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
          <Table 
          columns={columns} 
          data={merchantUserData} 
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage} 
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Product List Status</h4>
        <div className="modalUserPaymentStatus">
          {loanStatusData && (
            <table className="programList">
              <tbody>
                <tr>
                  <th>Product Name:</th>
                  <td>{loanStatusData?.productName || "-"}</td>
                </tr>
                <tr>
                  <th>Product Category:</th>
                  <td>{loanStatusData?.productCategory || "-"}</td>
                </tr>
                <tr>
                  <th>Brand:</th>
                  <td>{loanStatusData?.brand || "-"}</td>
                </tr>
                <tr>
                  <th>Size:</th>
                  <td>{loanStatusData?.size || "-"}</td>
                </tr>
                <tr>
                  <th>Color:</th>
                  <td>{loanStatusData?.color || "-"}</td>
                </tr>
                <tr>
                  <th>MRP:</th>
                  <td>{loanStatusData?.mrp || "-"}</td>
                </tr>
                <tr>
                  <th>Discount:</th>
                  <td>{loanStatusData?.discount || "-"}</td>
                </tr>
                <tr>
                  <th>Created At:</th>
                  <td>{loanStatusData?.createdAt ? formatDateTimeString(loanStatusData.createdAt) : "-"}</td>
                </tr>
                <tr>
                  <th>Updated At:</th>
                  <td>{loanStatusData?.updatedAt ? formatDateTimeString(loanStatusData.updatedAt) : "-"}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default ProductList;


function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
