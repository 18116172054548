import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Loader from "../component/Loader";
import Swal from "sweetalert2";
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledTableContainer = styled('div')({
    overflowY: 'hidden',
    overflowX: 'auto',
  });
  
  const StyledTable = styled('table')({
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: '10px', // Curve borders
    border: '1px solid #e0e0e0',
    '& thead tr': {
      backgroundColor: '#f5f5f5', // Light gray for header
    },
  });
  
  const StyledTableHead = styled('thead')({});
  
  const StyledTableBodyRow = styled(TableRow)({
    '&:nth-of-type(even)': {
      backgroundColor: '#fafafa', // Alternate row color for light theme
    },
    '&:hover': {
      backgroundColor: '#f0f0f0', // Highlight on hover
    },
  });  

function DisbursementAndRepaymentSummary() {
  const [userDashboardData, setUserDashboardData] = useState([]);
  const [userDpdData, setUserDpdData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");

  const userRole = localStorage.getItem("USER_ROLE");

  const formatter = new Intl.NumberFormat("en-IN");

  const inputRef = useRef();

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
    ],
    []
  );

  const getDashboardData = async () => {
    setIsLoadingOn(true);
    let apiUrl = `dashboard/disbursementAndRepaymentSummary`;

    const tempData = await getData(apiUrl);
    if (tempData.statusCode === 200) {
      setUserDashboardData(tempData.data);
      setTotalRecords(tempData.total);
      setErrorMessage("");
      setIsLoadingOn(false);
    } else {
      setIsLoadingOn(false);
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  };

  const getDpdWiseSummaryData = async () => {
    setIsLoadingOn(true);
    let apiUrl = `dashboard/dpdWiseSummary`;

    const tempData = await getData(apiUrl);
    if (tempData.statusCode === 200) {
      setUserDpdData(tempData.data);
      setTotalRecords(tempData.total);
      setErrorMessage("");
      setIsLoadingOn(false);
    } else {
      setIsLoadingOn(false);
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    getDashboardData();
    getDpdWiseSummaryData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "Month",
        Cell: ({ value }) => (
          <span style={{ fontWeight: 'bold' }}>
            {value === null || value === undefined ? "-" : value}
          </span>
        ),
      },
      {
        Header: "Disb #",
        accessor: "Disb_Count",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Disb Rs Lacs",
        accessor: "Disb_Rs_Lacs",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Invoice Rs Lacs",
        accessor: "Invoice_Rs_Lacs",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Paid Count",
        accessor: "Paid_Count",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Paid_Percent (%)",
        accessor: "Paid_Percent",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Not Paid Count",
        accessor: "Not_Paid_Count",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Not Paid Rs Lacs",
        accessor: "Not_Paid_Rs_Lacs",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Not Paid Percent (%)",
        accessor: "Not_Paid_Percent",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Partial Paid Count",
        accessor: "Partial_Paid_Count",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Partial Paid Rs Lacs",
        accessor: "Partial_Paid_Rs_Lacs",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Partial Paid Percent (%)",
        accessor: "Partial_Paid_Percent",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
      {
        Header: "Partial Paid Pending",
        accessor: "Partial_Paid_Pending",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Overall Pos Resolve",
        accessor: "Overall_Pos_Resolve",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value.toFixed(2)),
      },
      {
        Header: "Overall Pos Resolve Percent (%)",
        accessor: "Overall_Pos_Resolve_Percent",
        Cell: ({ value }) => (value === null || value === undefined ? "-" : value),
      },
    ],
    [pageIndex, pageSize]
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const resetFilters = () => {
    inputRef.current.value = "";
    getDashboardData();
  };

  return (
    <div>
      {isLoadingOn && <Loader />}
      <div className="row alignmentTable">
        <div className="col-md-9">
        </div>
      </div>
      <div>
        <div className="centre">
          <h4 style={{ padding: '16px', textAlign: 'center' }}>Disbursement And Repayment Summary</h4>
        </div>
      </div>
      {userDashboardData?.length > 0 ? (
        <TableComponent columns={columns} data={userDashboardData} />
      ) : (
        <p className="errorMessage">{errorMessage}</p>
      )}
    </div>
  );
}

export default DisbursementAndRepaymentSummary;

const TableComponent = ({ columns, data }) => {
    return (
      <Paper elevation={3} style={{ padding: '10px' }}>
        <StyledTableContainer>
          <StyledTable>
            <StyledTableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.accessor} style={{ fontWeight: 'bold', border: '2px solid #e0e0e0', backgroundColor: '#f67555',  color: 'white'}}>
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableBodyRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.accessor} style={{ border: '1px solid #e0e0e0' }}>
                      {
                        row[column.accessor] === null || row[column.accessor] === undefined
                          ? "-"
                          : column.accessor === "Month"
                            ? <span style={{ fontWeight: 'bold' }}>{row[column.accessor]}</span>
                            : column.accessor === "Disb_Rs_Lacs" || column.accessor === "Invoice_Rs_Lacs" || 
                              column.accessor === "Paid_Rs_Lacs" || column.accessor === "Not_Paid_Rs_Lacs" || 
                              column.accessor === "Partial_Paid_Rs_Lacs" || column.accessor === "Partial_Paid_Pending" || 
                              column.accessor === "Overall_Pos_Resolve"
                              ? <span>{row[column.accessor].toFixed(2)}</span>
                              : column.accessor === "Paid_Percent" || column.accessor === "Not_Paid_Percent" || 
                                column.accessor === "Partial_Paid_Percent" || column.accessor === "Overall_Pos_Resolve_Percent"
                                ? `${row[column.accessor]}%`
                                : <span>{row[column.accessor]}</span>
                      }
                    </TableCell>
                  ))}
                </StyledTableBodyRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
      </Paper>
    );
  };