import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Swal from "sweetalert2";
import {
  formatDate,
  formatNumberWithCommasAndDecimals,
  formatDateTimeSecond,
} from "../../component/common.js";
import { LuRefreshCcw } from "react-icons/lu";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Select from "react-select";
import BankStatementReport from "./BankStatementReport.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "20px",
  },
};

const MerchantCreditReport = () => {
  const [merchantReport, setMerchantReport] = useState();
  const [fieldData, setFieldData] = useState([]);
  const [fieldDataMessage, setFieldDataMessage] = useState("");
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [bureuReport, setBureuReport] = useState();
  const [gstScrubData, setGstScrubData] = useState();
  const [bureuReportMessage, setBureuReportMessage] = useState("");
  const [bureuParametersMessage, setBureuParametersMessage] = useState("");
  const [gstScrubMessage, setGstScrubMessage] = useState("");
  const [digiDataMessage, setDigiDataMessage] = useState("");
  const [derogData, setDerogData] = useState([]);
  const [equiFaxData, setEquiFaxData] = useState([]);
  const [systemGeneratedLimit, setSystemGeneratedLimit] = useState([]);
  const [equiFaxDataErrorMessage, setEquiFaxDataErrorMessage] = useState([]);
  const [creditLimitDataInfo, setCreditLimitDataInfo] = React.useState([]);
  const [statusModalIsOpen, setStatusModalIsOpen] = React.useState(false);
  const [lenderNameInfo, setLenderNameInfo] = useState([]);
  const [activeTab, setActiveTab] = useState("equifax");
  const searchParams = new URLSearchParams(window.location.search);
  const userRole = localStorage.getItem("USER_ROLE");
  const userId = searchParams.get("userId");
  const [formData, setFormData] = useState({
    status: "",
    creditLimit: "",
    tenure: "",
    transactionFees: "",
    processingFees: "",
    userId: userId,
    creditLimitId: "",
    financierId: "",
    reasons: [],
    comment: "",
  });

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (creditLimitDataInfo?.id) {
      setFormData((prevData) => ({
        ...prevData,
        creditLimitId: creditLimitDataInfo.id,
      }));
    }
    if (creditLimitDataInfo?.creditLimit) {
      setFormData((prevData) => ({
        ...prevData,
        creditLimit: creditLimitDataInfo?.creditLimit,
      }));
    }
  }, [creditLimitDataInfo]);

  console.log("userRole+++++==", userRole);

  const closeStatusModal = () => {
    setStatusModalIsOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInsertLoan = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to authorize this user credit limit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Authorized it!",
      cancelButtonText: "No, cancel!",
    });

    try {
      if (result.isConfirmed) {
        setIsLoadingOn(true);
        const response = await getData(
          `limit/updateCreditAuthorization/params?userId=${userId}`
        );
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          checkOrderCreditLimit();
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Data Inserted successfully!",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: response?.data?.errors?.msg || "Somthing went wrong",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Access the form data here and perform any necessary actions
    console.log("formdata----", formData);
    setIsLoadingOn(true);
    const res = await postData(`users/updateCreditLimit`, formData);
    setIsLoadingOn(false);
    if (res.statusCode == 200) {
      setStatusModalIsOpen(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Updated successfully!",
      });
      checkOrderCreditLimit();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: res?.data?.errors.msg || "Somthing went wrong",
      });
    }
  };

  const getFinancierLender = async () => {
    let tempLenderData = [];
    setIsLoadingOn(true);
    const tempData = await getData("financier/getFinancierList");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      for (let index = 0; index < tempData.data.length; index++) {
        const element = tempData.data[index];
        if (element) {
          tempLenderData.push({
            label: element.nameNBFC,
            value: element.id,
          });
        }
      }
      setLenderNameInfo(tempLenderData);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    getFinancierLender();
  }, []);

  const merchantFieldData = async() => {
    setIsLoadingOn(true);
    const tempData = await getData(
      `users/getUserShop/${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setFieldData(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setFieldDataMessage(tempData?.message);
      }
    } else {
      console.log("NO Data found");
    }
  }

  useEffect(() => {
    merchantFieldData();
  }, []);

  // ------------- Merchant Shop Address Data --------------------------

  async function getEquifaxData() {
    setIsLoadingOn(true);
    const tempData = await getData(`bureau/${userId}`);
    setIsLoadingOn(false);
    setEquiFaxDataErrorMessage("");
    if (
      tempData.statusCode == 200 &&
      Array.isArray(tempData.data) &&
      tempData.data.length > 0
    ) {
      const data = tempData.data[0];
      setEquiFaxData(data);
      console.log("tempData.data[0]", tempData.data[0]);

      if (data.hasOwnProperty("systemGeneratedLimit")) {
        setSystemGeneratedLimit(data?.systemGeneratedLimit);
        console.log("systemGeneratedLimit", data.systemGeneratedLimit);
      }
      if (data.error !== null && data.error !== "") {
        setEquiFaxDataErrorMessage(data.error);
      }
    } else {
      if (tempData?.response?.status == 400) {
        if (tempData?.response?.data?.errors?.msg === "No Record Found") {
          setEquiFaxDataErrorMessage(
            "Hey! It looks like the bureau has not been triggered yet. Please click on the Fetch Equifax Bureau button to get the Equifax credit report."
          );
        } else {
          setEquiFaxDataErrorMessage(tempData?.response?.data?.errors?.msg);
        }
      }
    }
  }
  // Age Calculate Function
  function calculateAge(dob) {
    if (!dob) return "N/A";

    const birthDate = new Date(dob);
    if (isNaN(birthDate)) return "N/A"; // Ensure valid date

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust the age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  async function merchantCreditReport() {
    setIsLoadingOn(true);
    const tempData = await getData(
      `digiData/getUserSmsFlag/nothing?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantReport(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setDigiDataMessage(tempData?.message);
      }
    } else {
      // Swal.fire({
      //   title: "Error!",
      //   text: tempData?.data?.errors.msg || "Something went wrong",
      // });
      console.log("NO Data found");
    }
  }

  const checkOrderCreditLimit = async () => {
    if (userId) {
      setIsLoadingOn(true);
      let res = await getData(`users/get_credit_limit/${userId}`);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        let tempValueData = [];
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          if (element) {
            tempValueData = element;
          }
        }
        setCreditLimitDataInfo(tempValueData);
      }
    }
  };

  useEffect(() => {
    checkOrderCreditLimit();
    getEquifaxData();
  }, []);

  const handleDerogData = async () => {
    setIsLoadingOn(true);
    try {
      const response = await getData(`digiData/getDerogData/${userId}`);
      if (response.statusCode === 200) {
        setDerogData(response.data);
        if (response.data == null) {
          setBureuReportMessage(response?.message);
        }
      } else {
        console.error(`Error: ${response.data.errors.msg}`);
      }
    } catch (error) {
      console.error(`API call failed: ${error}`);
    } finally {
      setIsLoadingOn(false);
    }
  };

  const handleRefreshDigiData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(
      `digiData/smsProcessData/nothing?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      merchantCreditReport();
    } else {
      // Swal.fire({
      //   title: "Error!",
      //   text: tempData?.data?.errors.msg || "Something went wrong",
      // });
      console.log("No Data Found");
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setFormData((prevData) => {
      const newReasons = checked
        ? [...prevData.reasons, name] // Add reason if checked
        : prevData.reasons.filter((reason) => reason !== name); // Remove reason if unchecked

      return {
        ...prevData,
        reasons: newReasons,
      };
    });
  };

  const availableReasons = [
    "Bureau",
    "GST",
    "DigiData",
    "Ledger",
    "Bank Statement",
    "Exception",
    "Limit Enhancement (Repayment)",
  ];

  const handleCommentChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      comment: value,
    }));
  };

  const handleRefreshEquiFaxData = async () => {
    handleDerogData();
    setIsLoadingOn(true);
    const tempData = await getData(`bureau/bureau/${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      if (!tempData.data || tempData.data.length === 0) {
        const ccrErrorDesc =
          tempData?.bureauResponse?.CCRResponse?.CIRReportDataLst?.[0]?.Error
            ?.ErrorDesc;
        if (ccrErrorDesc) {
          setEquiFaxDataErrorMessage(ccrErrorDesc);
        } else {
          const errorDesc = tempData?.bureauResponse?.Error?.ErrorDesc;
          setEquiFaxDataErrorMessage(errorDesc);
        }
      } else {
        getEquifaxData();
      }
    }
    if (tempData.response.data.status.code == 500) {
      setEquiFaxDataErrorMessage(tempData.response.data.status.errorMessage);
    }
  };

  const handleBureaSummaryData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(`digiData/getBureauSummaryData/${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setBureuReport(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setBureuParametersMessage(tempData?.message);
      }
    }
  };

  const handleGetSystemLimit = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(`limit/assignLimit/${userId}`);
    setIsLoadingOn(false);
    console.log("handleGetSystemLimit++", tempData);
    if (tempData.statusCode == 200) {
      checkOrderCreditLimit();
    } else {
      if (tempData?.response?.status == 400) {
        toast.error(tempData?.response?.data?.errors?.msg);
      }
    }
  };

  const getGstScrubData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(
      `digiData/getGSTScrub/params?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setGstScrubData(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setGstScrubMessage(tempData?.message);
      }
    }
  };

  useEffect(() => {
    merchantCreditReport();
    // handleDerogData();
    handleBureaSummaryData();
    getGstScrubData();
  }, []);
  const validateNumber = (value) => {
    const numberValue = Number(value);
    return typeof numberValue === "number" && !isNaN(numberValue)
      ? numberValue
      : 0;
  };

  const formatter = new Intl.NumberFormat("en-IN");
  const totalCreditLimit = validateNumber(creditLimitDataInfo?.creditLimit);
  const usedLimit = validateNumber(creditLimitDataInfo?.usedLimit);
  const availCreditLimit = validateNumber(
    creditLimitDataInfo?.availCreditLimit
  );

  console.log("creditLimitDataInfo+++++", creditLimitDataInfo);
  console.log("totalCreditLimit+++++", creditLimitDataInfo?.creditLimit);
  console.log("usedLimit+++++", usedLimit);
  console.log("creditLimitDataInfo+++++", creditLimitDataInfo);

  const usedLimitPercentage = totalCreditLimit
    ? ((usedLimit / totalCreditLimit) * 100).toFixed(2)
    : "0";

  const availLimitPercentage = totalCreditLimit
    ? ((availCreditLimit / totalCreditLimit) * 100).toFixed(2)
    : "0";

  // console.log("usedLimitPercentage+++++", usedLimitPercentage);
  // console.log("availLimitPercentage+++++", availLimitPercentage);

  const StatusValueCredit = [
    { label: "Limit Not Assigned", value: 25 },
    { label: "Limit Assigned", value: 26 },
    { label: "Limit Rejected", value: 27 },
  ];

  // ------------- Merchant Shop Address Data --------------------------

  function printEquifaxData() {
    const originalContent = document.body.innerHTML; // Save the original content of the page
    const printContent = document.getElementById("equifaxData").innerHTML; // Get only the equifax content
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  }

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <section className="creditReportMainSection">
        <div className="container-fluid">
          <div className="row assignLimitCustom pt-3">
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Status:{" "}
                <span
                  className={`blueColorStyleSpan ${
                    creditLimitDataInfo?.creditLimit == null
                      ? "text-primary"
                      : creditLimitDataInfo?.status == 28
                      ? "text-warning"
                      : creditLimitDataInfo?.creditLimit > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {creditLimitDataInfo?.creditLimit == null
                    ? "No action taken"
                    : creditLimitDataInfo?.creditLimit == 0
                    ? "Rejected"
                    : creditLimitDataInfo?.status == 28
                    ? "Approval Pending"
                    : creditLimitDataInfo?.creditLimit > 0
                    ? "Approved"
                    : "0"}
                </span>
              </p>
              <p className="creditLimitHeading">
                Remaining Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {availCreditLimit ? formatter.format(availCreditLimit) : 0}
                </span>
              </p>
            </div>
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Assigned Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {totalCreditLimit ? formatter.format(totalCreditLimit) : 0}
                </span>
                <button title="Assign Limit" className="butnAssignLimit">
                  {(localStorage.getItem("USER_ROLE") === "Admin" ||
                    localStorage.getItem("USER_ROLE") === "Tendative") && (
                    <FaEdit onClick={() => setStatusModalIsOpen(true)} />
                  )}
                </button>
              </p>
              <p className="creditLimitHeading">
                Used Limit(%):{" "}
                <span className="orangeColorStyleSpan">
                  {usedLimitPercentage}%
                </span>
              </p>
            </div>
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Used Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {usedLimit ? formatter.format(usedLimit) : 0}
                </span>
              </p>
              <p className="creditLimitHeading">
                Available Limit(%):{" "}
                <span className="orangeColorStyleSpan">
                  {availLimitPercentage}%
                </span>
              </p>
            </div>

            <div className="col-md-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  className="creditLimitHeading"
                  style={{ position: "relative" }}
                >
                  Band:{" "}
                  <span className="blueColorStyleSpan">
                    {" "}
                    {creditLimitDataInfo?.classification
                      ? creditLimitDataInfo?.classification
                      : "Not Generated"}
                  </span>
                </p>
                {creditLimitDataInfo?.status == 28 && (
                  <button
                    title="Authorize Limit"
                    onClick={handleInsertLoan}
                    className="authorizeLimitButton"
                  >
                    Authorize Limit
                  </button>
                )}
              </div>
              <p className="creditLimitHeading">
                System Limit:{" "}
                <span className="orangeColorStyleSpan">
                  {systemGeneratedLimit !== undefined
                    ? systemGeneratedLimit !== null
                      ? `₹ ${systemGeneratedLimit}`
                      : "Rejected"
                    : creditLimitDataInfo?.systemGeneratedLimit !== undefined
                    ? creditLimitDataInfo.systemGeneratedLimit !== null
                      ? `₹ ${creditLimitDataInfo.systemGeneratedLimit}`
                      : "Not Generated"
                    : "Not Generated"}
                </span>
                <button title="Generate Limit" className="butnGenrateLimit">
                  <LuRefreshCcw onClick={handleGetSystemLimit} />
                </button>
              </p>
            </div>
          </div>
        </div>

        <ul
          className="nav nav-tabs creditReportTab"
          id="myTab"
          role="tablist"
          style={{ marginTop: "30px" }}
        >
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "derog" ? "active" : ""}`}
              id="derog-tab"
              data-bs-toggle="tab"
              data-bs-target="#derog"
              type="button"
              role="tab"
              aria-controls="derog"
              aria-selected={activeTab === "derog"}
              onClick={() => {
                handleTabClick("derog");
                handleDerogData();
              }}
            >
              <strong>Derog Data</strong>
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "digiData" ? "active" : ""}`}
              id="digiData-tab"
              data-bs-toggle="tab"
              data-bs-target="#digiData"
              type="button"
              role="tab"
              aria-controls="digiData"
              aria-selected={activeTab === "digiData"}
              onClick={() => handleTabClick("digiData")}
            >
              <strong>Digi Data</strong>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "equifax" ? "active" : ""}`}
              id="equifax-tab"
              data-bs-toggle="tab"
              data-bs-target="#equifax"
              type="button"
              role="tab"
              aria-controls="equifax"
              aria-selected={activeTab === "equifax"}
              onClick={() => handleTabClick("equifax")}
            >
              <strong>Equifax Credit Report</strong>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "gst" ? "active" : ""}`}
              id="gst-tab"
              data-bs-toggle="tab"
              data-bs-target="#gst"
              type="button"
              role="tab"
              aria-controls="gst"
              aria-selected={activeTab === "gst"}
              onClick={() => handleTabClick("gst")}
            >
              <strong>GST Scrub</strong>
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "ledger" ? "active" : ""}`}
              id="ledger-tab"
              data-bs-toggle="tab"
              data-bs-target="#ledger"
              type="button"
              role="tab"
              aria-controls="ledger"
              aria-selected={activeTab === "ledger"}
              onClick={() => handleTabClick("ledger")}
            >
              <strong>Ledger/Sales</strong>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "field" ? "active" : ""}`}
              id="field-tab"
              data-bs-toggle="tab"
              data-bs-target="#field"
              type="button"
              role="tab"
              aria-controls="field"
              aria-selected={activeTab === "field"}
              onClick={() => handleTabClick("field")}
            >
              <strong>Field Data</strong>
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                activeTab === "bankReport" ? "active" : ""
              }`}
              id="bankReport-tab"
              data-bs-toggle="tab"
              data-bs-target="#bankReport"
              type="button"
              role="tab"
              aria-controls="bankReport"
              aria-selected={activeTab === "bankReport"}
              onClick={() => handleTabClick("bankReport")}
            >
              <strong>Bank Statement Analysis</strong>
            </button>
          </li>
        </ul>
        <div className="containerClass" id="myTabContent" class="right-side">
          <div
            className="tab-pane fade show active"
            id="equifax"
            role="tabpanel"
            aria-labelledby="equifax-tab"
          >
            {activeTab === "equifax" && Object.keys(equiFaxData).length > 0 ? (
              <div>
                <button
                  className="btn btn-primary"
                  style={{ float: "right" }}
                  onClick={() => printEquifaxData()}
                >
                  Print
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
        <div id="equifaxData">
          <div
            className="tab-pane fade show active"
            id="equifax"
            role="tabpanel"
            aria-labelledby="equifax-tab"
          >
            {activeTab === "equifax" && (
              <div>
                <div className="maincontainer">
                  {equiFaxData && Object.keys(equiFaxData).length > 0 ? null : (
                    <button
                      className="btn btn-success"
                      style={{ float: "right" }}
                      onClick={handleRefreshEquiFaxData}
                    >
                      Fetch Equifax Bureau
                    </button>
                  )}
                  <div>
                    {equiFaxDataErrorMessage &&
                    equiFaxDataErrorMessage != "" ? (
                      <p
                        style={{
                          lineHeight: 1.8,
                          fontSize: 16,
                          color: "red",
                          fontWeight: 600,
                        }}
                      >
                        <span>*</span>
                        &nbsp;{equiFaxDataErrorMessage}
                      </p>
                    ) : (
                      <>
                        <div class="containerClass">
                          <div class="left-side">
                            <h6 className="consumerAddressname">
                              <strong>
                                Consumer Name: {equiFaxData?.userName || "N/A"}
                              </strong>
                            </h6>
                          </div>
                          <div class="right-side">
                            <p>
                              Bureau Data Fetch Date:{" "}
                              {equiFaxData?.bureauCreatedDate || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="row personalInfo">
                          <div className="col-md-4">Personal Information</div>
                          <div className="col-md-4">Identification</div>
                          <div className="col-md-4">Contact Details</div>
                        </div>
                        <div className="personInfoData">
                          <div className="columnData">
                            <p>
                              DOB:{" "}
                              <b>{formatDate(equiFaxData?.dob) || "N/A"}</b>
                            </p>
                            <p>
                              Age:{" "}
                              <b>
                                {calculateAge(equiFaxData?.dob || "N/A")} years
                              </b>
                            </p>
                            <p>
                              Gender:{" "}
                              <b>
                                {equiFaxData?.gender == "M"
                                  ? "Male"
                                  : "Female" || "N/A"}
                              </b>
                            </p>
                            <p>
                              Occupation:{" "}
                              <b>{equiFaxData?.occupation || "N/A"}</b>
                            </p>
                          </div>
                          <div className="columnData">
                            <p>
                              PAN: <b>{equiFaxData?.pan || "N/A"}</b>
                            </p>
                            <p>
                              Voter ID: <b>{equiFaxData?.voterId || "N/A"}</b>
                            </p>
                            <p>
                              Passport ID: {equiFaxData?.passportId || "N/A"}
                            </p>
                          </div>
                          <div className="columnData">
                            <p>Home: {equiFaxData?.home || "N/A"}</p>
                            <p>Office: {equiFaxData?.office || "N/A"}</p>
                            <p>Mobile: {equiFaxData?.mobile || "N/A"}</p>
                            <p>
                              Email: <b>{equiFaxData?.email || "N/A"}</b>
                            </p>
                          </div>
                        </div>
                        <hr
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        />
                        <h6
                          className="consumerAddress"
                          style={{ marginTop: "20px" }}
                        >
                          <strong>Summary:</strong>
                        </h6>
                        <div className="row personalInfo">
                          <table className="consumerAddTable">
                            <th
                              style={{ textAlign: "center", border: "none" }}
                              colSpan={3}
                            >
                              Credit Summary Report
                            </th>
                            <tbody style={{ backgroundColor: "transparent" }}>
                              <td
                                className="recentAct"
                                style={{ textAlign: "left" }}
                                colSpan={3}
                              >
                                <h6>
                                  <u>
                                    <b>Bureau Summary Report:</b>
                                  </u>
                                </h6>
                              </td>
                              <tr>
                                <td className="recentAct">
                                  Number of Accounts :{" "}
                                  <b>
                                    {equiFaxData?.noOfAccounts !== null &&
                                    equiFaxData?.noOfAccounts !== undefined
                                      ? equiFaxData?.noOfAccounts
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total Balance Amount :{" "}
                                  <b>
                                    {equiFaxData?.totalBalanceAmount
                                      ? formatter.format(
                                          equiFaxData?.totalBalanceAmount
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Recent Account :{" "}
                                  <b>{equiFaxData?.recentAccount || "N/A"}</b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Number of Open Accounts :{" "}
                                  <b>
                                    {equiFaxData?.noOfActiveAccounts !== null &&
                                    equiFaxData?.noOfActiveAccounts !==
                                      undefined
                                      ? equiFaxData?.noOfActiveAccounts
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total Past Due Amount :{" "}
                                  <b>
                                    {equiFaxData?.totalPastDue !== null &&
                                    equiFaxData?.totalPastDue !== undefined
                                      ? formatter.format(
                                          equiFaxData?.totalPastDue
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Oldest Account :{" "}
                                  <b>{equiFaxData?.oldestAccount || "N/A"}</b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Number of Past Due Accounts:{" "}
                                  <b>
                                    {equiFaxData?.noOfPastDueAccounts !==
                                      null &&
                                    equiFaxData?.noOfPastDueAccounts !==
                                      undefined
                                      ? equiFaxData?.noOfPastDueAccounts
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total High Credit:{" "}
                                  <b>
                                    {equiFaxData?.totalHighCredit
                                      ? formatter.format(
                                          equiFaxData?.totalHighCredit
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total Credit Limit:{" "}
                                  <b>
                                    {equiFaxData?.totalCreditLimit
                                      ? formatter.format(
                                          equiFaxData?.totalCreditLimit
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Number of Write-off Accounts:{" "}
                                  <b>
                                    {equiFaxData?.noOfWriteOffs !== null &&
                                    equiFaxData?.noOfWriteOffs !== undefined
                                      ? equiFaxData?.noOfWriteOffs
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total Sanction Amount:{" "}
                                  <b>
                                    {equiFaxData?.totalSanctionAmount
                                      ? formatter.format(
                                          equiFaxData?.totalSanctionAmount
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Single Highest Credit:{" "}
                                  <b>
                                    {equiFaxData?.singleHighestCredit
                                      ? formatter.format(
                                          equiFaxData?.singleHighestCredit
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Number of Zero Balance Accounts{" "}
                                  <b>
                                    {" "}
                                    {equiFaxData?.noOfZeroBalanceAccounts !==
                                      null &&
                                    equiFaxData?.noOfZeroBalanceAccounts !==
                                      undefined
                                      ? equiFaxData.noOfZeroBalanceAccounts
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Total Monthly Payment Amount :{" "}
                                  <b>
                                    {equiFaxData?.totalMonthlyPaymentAmount
                                      ? formatter.format(
                                          equiFaxData?.totalMonthlyPaymentAmount
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Single Highest Sanction Amount :{" "}
                                  <b>
                                    {equiFaxData?.singleHighestSanctionAmount
                                      ? formatter.format(
                                          equiFaxData?.singleHighestSanctionAmount
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Most Severe Status 24 Months :{" "}
                                  <b>
                                    {equiFaxData?.mostSevereStatusWithIn24Months ||
                                      "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Average Open Balance :{" "}
                                  <b>
                                    {equiFaxData?.averageOpenBalance
                                      ? formatter.format(
                                          equiFaxData?.averageOpenBalance
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Single Highest Balance :{" "}
                                  <b>
                                    {equiFaxData?.singleHighestBalance
                                      ? formatter.format(
                                          equiFaxData?.singleHighestBalance
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="consumerAddTable">
                            <tbody style={{ backgroundColor: "transparent" }}>
                              <td
                                className="recentAct"
                                style={{ textAlign: "left" }}
                                colSpan={3}
                              >
                                <h6>
                                  <u>
                                    <b>Bureau Parameters:</b>
                                  </u>
                                </h6>
                              </td>

                              <tr>
                                <td className="recentAct">
                                  Live Loan Over Due Amount :{" "}
                                  <b>
                                    {equiFaxData?.liveLoanOverDueAmount
                                      ? formatter.format(
                                          equiFaxData?.liveLoanOverDueAmount
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                                <td className="recentAct">
                                  Bureau Veritas :{" "}
                                  <b>{equiFaxData?.bureauVeritas || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Maximum Loan Amount Taken :{" "}
                                  <b>
                                    {equiFaxData?.maximumLoanAmountTaken
                                      ? formatter.format(
                                          equiFaxData?.maximumLoanAmountTaken
                                        )
                                      : "N/A"}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  30+ DPD in last 6 months :{" "}
                                  <b>{equiFaxData?.plus30DPD || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  90+ DPD in last 12 months :{" "}
                                  <b>{equiFaxData?.plus90DPD || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  90+ DPD in last 24 months :{" "}
                                  <b>
                                    {equiFaxData?.plus90DPD24Months || "N/A"}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Count Of Written Off 2 Year :{" "}
                                  <b>{equiFaxData?.WrittenOff2Year || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Count Of Written Off 3 Year :{" "}
                                  <b>{equiFaxData?.WrittenOff3Year || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Is NTC : <b>{equiFaxData?.isNTC || "N/A"}</b>
                                </td>
                              </tr>
                              <tr>
                                <td className="recentAct">
                                  Bureau Score :{" "}
                                  <b>{equiFaxData?.value || "N/A"}</b>
                                </td>
                                <td className="recentAct"></td>
                                <td className="recentAct"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr style={{ margin: "30px" }} />
                        <h6
                          className="consumerAddress"
                          style={{ marginTop: "20px" }}
                        >
                          <strong>Consumer Address:</strong>
                        </h6>
                        <div
                          className="row personalInfo"
                          style={{ marginTop: "10px" }}
                        >
                          <table
                            style={{ border: "1px" }}
                            className="consumerAddTable"
                          >
                            <th>Type</th>
                            <th style={{ width: "60%" }}>Address</th>
                            <th>State</th>
                            <th>Postal</th>
                            <th>Date Posted</th>
                            <tbody style={{ backgroundColor: "transparent" }}>
                              {equiFaxData?.consumerAddress?.map(
                                (row, index) => (
                                  <tr key={index}>
                                    <td>{row.type || "N/A"}</td>
                                    <td>{row.address || "N/A"}</td>
                                    <td>{row.state || "N/A"}</td>
                                    <td>{row.postal || "N/A"}</td>
                                    <td>{row.DateReported || "N/A"}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <hr
                          style={{ margin: "30px", border: "1px solid black" }}
                        />
                        <h6 className="consumerAddress">
                          <strong>Equifax Score:</strong>
                        </h6>
                        <div
                          className="row"
                          style={{
                            backgroundColor: "transparent",
                            margin: "25px",
                            marginTop: "-15px",
                            marginBottom: "25px",
                          }}
                        >
                          <div className="col-md-3">
                            <strong>
                              Score Type:{" "}
                              {equiFaxData?.bereauScoreType || "N/A"}{" "}
                            </strong>
                          </div>
                          <div className="col-md-3">
                            <strong>
                              Version: {equiFaxData?.version || "N/A"}
                            </strong>
                          </div>
                          <div className="col-md-3">
                            <strong>
                              Score Name:{" "}
                              {equiFaxData?.bereauScoreName || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="col-md-3">
                            <strong>
                              Score: {equiFaxData?.value || "N/A"}
                            </strong>
                          </div>
                        </div>
                        <div className="row personalInfo">
                          <table className="consumerAddTable">
                            <th>SEQ</th>
                            <th>Code</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Bureau Score ID</th>
                            <tbody style={{ backgroundColor: "transparent" }}>
                              {equiFaxData?.scoringElements?.map(
                                (row, index) => (
                                  <tr key={index}>
                                    <td className="recentActInqury">
                                      {row.seq}
                                    </td>
                                    <td className="recentActInqury">
                                      {row.code}
                                    </td>
                                    <td className="recentActInqury">
                                      {row.type}
                                    </td>
                                    <td className="recentActInqury">
                                      {row.description}
                                    </td>
                                    <td className="recentActInqury">
                                      {row.bureauScoreId}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <hr
                          style={{
                            margin: "30px",
                            border: "1px solid black",
                            marginTop: "30px",
                          }}
                        />
                        <h6 className="consumerAddress">
                          <strong>Recent Activity:</strong>
                        </h6>
                        <div className="row personalInfo">
                          <table className="consumerAddTable">
                            <th
                              style={{ textAlign: "center", border: "none" }}
                              colSpan={4}
                            >
                              Recent Activity(last 90 Days)
                            </th>
                            <tbody style={{ backgroundColor: "transparent" }}>
                              <tr>
                                <td className="recentAct">
                                  Total Inquiries:{" "}
                                  <b>{equiFaxData?.totalInquiries || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Account Opened:{" "}
                                  <b>{equiFaxData?.accountsOpened || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Account Updated:{" "}
                                  <b>{equiFaxData?.accountsUpdated || "N/A"}</b>
                                </td>
                                <td className="recentAct">
                                  Account Delinquent:{" "}
                                  <b>
                                    {equiFaxData?.accountsDeliquent || "N/A"}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h6
                          className="consumerAddress"
                          style={{ marginTop: "30px" }}
                        >
                          <strong>Account Details:</strong>
                        </h6>
                        <div className="row personalInfo">
                          {equiFaxData?.accountDetails &&
                          equiFaxData.accountDetails.length > 0 ? (
                            <table className="consumerAddTable">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "center",
                                      border: "none",
                                    }}
                                    colSpan={4}
                                  >
                                    Accounts
                                  </th>
                                </tr>
                              </thead>
                              {equiFaxData?.accountDetails?.map(
                                (account, index) => (
                                  <React.Fragment key={index}>
                                    <tbody
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <tr>
                                        {/* <td className="recentAct">Sr No: </td> */}
                                        <td className="recentAct">
                                          <b>{index + 1}</b> . Account Number:{" "}
                                          <b>
                                            {account?.accountNumber || "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Balance:{" "}
                                          <b>
                                            {account?.balance
                                              ? formatter.format(
                                                  account?.balance
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Open:{" "}
                                          <b>
                                            {account.dateOpened == "0000-00-00"
                                              ? "N/A"
                                              : formatDate(account.dateOpened)}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Date Reported:{" "}
                                          <b>
                                            {account.dateReported ==
                                            "0000-00-00"
                                              ? "N/A"
                                              : formatDate(
                                                  account.dateReported
                                                )}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct">
                                          Institution:{" "}
                                          <b>{account.institution || "N/A"}</b>
                                        </td>
                                        <td className="recentAct">
                                          Past Due Amount:{" "}
                                          <b>
                                            {account?.pastDueAmount
                                              ? formatter.format(
                                                  account?.pastDueAmount
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Interest Rate:
                                          <b>
                                            {" "}
                                            {account.interestRate || "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Date Opened:{" "}
                                          <b>
                                            {account.dateOpened == "0000-00-00"
                                              ? "N/A"
                                              : formatDate(account.dateOpened)}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct">
                                          Type:{" "}
                                          <b>{account.accountType || "N/A"}</b>
                                        </td>
                                        <td className="recentAct">
                                          Last Payment:{" "}
                                          <b>
                                            {account?.lastPayment
                                              ? formatter.format(
                                                  account?.lastPayment
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Last Payment Date:{" "}
                                          <b>
                                            {account.lastPaymentDate ==
                                            "0000-00-00"
                                              ? "N/A"
                                              : formatDate(
                                                  account.lastPaymentDate
                                                )}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Date Closed:{" "}
                                          <b>
                                            {account.dateClosed != "0000-00-00"
                                              ? formatDate(account.dateClosed)
                                              : "N/A"}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct">
                                          Ownership Type:{" "}
                                          <b>
                                            {account.ownershipType || "N/A"}
                                          </b>
                                        </td>

                                        <td className="recentAct">
                                          Sanction Amount:{" "}
                                          <b>
                                            {account?.sanctionAmount
                                              ? formatter.format(
                                                  account?.sanctionAmount
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Reason:{" "}
                                          <b>{account.reason || "N/A"}</b>
                                        </td>
                                        <td className="recentAct">
                                          Credit Limit:{" "}
                                          <b>
                                            {account?.creditLimit
                                              ? formatter.format(
                                                  account?.creditLimit
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct">
                                          Installment Amount:{" "}
                                          <b>
                                            {account.installmentAmount || "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Repayment Tenure:{" "}
                                          <b>
                                            {account.repaymentTenure || "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Monthly Payment Amount:{" "}
                                          <b>
                                            {account?.monthlyPaymentAmount
                                              ? formatter.format(
                                                  account?.monthlyPaymentAmount
                                                )
                                              : "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct" colSpan={4}>
                                          Term Frequency:{" "}
                                          <b>
                                            {account.termFrequency || "N/A"}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct">
                                          Collateral Value:{" "}
                                          <b>
                                            {account.collateralValue || "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Bureau CirId:{" "}
                                          <b>{account.bureauCirId || "N/A"}</b>
                                        </td>

                                        <td className="recentAct">
                                          Asset Classification:{" "}
                                          <b>
                                            {account.assetClassification ||
                                              "N/A"}
                                          </b>
                                        </td>
                                        <td className="recentAct">
                                          Account Status:{" "}
                                          <b>
                                            {account.accountStatus || "N/A"}
                                          </b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="recentAct" colSpan={4}>
                                          Write-Off Amount:{" "}
                                          <b>
                                            {account?.writeOffAmount !== null &&
                                            account?.writeOffAmount !==
                                              undefined
                                              ? account?.writeOffAmount
                                              : "N/A"}
                                          </b>
                                        </td>
                                      </tr>
                                      {account.history &&
                                        JSON.parse(account.history).length >
                                          0 && (
                                          <>
                                            <tr>
                                              <td className="historySec">
                                                <u>History</u>
                                              </td>
                                              <td className="historySec"></td>
                                              <td className="historySec"></td>
                                              <td className="historySec"></td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="historySec"
                                                colSpan={4}
                                              >
                                                <table className="innerTable">
                                                  <tbody
                                                    style={{
                                                      backgroundColor:
                                                        "transparent",
                                                    }}
                                                  >
                                                    {JSON.parse(account.history)
                                                      .reduce(
                                                        (acc, item, index) => {
                                                          const rowIndex =
                                                            Math.floor(
                                                              index / 15
                                                            );
                                                          if (!acc[rowIndex]) {
                                                            acc[rowIndex] = {
                                                              keys: [],
                                                              paymentStatus: [],
                                                              assetClassification:
                                                                [],
                                                              suitFiledStatus:
                                                                [],
                                                            };
                                                          }

                                                          acc[
                                                            rowIndex
                                                          ].keys.push(item.key);
                                                          acc[
                                                            rowIndex
                                                          ].paymentStatus.push(
                                                            item.PaymentStatus
                                                          );
                                                          acc[
                                                            rowIndex
                                                          ].assetClassification.push(
                                                            item.AssetClassificationStatus
                                                          );
                                                          acc[
                                                            rowIndex
                                                          ].suitFiledStatus.push(
                                                            item.SuitFiledStatus
                                                          );

                                                          return acc;
                                                        },
                                                        []
                                                      )
                                                      .map((row, rowIndex) => (
                                                        <React.Fragment
                                                          key={rowIndex}
                                                        >
                                                          <tr>
                                                            <td
                                                              className="historySec"
                                                              colSpan={4}
                                                            >
                                                              Key:
                                                            </td>
                                                            {row.keys.map(
                                                              (
                                                                key,
                                                                cellIndex
                                                              ) => (
                                                                <td
                                                                  key={
                                                                    cellIndex
                                                                  }
                                                                >
                                                                  {key}
                                                                </td>
                                                              )
                                                            )}
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              className="historySec"
                                                              colSpan={4}
                                                            >
                                                              Account Status:
                                                            </td>
                                                            {row.paymentStatus.map(
                                                              (
                                                                status,
                                                                cellIndex
                                                              ) => (
                                                                <td
                                                                  key={
                                                                    cellIndex
                                                                  }
                                                                >
                                                                  {status}
                                                                </td>
                                                              )
                                                            )}
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              className="historySec"
                                                              colSpan={4}
                                                            >
                                                              Asset
                                                              Classification:
                                                            </td>
                                                            {row.assetClassification.map(
                                                              (
                                                                status,
                                                                cellIndex
                                                              ) => (
                                                                <td
                                                                  key={
                                                                    cellIndex
                                                                  }
                                                                >
                                                                  {status}
                                                                </td>
                                                              )
                                                            )}
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              className="historySec"
                                                              colSpan={4}
                                                            >
                                                              Suit Filed Status:
                                                            </td>
                                                            {row.suitFiledStatus.map(
                                                              (
                                                                status,
                                                                cellIndex
                                                              ) => (
                                                                <td
                                                                  key={
                                                                    cellIndex
                                                                  }
                                                                >
                                                                  {status}
                                                                </td>
                                                              )
                                                            )}
                                                          </tr>
                                                          {JSON.parse(
                                                            account.history
                                                          ).length > 15 && (
                                                            <tr>
                                                              <td className="historySec"></td>
                                                              <td className="historySec"></td>
                                                              <td className="historySec"></td>
                                                              <td className="historySec"></td>
                                                            </tr>
                                                          )}
                                                        </React.Fragment>
                                                      ))}
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      <tr className="historySec" colSpan={4}>
                                        <td className="historySec"></td>
                                        <td className="historySec"></td>
                                        <td className="historySec"></td>
                                        <td className="historySec"></td>
                                      </tr>
                                    </tbody>
                                  </React.Fragment>
                                )
                              )}
                            </table>
                          ) : (
                            <p>No account details available.</p>
                          )}
                        </div>
                        <hr
                          style={{
                            margin: "30px",
                            border: "1px solid black",
                            marginTop: "35px",
                          }}
                        />
                        <h6
                          className="consumerAddress"
                          style={{ marginTop: "30px" }}
                        >
                          <strong>Enquiry Summary:</strong>
                        </h6>
                        <div className="row personalInfo">
                          <table className="consumerAddTable">
                            <th>Purpose</th>
                            <th>Total</th>
                            <th>Past 30 Days</th>
                            <th>Past 12 Months</th>
                            <th>Past 24 Months</th>
                            <th>Recent</th>
                            <tbody style={{ backgroundColor: "transparent" }}>
                              <tr>
                                <td className="recentActInqury">
                                  {equiFaxData?.purpose || "N/A"}
                                </td>
                                <td className="recentActInqury">
                                  {equiFaxData?.total || "N/A"}
                                </td>
                                <td className="recentActInqury">
                                  {equiFaxData?.past30Days || "N/A"}
                                </td>
                                <td className="recentActInqury">
                                  {equiFaxData?.past12Months || "N/A"}
                                </td>
                                <td className="recentActInqury">
                                  {equiFaxData?.past24Months || "N/A"}
                                </td>
                                <td className="recentActInqury">
                                  {equiFaxData?.recent
                                    ? formatDateTimeSecond(equiFaxData?.recent)
                                    : "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="accordion d-none"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        Bureau Parameters
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-md-12">
                            {bureuParametersMessage ? (
                              <p
                                className="mt-3"
                                style={{
                                  lineHeight: 1.8,
                                  fontSize: 16,
                                  color: "red",
                                  fontWeight: 600,
                                }}
                              >
                                <span>*</span> {bureuParametersMessage}
                              </p>
                            ) : (
                              <div className="bankTableDetailsData">
                                <table className="tableRowAdjustCustom">
                                  <tr>
                                    <th>Mobile Number</th>
                                    <td>
                                      {bureuReport?.mobileNumber
                                        ? bureuReport?.mobileNumber
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>live Loan Over Due Amount</th>
                                    <td>
                                      {bureuReport?.liveLoanOverDueAmount
                                        ? bureuReport?.liveLoanOverDueAmount
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Bureau Veritas</th>
                                    <td>
                                      {bureuReport?.bureauVeritas
                                        ? bureuReport?.bureauVeritas
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Maximum Loan Amount Taken</th>
                                    <td>
                                      {bureuReport?.maximumLoanAmountTaken
                                        ? bureuReport?.maximumLoanAmountTaken
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Bureau Score</th>
                                    <td>
                                      {bureuReport?.bureauScore
                                        ? bureuReport?.bureauScore
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>month Since 1 Dpd</th>
                                    <td>
                                      {bureuReport?.monthSince1Dpd
                                        ? bureuReport?.monthSince1Dpd
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Month Since 30 Dpd</th>
                                    <td>
                                      {bureuReport?.monthSince30Dpd
                                        ? bureuReport?.monthSince30Dpd
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month Since 60 Dpd</th>
                                    <td>
                                      {bureuReport?.monthSince60Dpd
                                        ? bureuReport?.monthSince60Dpd
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month Since 90 Dpd</th>
                                    <td>
                                      {bureuReport?.monthSince90Dpd
                                        ? bureuReport?.monthSince90Dpd
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Month Since 180 Dpd</th>
                                    <td>
                                      {bureuReport?.monthSince180Dpd
                                        ? bureuReport?.monthSince180Dpd
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Count Of Written Off 2 Year</th>
                                    <td>
                                      {bureuReport?.countOfWrittenOff2Y
                                        ? bureuReport?.countOfWrittenOff2Y
                                        : "-"}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Count Of Written Off 3 Year</th>
                                    <td>
                                      {bureuReport?.countOfWrittenOff3Y
                                        ? bureuReport?.countOfWrittenOff3Y
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Is NTC</th>
                                    <td>
                                      {bureuReport?.isNTC
                                        ? bureuReport?.isNTC
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Bounce Rate Percent</th>
                                    <td>
                                      {bureuReport?.bounceRatePercent
                                        ? bureuReport?.bounceRatePercent
                                        : "-"}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          </div>

          <div
            className="tab-pane fade"
            id="derog"
            role="tabpanel"
            aria-labelledby="derog-tab"
          >
            {activeTab === "derog" && (
              <div className="row">
                <div className="col-md-12">
                  {bureuReportMessage && (
                    <p
                      className="mt-3"
                      style={{
                        lineHeight: 1.8,
                        fontSize: 16,
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      <span>*</span> {bureuReportMessage}
                    </p>
                  )}
                </div>
                <div className="col-md-12 table-container">
                  {isLoadingOn ? (
                    <p>Loading...</p>
                  ) : (
                    derogData &&
                    derogData.map((row, index) => (
                      <div className="table-wrapper" key={index}>
                        <table className="data-table">
                          <tr>
                            <th>Lender name</th>
                            <td>{row.lender_name ? row.lender_name : "N/A"}</td>
                          </tr>
                          <tr>
                            <th>Derog Status</th>
                            <td>
                              {row.derog_status ? row.derog_status : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Derog Reason</th>
                            <td>
                              {row.derog_reason ? row.derog_reason : "N/A"}
                            </td>
                          </tr>
                          {row.caveat && (
                            <tr>
                              <td
                                style={{
                                  color: "#0000f9",
                                  textAlign: "center",
                                }}
                                colSpan={2}
                              >
                                {row.caveat ? row.caveat : "N/A"}
                              </td>
                            </tr>
                          )}
                        </table>
                        <br />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="bureau"
            role="tabpanel"
            aria-labelledby="bureau-tab"
          >
            {activeTab === "bureau" && (
              <div className="row">
                <div className="col-md-12">
                  {bureuParametersMessage ? (
                    <p
                      className="mt-3"
                      style={{
                        lineHeight: 1.8,
                        fontSize: 16,
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      <span>*</span> {bureuParametersMessage}
                    </p>
                  ) : (
                    <div className="bankTableDetailsData">
                      <table className="tableRowAdjustCustom">
                        <tr>
                          <th>Mobile Number</th>
                          <td>
                            {bureuReport?.mobileNumber
                              ? bureuReport?.mobileNumber
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>live Loan Over Due Amount</th>
                          <td>
                            {bureuReport?.liveLoanOverDueAmount
                              ? bureuReport?.liveLoanOverDueAmount
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Bureau Veritas</th>
                          <td>
                            {bureuReport?.bureauVeritas
                              ? bureuReport?.bureauVeritas
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Maximum Loan Amount Taken</th>
                          <td>
                            {bureuReport?.maximumLoanAmountTaken
                              ? bureuReport?.maximumLoanAmountTaken
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Bureau Score</th>
                          <td>
                            {bureuReport?.bureauScore
                              ? bureuReport?.bureauScore
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>month Since 1 Dpd</th>
                          <td>
                            {bureuReport?.monthSince1Dpd
                              ? bureuReport?.monthSince1Dpd
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Month Since 30 Dpd</th>
                          <td>
                            {bureuReport?.monthSince30Dpd
                              ? bureuReport?.monthSince30Dpd
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Month Since 60 Dpd</th>
                          <td>
                            {bureuReport?.monthSince60Dpd
                              ? bureuReport?.monthSince60Dpd
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Month Since 90 Dpd</th>
                          <td>
                            {bureuReport?.monthSince90Dpd
                              ? bureuReport?.monthSince90Dpd
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Month Since 180 Dpd</th>
                          <td>
                            {bureuReport?.monthSince180Dpd
                              ? bureuReport?.monthSince180Dpd
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Count Of Written Off 2 Year</th>
                          <td>
                            {bureuReport?.countOfWrittenOff2Y
                              ? bureuReport?.countOfWrittenOff2Y
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>Count Of Written Off 3 Year</th>
                          <td>
                            {bureuReport?.countOfWrittenOff3Y
                              ? bureuReport?.countOfWrittenOff3Y
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Is NTC</th>
                          <td>
                            {bureuReport?.isNTC ? bureuReport?.isNTC : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Bounce Rate Percent</th>
                          <td>
                            {bureuReport?.bounceRatePercent
                              ? bureuReport?.bounceRatePercent
                              : "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="gst"
            role="tabpanel"
            aria-labelledby="gst-tab"
          >
            {activeTab === "gst" && (
              <div className="row">
                <div className="col-md-12">
                  {gstScrubMessage ? (
                    <p
                      className="mt-3"
                      style={{
                        lineHeight: 1.8,
                        fontSize: 16,
                        color: "red",
                        fontWeight: 600,
                      }}
                    >
                      <san>*</san> {gstScrubMessage}
                    </p>
                  ) : (
                    <div
                      className="bankTableDetailsData"
                      style={{ marginTop: "30px" }}
                    >
                      <table className="tableRowAdjustCustom">
                        <tr>
                          <th>GST No.</th>
                          <td>
                            {gstScrubData?.gstin ? gstScrubData?.gstin : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>GST revenue year</th>
                          <td>
                            {gstScrubData?.annualAggregateTurnoverYear
                              ? gstScrubData?.annualAggregateTurnoverYear
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>GST revenue slab</th>
                          <td>
                            {gstScrubData?.annualAggregateTurnover
                              ? gstScrubData?.annualAggregateTurnover
                              : "-"}
                          </td>
                        </tr>

                        {/* <tr>
                          <th>Gst induced income</th>
                          <td>{gstScrubData?.gstin}</td>
                        </tr> */}

                        <tr>
                          <th>GST Aadhaar Verified</th>
                          <td>
                            {gstScrubData?.aadhaarVerified == "True"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <th>Aadhaar Verification Date</th>
                          <td>
                            {gstScrubData?.aadhaarVerificationDate
                              ? gstScrubData.aadhaarVerificationDate
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>constitute of business</th>
                          <td>
                            {gstScrubData?.constitutionOfBusiness
                              ? gstScrubData?.constitutionOfBusiness
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>GST Status</th>
                          <td>
                            <p
                              className={
                                gstScrubData?.status == "Active"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {gstScrubData?.status
                                ? gstScrubData?.status
                                : "-"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <th>Tax payer type</th>
                          <td>
                            {gstScrubData?.taxpayerType
                              ? gstScrubData?.taxpayerType
                              : "_"}
                          </td>
                        </tr>
                        <tr>
                          <th>GST Address</th>
                          <td>
                            {gstScrubData?.principalAddress
                              ? gstScrubData?.principalAddress
                              : "-"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>GST Name</th>
                          <td>{gstScrubData?.legalName ? gstScrubData?.legalName  : "-"}</td>
                        </tr> */}
                        <tr>
                          <th>GST Mobile Number</th>
                          <td>
                            {gstScrubData?.principalAddressMobile
                              ? gstScrubData?.principalAddressMobile
                              : "-"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>GST additional address</th>
                          <td>
                          {gstScrubData?.legalName ? gstScrubData?.legalName  : "-"}
                          </td>
                        </tr> */}

                        <tr>
                          <th>GST legal Name</th>
                          <td>
                            {gstScrubData?.legalName
                              ? gstScrubData?.legalName
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <th>GST trade name</th>
                          <td>
                            {gstScrubData?.tradeName
                              ? gstScrubData?.tradeName
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>State Jurisdiction</th>
                          <td>
                            {gstScrubData?.stateJurisdiction
                              ? gstScrubData?.stateJurisdiction
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Nature of Business</th>
                          <td>
                            {gstScrubData?.natureOfBusiness
                              ? gstScrubData?.natureOfBusiness
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>GST Registration Date</th>
                          <td>
                            {gstScrubData?.dateOfRegistration
                              ? gstScrubData?.dateOfRegistration
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>PAN Number</th>
                          <td>{gstScrubData?.pan ? gstScrubData?.pan : "-"}</td>
                        </tr>
                        <tr>
                          <th>Annual Gst TurnOver</th>
                          <td>
                            {gstScrubData?.annualGstTurnOver
                              ? gstScrubData?.annualGstTurnOver
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Monthly Gst TurnOver</th>
                          <td>
                            {gstScrubData?.monthlyGstTurnOver
                              ? gstScrubData?.monthlyGstTurnOver
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Gross Total Income</th>
                          <td>
                            {gstScrubData?.grossTotalIncome
                              ? gstScrubData?.grossTotalIncome
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Financial Year For GTI</th>
                          <td>
                            {gstScrubData?.fyForGrossTotalIncome
                              ? gstScrubData?.fyForGrossTotalIncome
                              : "-"}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="digiData"
            role="tabpanel"
            aria-labelledby="digiData-tab"
          >
            {activeTab === "digiData" && (
              <div className="row">
                {digiDataMessage ? (
                  <>
                    <div className="col-md-6">
                      <p
                        className="mt-3"
                        style={{
                          lineHeight: 1.8,
                          fontSize: 16,
                          color: "red",
                          fontWeight: 600,
                        }}
                      >
                        <span>*</span> {digiDataMessage}
                      </p>
                    </div>
                    <div className="col-md-6 text-end mb-2">
                      <button
                        onClick={handleRefreshDigiData}
                        className="btn btn-success"
                      >
                        Refresh Data
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-12 text-end mb-2 mt-2">
                      <button
                        onClick={handleRefreshDigiData}
                        className="btn btn-success"
                      >
                        Refresh Data
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className="bankTableDetailsData">
                        <h4 style={{ color: "#6E12F9", fontSize: 17 }}>
                          Last fetched date:{" "}
                          {merchantReport ? merchantReport.updated_at : "-"}
                        </h4>
                        <table className="tableRowAdjustCustom">
                          <tr>
                            <th>Message</th>
                            <td>
                              {merchantReport?.message !== null &&
                              merchantReport?.message !== undefined
                                ? merchantReport.message
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <th>Has Delinquency</th>
                            <td>
                              {merchantReport?.hasDelinquency !== null &&
                              merchantReport?.hasDelinquency !== undefined
                                ? merchantReport.hasDelinquency
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Number of Overdue Messages</th>
                            <td>
                              {merchantReport?.countOverdue !== null &&
                              merchantReport?.countOverdue !== undefined
                                ? merchantReport.countOverdue
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Amount in Overdue</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalAmountOverdue !== null &&
                              merchantReport?.totalAmountOverdue !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalAmountOverdue
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Highest Amount in Overdue</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.maxAmountOverdue !== null &&
                              merchantReport?.maxAmountOverdue !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.maxAmountOverdue
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Number of Check Bounce Messages</th>
                            <td>
                              {merchantReport?.countChqBounce !== null &&
                              merchantReport?.countChqBounce !== undefined
                                ? merchantReport.countChqBounce
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Amount in Cheque Bounces</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalAmountChqBounce !== null &&
                              merchantReport?.totalAmountChqBounce !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalAmountChqBounce
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Highest Amount in Cheque Bounces</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.maxAmountChqBounce !== null &&
                              merchantReport?.maxAmountChqBounce !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.maxAmountChqBounce
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Number of Successful Cheque Deposits</th>
                            <td>
                              {merchantReport?.countSuccessfulChqDeposit !==
                                null &&
                              merchantReport?.countSuccessfulChqDeposit !==
                                undefined
                                ? merchantReport.countSuccessfulChqDeposit
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Amount in Successful Cheque Deposits</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalAmountSuccessfulChqDeposit !==
                                null &&
                              merchantReport?.totalAmountSuccessfulChqDeposit !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalAmountSuccessfulChqDeposit
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Number of Unique Case Overdue Messages</th>
                            <td>
                              {merchantReport?.uniqueCasesOverdue !== null &&
                              merchantReport?.uniqueCasesOverdue !== undefined
                                ? merchantReport.uniqueCasesOverdue
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Amount in Unique Case Overdue</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalUniqueOverdueAmount !==
                                null &&
                              merchantReport?.totalUniqueOverdueAmount !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalUniqueOverdueAmount
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Cheque Bounce Rate</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.bounceRate !== null &&
                              merchantReport?.bounceRate !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.bounceRate
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Distributor Txn With</th>
                            <td>
                              {merchantReport?.distributorsTxnWith !== null &&
                              merchantReport?.distributorsTxnWith !== undefined
                                ? merchantReport.distributorsTxnWith
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Bank's UPI Used</th>
                            <td>
                              {merchantReport?.banksUPIUsed !== null &&
                              merchantReport?.banksUPIUsed !== undefined
                                ? merchantReport.banksUPIUsed
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>UPI Apps </th>
                            <td>
                              {merchantReport?.UPIApps !== null &&
                              merchantReport?.UPIApps !== undefined
                                ? merchantReport.UPIApps
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Red Flag Apps Used </th>
                            <td>
                              {merchantReport?.redFlagAppsUsed !== null &&
                              merchantReport?.redFlagAppsUsed !== undefined
                                ? merchantReport.redFlagAppsUsed
                                : "N/A"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Debit Amount in 24 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalDebitAmount24m !== null &&
                              merchantReport?.totalDebitAmount24m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalDebitAmount24m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Credit Amount in 24 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalCreditAmount24m !== null &&
                              merchantReport?.totalCreditAmount24m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalCreditAmount24m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Debit Transaction in 24 Months</th>
                            <td>
                              {merchantReport?.debitTransactionCount24m !==
                                null &&
                              merchantReport?.debitTransactionCount24m !==
                                undefined
                                ? merchantReport.debitTransactionCount24m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Credit Transaction in 24 Months</th>
                            <td>
                              {merchantReport?.creditTransactionCount24m !==
                                null &&
                              merchantReport?.creditTransactionCount24m !==
                                undefined
                                ? merchantReport.creditTransactionCount24m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Avg Monthly Debit Amount in 24 Months</th>
                            <td>
                              {" "}
                              ₹{" "}
                              {merchantReport?.avgMonthlyDebitAmount24m !==
                                null &&
                              merchantReport?.avgMonthlyDebitAmount24m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyDebitAmount24m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          {/* <tr>
                          <th>Avg Monthly Credit Amount of 24 Month</th>
                          <td>
                          {merchantReport?.avgMonthlyDebitAmount24m !== null && merchantReport?.avgMonthlyDebitAmount24m !== undefined
                              ? formatNumberWithCommasAndDecimals(merchantReport.avgMonthlyDebitAmount24m)
                              : "0"}
                          </td>
                        </tr> */}

                          <tr>
                            <th>Avg Monthly Credit Amount of 24 Months</th>
                            <td>
                              {" "}
                              ₹{" "}
                              {merchantReport?.avgMonthlyCreditAmount24m !==
                                null &&
                              merchantReport?.avgMonthlyCreditAmount24m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyCreditAmount24m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Debit Amount in 12 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalDebitAmount12m !== null &&
                              merchantReport?.totalDebitAmount12m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalDebitAmount12m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Credit Amount in 12 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalCreditAmount12m !== null &&
                              merchantReport?.totalCreditAmount12m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalCreditAmount12m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Debit Transaction in 12 Months</th>
                            <td>
                              {merchantReport?.debitTransactionCount12m !==
                                null &&
                              merchantReport?.debitTransactionCount12m !==
                                undefined
                                ? merchantReport.debitTransactionCount12m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Credit Transaction in 12 Months</th>
                            <td>
                              {merchantReport?.creditTransactionCount12m !==
                                null &&
                              merchantReport?.creditTransactionCount12m !==
                                undefined
                                ? merchantReport.creditTransactionCount12m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Avg Monthly Debit Amount in 12 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.avgMonthlyDebitAmount12m !==
                                null &&
                              merchantReport?.avgMonthlyDebitAmount12m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyDebitAmount12m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Avg Monthly Credit Amount of 12 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.avgMonthlyCreditAmount12m !==
                                null &&
                              merchantReport?.avgMonthlyCreditAmount12m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyCreditAmount12m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Debit Amount in 6 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalDebitAmount6m !== null &&
                              merchantReport?.totalDebitAmount6m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalDebitAmount6m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Total Credit Amount in 6 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.totalCreditAmount6m !== null &&
                              merchantReport?.totalCreditAmount6m !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.totalCreditAmount6m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Debit Transaction in 6 Months</th>
                            <td>
                              {merchantReport?.debitTransactionCount6m !==
                                null &&
                              merchantReport?.debitTransactionCount6m !==
                                undefined
                                ? merchantReport.debitTransactionCount6m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>No. of Credit Transaction in 6 Months</th>
                            <td>
                              {merchantReport?.creditTransactionCount6m !==
                                null &&
                              merchantReport?.creditTransactionCount6m !==
                                undefined
                                ? merchantReport.creditTransactionCount6m
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Avg Monthly Debit Amount in 6 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.avgMonthlyDebitAmount6m !==
                                null &&
                              merchantReport?.avgMonthlyDebitAmount6m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyDebitAmount6m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Avg Monthly Credit Amount of 6 Months</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.avgMonthlyCreditAmount6m !==
                                null &&
                              merchantReport?.avgMonthlyCreditAmount6m !==
                                undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.avgMonthlyCreditAmount6m
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Months</th>
                            <td>
                              {merchantReport?.overallNumMonths !== null &&
                              merchantReport?.overallNumMonths !== undefined
                                ? merchantReport.overallNumMonths
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Total Debit Amount</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.overallTotalDebit !== null &&
                              merchantReport?.overallTotalDebit !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.overallTotalDebit
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Total Credit Amount</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.overallTotalCredit !== null &&
                              merchantReport?.overallTotalCredit !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.overallTotalCredit
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Debit Transactions</th>
                            <td>
                              {merchantReport?.overallDebitTransactions !==
                                null &&
                              merchantReport?.overallDebitTransactions !==
                                undefined
                                ? merchantReport.overallDebitTransactions
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Credit Transactions</th>
                            <td>
                              {merchantReport?.overallCreditTransactions !==
                                null &&
                              merchantReport?.overallCreditTransactions !==
                                undefined
                                ? merchantReport.overallCreditTransactions
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Average Debit Amount</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.overallAvgDebit !== null &&
                              merchantReport?.overallAvgDebit !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.overallAvgDebit
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>Overall Average Credit Amount</th>
                            <td>
                              ₹{" "}
                              {merchantReport?.overallAvgCredit !== null &&
                              merchantReport?.overallAvgCredit !== undefined
                                ? formatNumberWithCommasAndDecimals(
                                    merchantReport.overallAvgCredit
                                  )
                                : "0"}
                            </td>
                          </tr>

                          <tr>
                            <th>SMS File Path</th>
                            <td>
                              {merchantReport?.smsFilePath !== null &&
                              merchantReport?.smsFilePath !== undefined
                                ? merchantReport.smsFilePath
                                : "-"}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="ledger"
            role="tabpanel"
            aria-labelledby="ledger-tab"
          >
            {activeTab === "ledger" && (
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-md-12">
                  <div className="bankTableDetailsData">
                    <table className="tableRowAdjustCustom">
                      <tr>
                        <th>Total Sales last 12 months</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Total Sales ever</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Total Invoices Paid</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Vintage with Distributor</th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>Address as per Ledger</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Total Debit Entities</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Total Credit Entities</th>
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="field"
            role="tabpanel"
            aria-labelledby="field-tab"
          >
            {activeTab === "field" && (
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-md-12">
                  <div className="bankTableDetailsData">
                    <table className="tableRowAdjustCustom">

                      <tr>
                        <th>Shop/Business Name</th>
                        <td>{fieldData?.shopName !== null && fieldData?.shopName !== undefined
                            ? fieldData.shopName
                            : "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Establishment Year</th>
                        <td>{fieldData?.businessEstablishmentYear !== null && fieldData?.businessEstablishmentYear !== undefined
                            ? fieldData.businessEstablishmentYear
                            : "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Online order Application</th>
                        <td>{fieldData?.onlineOrderApplication !== null && fieldData?.onlineOrderApplication !== undefined
                            ? fieldData.onlineOrderApplication
                            : "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Shop Type</th>
                        <td>{fieldData?.shopBusinessType !== null && fieldData?.shopBusinessType !== undefined
                            ? fieldData.shopBusinessType
                            : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Shop Property</th>
                        <td>{fieldData?.shopProperty !== null && fieldData?.shopProperty !== undefined
                            ? fieldData.shopProperty
                            : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Average monthly purchase</th>
                        <td>{fieldData?.averageMonthlyPurchase !== null && fieldData?.averageMonthlyPurchase !== undefined
                            ? fieldData.averageMonthlyPurchase
                            : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Average monthly sales</th>
                        <td>{fieldData?.averageMonthlySales !== null && fieldData?.averageMonthlySales !== undefined
                            ? fieldData.averageMonthlySales
                            : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Shop Size</th>
                        <td>{fieldData?.shopSize !== null && fieldData?.shopSize !== undefined
                            ? fieldData.shopSize
                            : "N/A"}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="tab-pane fade"
            id="bankReport"
            role="tabpanel"
            aria-labelledby="bankReport-tab"
          >
            {activeTab === "bankReport" && <BankStatementReport />}
          </div>
        </div>
      </section>

      <Modal
        isOpen={statusModalIsOpen}
        onRequestClose={closeStatusModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeStatusModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <div className="modalUserImage">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="mb-3">
                <label htmlFor="userId" className="form-label">
                  Status:
                </label>
                <Select
                  placeholder="Select Search Type"
                  name="status"
                  defultValue={{
                    label: "Limit Not assigned",
                    value: "Limit Not assigned",
                  }}
                  options={StatusValueCredit}
                  value={StatusValueCredit.find(
                    (option) => option.value === formData.status
                  )}
                  onChange={(selectedOption) =>
                    setFormData({ ...formData, status: selectedOption.value })
                  }
                />
              </div>
              <label htmlFor="creditLimit" className="form-label">
                Credit Limit
              </label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                className="form-control"
                id="creditLimit"
                name="creditLimit"
                value={formData.creditLimit}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="financierId" className="form-label">
                Lender Name
              </label>
              <Select
                placeholder="Select"
                name="financierId"
                required
                options={lenderNameInfo}
                value={lenderNameInfo.find(
                  (option) => option.value === formData.financierId
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    financierId: selectedOption.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="financierId" className="form-label">
                Select Reason
              </label>
              {availableReasons.map((reason) => (
                <div key={reason}>
                  <input
                    type="checkbox"
                    name={reason}
                    checked={formData?.reasons?.includes(reason)} // Check if the reason is already selected
                    onChange={handleCheckboxChange}
                  />{" "}
                  <span>{reason}</span>
                </div>
              ))}
            </div>

            <div className="mb-3">
              <label htmlFor="financierId" className="form-label">
                Comment (Optional)
              </label>
              <div>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  placeholder="Enter Comment"
                  value={formData.comment}
                  onChange={handleCommentChange}
                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-success text-center">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default MerchantCreditReport;
