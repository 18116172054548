import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import { formatDateTimeString } from "../component/common";
import Swal from "sweetalert2";
import Table from "../pages/ReactTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
  },
};

function DistributorBrandList() {
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [formData, setFormData] = useState({
    brandName: "",
    logoUrl: "",
  });

  const userRole = localStorage.getItem("USER_ROLE");
  
  const validateForm = () => {
    const errors = {};
    if (!formData.brandName) {
      errors.brandName = "Please enter brand name";
    }
    if (!formData.logoUrl) {
      errors.logoUrl = "Please select brand image/logo";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64URL = reader.result;
        const logoURi = base64URL.split(",")[1];
        setFormData({
          ...formData,
          logoUrl: logoURi,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handelUpdateSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoadingOn(true);
      const AddBrandData = {
        name: formData.brandName,
        logo: formData.logoUrl,
      };
      console.log("Filter Brand Data", AddBrandData);
      const res = await postData(`users/addBrand`, AddBrandData);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Inserted successfully!",
        });
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleUpdateDropdownChange = (selectedValue) => {
  //     setFormData((prevData) => ({
  //         ...prevData,
  //         brandId: selectedValue,
  //         distributorId: selectedValue,
  //     }));
  // };

  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );

  const handleGotoPage = (page) => {
      if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
      let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    updateOrderStatus(pageIndex, pageSize, column.id, newOrderBy);
  };



  const handleInputChange = (e) => {
    if (merchantRefrenceType === 'BrandId') {
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
        setSearchBoxValue(value);
    } else {
        setSearchBoxValue(e.target.value);
    }
};



  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "BrandId") {
      filterData = {
        brandId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "BrandName") {
      filterData = {
        brandName: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`users/getBrands/nothing?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };

  const history = useHistory();

  const updateOrderStatus = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);
    let apiUrl = `users/getBrands/nothing?page=${page + 1}&pageSize=${pageSize}`;
    
      if (sortBy && orderBy) {
          apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
      }
    const tempData = await getData(apiUrl)
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };

  useEffect(() => {
    updateOrderStatus(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Brand Id",
        accessor: "id",
      },
      {
        Header: "Brand Name",
        accessor: "name",
      },
      {
        Header: "Brand Image",
        accessor: "logo",
        Cell: ({ cell: { value } }) => (
          <img
            src={value}
            alt="Logo"
            style={{ width: "75px", height: "50px" }}
          />
        ),
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => {
            console.log(cell.row.original);
          return (
            <>
              <span>
                {
                  cell.row.original.createdDate && formatDateTimeString(cell.row.original.createdDate)
                  }
              </span>
            </>
          );
        },
      },
    ],
    [pageIndex,pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    // state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const merchantOption = [
    { value: "BrandId", label: "Brand Id" },
    { value: "BrandName", label: "Brand Name" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Added Brand" />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Brands List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={merchantRefrenceType === "BrandId" ? 'tel' : 'text'}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //   setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            <div className="col-lg-4 text-end">
              {userRole === "Admin" || userRole === "Operation" ? 
              <button
                className="btn btnAddCredit"
                onClick={() => {
                    setIsOpen(true);
                }}
              >
                Add
              </button>
              : 
               null 
              } 
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
         <Table 
         columns={columns} 
         data={merchantUserData} 
         pageCount={pageCount}
         pageIndex={pageIndex}
         pageSize={pageSize}
         setPageIndex={setPageIndex}
         setPageSize={setPageSize}
         totalRecords={totalRecords}
         handleGotoPage={handleGotoPage}
         errorMessage={errorMessage} 
         handleSort={handleSort}
         sortBy={sortBy}
         orderBy={orderBy}
           />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Add Brand</h4>
        <div className="modalUserPaymentStatusAddBrand">
          <form onSubmit={handelUpdateSubmit}>
            <div className="row">
              {/* <div className="col-md-12 changeProductPro">
                                <div className="mb-3">
                                    <label htmlFor="brandId" className="form-label">
                                        Select Brand<span>*</span>
                                    </label>
                                    <Select
                                        placeholder="Select"
                                        name="brandId"
                                        options={brandListData}
                                        value={brandListData.find(option => option.value === formData.brandId)}
                                        onChange={(selectedOption) => {
                                            handleUpdateDropdownChange(selectedOption.value);
                                        }}
                                    />
                                    {errors.brandId && (
                                        <div className="text-danger">{errors.brandId}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12 changeProductPro">
                                <div className="mb-3">
                                    <label htmlFor="distributorId" className="form-label">
                                        Distributor Id<span>*</span>
                                    </label>
                                    <Select
                                        placeholder="Select"
                                        name="distributorId"
                                        options={distributorListData}
                                        value={distributorListData.find(option => option.value === formData.distributorId)}
                                        onChange={(selectedOption) => {
                                            handleUpdateDropdownChange(selectedOption.value)
                                        }
                                        }
                                    />
                                    {errors.distributorId && (
                                        <div className="text-danger">{errors.distributorId}</div>
                                    )}
                                </div>
                            </div> */}

              <div className="col-md-12 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="brandName" className="form-label">
                    Brand Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Brand Name"
                    className="form-control"
                    id="brandName"
                    name="brandName"
                    value={formData.brandName}
                    onChange={handleUpdateChange}
                    aria-describedby="textHelp"
                    // required={true}
                  />
                  {errors.brandName && (
                    <div className="text-danger">{errors.brandName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="logoUrl" className="form-label">
                    Brand Image<span>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image"
                    placeholder="Enter"
                    className="form-control"
                    name="logoUrl"
                    onChange={handleLogoChange}
                    aria-describedby="textHelp"
                  />
                  {errors.logoUrl && (
                    <div className="text-danger">{errors.logoUrl}</div>
                  )}
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-success text-center">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default DistributorBrandList;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
