import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useRowSelect,
} from "react-table";
import {
  FaEye,
  FaSearch,
  FaRegEye,
  FaFileCsv,
  FaFileDownload,
  FaRegTimesCircle,
  FaIdCard,
  FaIdCardAlt,
  FaEnvelope,
  FaDownload,
} from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { formatDateTimeString } from "../component/common";
import TopShowMessage from "../component/TopShowMessage";
import { CSVLink } from "react-csv";
import TablePagination from "../component/TablePagination";
import { QueryClientProvider, QueryClient } from "react-query";
import DownloadButton from "./DownloadButton";
import Swal from "sweetalert2";
import Table from "../pages/ReactTable"

const queryClient = new QueryClient();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
  },
};

function MerchantTable() {
  const [merchantUserData, setMerchantUserData] = useState();
  const [merchantActiveStatus, setMerchantActiveStatus] = useState();
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [priceGridIsOpen, setPriceGridIsOpen] = React.useState(false);
  const [financierModalIsOpen, setfinancierModalIsOpen] = React.useState(false);
  const [pricingUserId, setPricingUserId] = React.useState();
  const [financierUserId, setFinancierUserId] = React.useState();
  const [showAleart, setshowAleart] = useState(false);
  const [showAleartRemark, setshowAleartRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [IsAccept, setIsAccept] = useState(false);
  const [IsReject, setIsReject] = useState(false);
  const [IsSuspended, setIsSuspended] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [merchantUserId, setMerchantUserId] = useState();
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [filterDataInfo, setIsFilterDataInfo] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [pricingGridData, setPricingGridData] = useState([]);
  const [financierData, setFinancierData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedFinancierRowIndex, setSelectedFinancierRowIndex] =
    useState(null);
  const [selectedPricingId, setSelectedPricingId] = useState(null);
  const [selectedFinancierId, setSelectedFinancierId] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const userRole = localStorage.getItem("USER_ROLE");

  const handleCheckboxChange = (index, gridId) => {
    setSelectedRowIndex(index);
    setSelectedPricingId(gridId);
  };

  const handleFinancierCheckboxChange = (index, financierId) => {
    setSelectedFinancierRowIndex(index);
    setSelectedFinancierId(financierId);
  };

  const Accepthandle = () => {
    setIsReject(false);
    setIsAccept(true);
    setIsSuspended(false);
    setshowAleart(false);
    setshowAleartRemark(true);
  };
  const Rejecthandle = () => {
    setIsAccept(false);
    setIsReject(true);
    setIsSuspended(false);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const Suspendedhandel = () => {
    setIsAccept(false);
    setIsReject(false);
    setIsSuspended(true);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setPriceGridIsOpen(false);
  };
  const closeFinancierModal = () => {
    setIsOpen(false);
    setfinancierModalIsOpen(false);
  };
  let selectRef = null;
  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setSelectedOption(null);
    setSelectedOptionStatus(null);
    setMerchantActiveStatus("");
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getMerchantData();
  };

  const statusName = { 1: "Active", 2: "Inactive", 12: "Suspended" };

  const history = useHistory();

  async function getPricingGridData() {
    setIsLoadingOn(true);
    const tempData = await getData("loan/getLoanPricingGrid");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setPricingGridData(tempData.data);
    }
  }

  async function getFinancierData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getFinancierDeatails/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      const filteredData = tempData.data.records.filter((item) => item.deleted == 0);
      console.log("FilteredData", filteredData);
      setFinancierData(filteredData);
    }
  }

  const handleInputChange = (e) => {
    // alert("zmhfgsdhsdf");
    if (merchantRefrenceType === 'Mobile No.') {
      const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };

  

  const getMerchantData = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);
    let apiUrl = `users/getMerchants/1?page=${page + 1}&pageSize=${pageSize}`;

    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    const tempData = await postData(apiUrl)
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";

    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getMerchantData(pageIndex, pageSize, column.id, newOrderBy);
  };
  useEffect(() => {
    getPricingGridData();
    getFinancierData();
    getMerchantData(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);


  const searchManualData = async () => {
    let filterData = {};
    if (merchantRefrenceType === "Merchant Ref No.") {
      filterData = {
        userShopId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "Merchant Ref No." && merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
        userShopId: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Mobile No.") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Mobile No." && merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
        mobileNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "merchantName") {
      filterData = {
        merchantName: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "merchantName" && merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
        merchantName: searchBoxValue,
      };
    }
    else if (merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
      };
    }
    else {
      // alert("Please select any one option");
    }
    setIsLoadingOn(true);
    const queryParams = new URLSearchParams(filterData).toString();
    const tempData = await postData(`users/getMerchants/1?${queryParams}`);
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      });
    }
  };

  const updateMerchantStatus = async () => {
    let filterData = {
      userId: merchantUserId,
      status: approvalStatus,
      remark: remark,
    };

    setIsLoadingOn(true);
    const tempData = await postData("users/updateUserStatus", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setIsOpen(false);
      setShowTopMessage(true);
      let tempMerchantData = [];
      if (merchantUserData) {
        for (let i = 0; i < merchantUserData.length; i++) {
          const element = merchantUserData[i];
          // console.log(" element ", element);
          if (merchantUserId == element.userId) {
            element.status = approvalStatus;
            element.statusName = statusName[`${approvalStatus}`];
          }
          tempMerchantData.push(element);
        }
      }
      getMerchantData(filterDataInfo);
      setMerchantUserData(tempMerchantData);
      setTimeout(() => {
        setShowTopMessage(false);
      }, 3000);
    }
  };

  const downloadDumpData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("userShop/getExcelUserShop/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      const downloadLink = document.createElement("a");
      downloadLink.href = tempData.data.Location;
      downloadLink.target = "_blank";
      downloadLink.rel = "noopener noreferrer";
      downloadLink.click();
    }
  };

  const displayMerchantInfo = (userId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/merchantDetails?userId=" + userId;
    } else {
      history.push({
        pathname: "/merchantDetails",
        state: { userId: userId },
      });
    }
  };

  const statusModalOpen = (userId) => {
    setMerchantUserId(userId);
    setIsOpen(true);
  };

  const openModalPriceGrid = (userId) => {
    setPricingUserId(userId);
    setPriceGridIsOpen(true);
  };

  const openModalFinancierGrid = (userId) => {
    setFinancierUserId(userId);
    setfinancierModalIsOpen(true);
  };

  const handleAddPricingGrid = async () => {
    if (pricingUserId && selectedPricingId) {
      let filterData = {
        loanPricingGridId: selectedPricingId,
        userId: pricingUserId,
      };
      setIsLoadingOn(true);
      const tempData = await postData(
        "users/updateUsersLoanPricingGrid",
        filterData
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setPriceGridIsOpen(false);
        setShowTopMessage(true);
        setTimeout(() => {
          setShowTopMessage(false);
        }, 3000);
      }
    } else {
      alert("Please Select Pricing Grid");
    }
  };
  const handleSendEmail = async (row) => {
    const { email } = row;

    const result = await Swal.fire({
      title: "Send Email",
      html: `
        <input type="text" id="orderid" class="swal2-input" placeholder="Enter Order ID">
      `,
      showCancelButton: true,
      confirmButtonText: "Send",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        const orderid = Swal.getPopup().querySelector("#orderid").value;
        if (!orderid) {
          Swal.showValidationMessage("Please enter Order ID");
        }
        return { orderid };
      },
    });

    if (result.isConfirmed) {
      try {
        const response = await postData("documents/sendRepaymetMail", {
          orderId: result.value.orderid,
        });

        if (response.statusCode == 200) {
          Swal.fire({
            icon: "success",
            title: "Email Sent",
            text: "The email has been sent successfully!",
          });
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${response.errors.msg}`,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter valid Order ID",
        });
        console.error("Error:", error);
      }
    }
  };
  const handleAddFinancierData = async () => {
    if (financierUserId && selectedFinancierId) {
      let filterData = {
        financierId: selectedFinancierId,
        userId: financierUserId,
      };
      console.log(filterData);
      setIsLoadingOn(true);
      const tempData = await postData(
        "users/updateUserFinancierDetails",
        filterData
      );
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setfinancierModalIsOpen(false);
        setShowTopMessage(true);
        setTimeout(() => {
          setShowTopMessage(false);
        }, 3000);
      }
    } else {
      // alert("Please Select Pricing Grid");
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Mer.Ref Number",
        accessor: "merchantReferenceNumber",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.merchantReferenceNumber}
                  onClick={() => displayMerchantInfo(cell.row.original.userId)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.merchantReferenceNumber}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Mobile",
        accessor: "mobileNumber",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Shop Name",
        accessor: "shopName",
      },
      // {
      //   Header: "Sanction Limit",
      //   accessor: "mobileNumber",
      // },
      // {
      //   Header: "Available Limit",
      //   accessor: "name",
      // },
      // {
      //   Header: "FOS Name",  
      //   accessor: "fosName",
      // },
      //   {
      //     Header: 'Pricing Grid',
      //     accessor: 'pricingGridId',
      //   },
      // {
      //   Header: "Pricing Grid",
      //   accessor: "pricingGrid",
      //   Cell: ({ cell }) => {
      //     return (
      //       <>
      //         <div className="loanIdDownloadBtnDiv">
      //           <button
      //             className="btn btn-sm someprobtn view-log-detail"
      //             title="View details"
      //             value={cell.row.original.merchantReferenceNumber}
      //             onClick={() => {
      //               if (userRole === "Admin" || userRole === "Operation") {
      //                 openModalPriceGrid(cell.row.original.userId);
      //               }
      //             }}
      //           >
      //             {/* <span style={{ fontWeight: "600" }}>1</span> */}
      //             <span style={{ fontWeight: "600" }}>
      //               {cell.row.original.pricingGridId}
      //             </span>
      //           </button>
      //         </div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: "Add Financier",
      //   accessor: "nameNBFC",
      //   Cell: ({ cell }) => {
      //     // console.log("Row Data++++", cell.row.original);

      //     const { nameNBFC } = cell.row.original;
      //     return (
      //       <>
      //         <div className="loanIdDownloadBtnDiv">
      //           <button
      //             className="btn btn-sm someprobtn view-log-detail"
      //             title="View details"
      //             value={cell.row.original.nameNBFC}
      //             onClick={() => {
      //               if (userRole === "Admin" || userRole === "Operation") {
      //                 openModalFinancierGrid(cell.row.original.userId);
      //               }
      //             }}
      //           >
      //             <span style={{ fontWeight: "600" }}>
      //               {nameNBFC ? nameNBFC : "Add"}
      //             </span>
      //           </button>
      //         </div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: "Onboarded As",
      //   accessor: "onboardedAs",
      // },
      {
        Header: "Onboarding date",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.createdAt &&
                formatDateTimeString(cell.row.original.createdAt)}
            </>
          );
        },
      },
      {
        Header: "Onboarding Status",
        accessor: "onboardingStatusName",
        Cell: ({ cell }) => {
          const rowData = cell.row.original;
          return (
            <h7>
              {rowData.onboardingStatusName}
            </h7>
          );
        },
      },
      {
        Header: "Limit Status",
        accessor: "creditLimit",
        Cell: ({ cell }) => {
          const rowData = cell.row.original;
          return (
            <h7>
              {rowData.creditLimitStatus}
            </h7>
          );
        },
      },
      // {
      //   Header: "Application Form",
      //   accessor: "Application_form",
      //   Cell: ({ cell }) => {
      //     const { userId } = cell.row.original;

      //     return (
      //       <>
      //         {userRole === "Admin" || userRole === "Operation" ? (
      //           <DownloadButton userId={userId} />
      //         ) : null}
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: "MITC",
      //   accessor: "sanctionPdfPath",
      //   Cell: ({ value }) => (
      //     <button
      //       style={{
      //         fontSize: 22,
      //         color: "#6E12F9",
      //         border: "none",
      //         background: "none",
      //       }}
      //       onClick={() => window.open(value, "_blank")}
      //     >
      //       <FaEye />
      //     </button>
      //   ),
      // },
      // {
      //   Header: "KFS",
      //   accessor: "sanctionKfsPdfPath",
      //   Cell: ({ value }) => (
      //     <button
      //       style={{
      //         fontSize: 22,
      //         color: "#6E12F9",
      //         border: "none",
      //         background: "none",
      //       }}
      //       onClick={() => window.open(value, "_blank")}
      //     >
      //       <FaRegEye />
      //     </button>
      //   ),
      // },
      // {
      //   Header: "KYC",
      //   accessor: "Documents",
      //   Cell: ({ row }) => (

      //     <div>
      //       <button
      //         className="btn btn-success"
      //         style={{
      //           fontSize: "9px",
      //           fontWeight: 900,
      //         }}
      //         onClick={() => {
      //           if (row.original.PanCard) {
      //             window.open(row.original.PanCard, "_blank");
      //           } else {
      //             Swal.fire({
      //               icon: "error",
      //               text: "PAN card not available!!",
      //             });
      //           }
      //         }}
      //       >
      //         <FaDownload /> PAN
      //       </button>
      //       <button
      //         className="btn btn-success"
      //         style={{
      //           fontSize: "10px",
      //           fontWeight: 900,
      //           cursor: "pointer",
      //           marginBottom: 2,
      //           marginTop: 2,
      //         }}
      //         onClick={() => {
      //           if (row.original.AadhaarCard) {
      //             window.open(row.original.AadhaarCard, "_blank");
      //           } else {
      //             Swal.fire({
      //               icon: "error",
      //               text: "Aadhaar Card not available!!",
      //             });
      //           }
      //         }}
      //       >
      //         Aadhar
      //       </button>
      //       <button
      //         className="btn btn-success"
      //         style={{
      //           fontSize: "10px",
      //           fontWeight: 900,
      //           cursor: "pointer",
      //           marginBottom: 2,
      //           marginTop: 2,
      //         }}
      //         onClick={() => {
      //           if (row.original.UdyamAadhar) {
      //             window.open(row.original.UdyamAadhar, "_blank");
      //           } else {
      //             Swal.fire({
      //               icon: "error",
      //               text: "Udyam Aadhar not available!!",
      //             });
      //           }
      //         }}
      //       >
      //         Udyam
      //       </button>
      //       {/* <button 
      //         className='btn btn-success' 
      //         style={{
      //           fontSize: "10px",
      //           fontWeight: 900,
      //           cursor: "pointer",
      //         }}
      //         onClick={() => window.open(row.original.TradeCertificate, '_blank')}
      //       >
      //         Trade
      //       </button> */}
      //     </div>
      //   ),
      // },
      // {
      //   Header: "Send Email",
      //   accessor: "",
      //   Cell: ({ row }) => (
      //     <button
      //       style={{
      //         fontSize: 22,
      //         color: "#6E12F9",
      //         border: "none",
      //         background: "none",
      //       }}
      //       onClick={() => handleSendEmail(row.original)}
      //     >
      //       <FaEnvelope />
      //     </button>
      //   ),
      // },
      // {
      //   Header: "Status",
      //   accessor: "statusName",
      //   Cell: (cell) => {
      //     const status = cell.row.original.statusName;
      //     const checkOrderPermit = cell.row.original.onboardingStatusName;
      //     return (
      //       <>
      //         {checkOrderPermit == "Fully Onboarded" ? (
      //           <div className="text-center">
      //             <button
      //               onClick={() => {
      //                 if (userRole === "Admin" || userRole === "Operation") {
      //                   statusModalOpen(cell.row.original.userId);
      //                 }
      //               }}
      //               className={`btn btnMangStatus 
      //           ${
      //             status == "Active"
      //               ? "btn-success"
      //               : status == "Suspended"
      //               ? "btn-warning"
      //               : "btn-danger"
      //           }`}
      //               value={cell.row.original.statusName}
      //             >
      //               {cell.row.original.statusName
      //                 ? cell.row.original.statusName
      //                 : "-"}
      //             </button>
      //           </div>
      //         ) : (
      //           <div className="text-center">
      //             <button
      //               onClick={() => {
      //                 if (userRole === "Admin" || userRole === "Operation") {
      //                   statusModalOpen(cell.row.original.userId);
      //                 }
      //               }}
      //               className={`btn btn-info btnMangStatus`}
      //               value={cell.row.original.statusName}
      //             >
      //               Incomplete
      //             </button>
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      // },
    ],
    [pageIndex, pageSize]
  );

  const StatusOption = [
    { value: "0", label: "All" },
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];
  const merchantOption = [
    { value: "merchantName", label: "Merchant Name" },
    { value: "Merchant Ref No.", label: "Merchant Ref No." },
    { value: "Mobile No.", label: "Mobile No." },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Updated Details." />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Merchant Table</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                ref={(ref) => {
                  selectRef = ref;
                }}
                placeholder="Select Status"
                name="role"
                options={StatusOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setSelectedOptionStatus(e);
                  setMerchantActiveStatus(e.value);
                }}
              />
            </div>

            <div className="col-md-2">
              <Select
                placeholder="Mer.. Ref No."
                name="role"
                // ref={selectRef}
                options={merchantOption}
                value={selectedOption}
                onChange={(e) => {
                  setSelectedOption(e);
                  setMerchantRefrenceType(e.value);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                className="searchInputField"
                ref={inputRef}
                type={merchantRefrenceType === "Mobile No." ? 'number' : 'text'}
                onChange={handleInputChange}
                placeholder="Enter Details"
              />
            </div>
            <div className="col-md-2 text-center" onClick={searchManualData}>
              <button className="searchTableData">
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            {(userRole === "Admin" || userRole === "Operation") && (
              <>
                {/* <div className="col-md-1 pl-0">
                  {merchantUserData &&
                    (userRole === "Admin" || userRole === "Operation") && (
                      <button
                        id="CSV"
                        type="button"
                        className="font-weight-bold download-csv transformBtn acceptDataCss"
                      >
                        <CSVLink
                          data={merchantUserData}
                          filename={"MerchantData.csv"}
                          target="_blank"
                        >
                          <span className="svg-icon svgSetting">
                            <FaFileCsv />
                          </span>
                        </CSVLink>
                      </button>
                    )}
                </div> */}
                <div className="col-md-2 pl-0 text-center">
                  <button
                    onClick={downloadDumpData}
                    id="CSV"
                    type="button"
                    className="font-weight-bold download-csv transformBtn acceptDataCss"
                  >
                    <a href="javascript:void(0)">
                      <FaFileDownload />
                    </a>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>


      {/* {merchantUserData && <Table columns={columns} data={merchantUserData} />} */}
      {merchantUserData &&
        <Table
          columns={columns}
          data={merchantUserData}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage}
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />}
      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Merchant Status</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">
              <button
                onClick={Accepthandle}
                htmlFor="option-1"
                className={
                  IsAccept ? "acceptlisth option option-1" : "option option-1"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("1");
                  }}
                >
                  Active
                </span>
              </button>
              <button
                onClick={Rejecthandle}
                htmlFor="option-2"
                className={
                  IsReject ? "rejectlisth option option-2" : "option option-2"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("2");
                  }}
                >
                  Inactive
                </span>
              </button>
              <button
                onClick={Suspendedhandel}
                htmlFor="option-3"
                className={
                  IsSuspended
                    ? "suspendedMng option option-3"
                    : "option option-3"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("12");
                  }}
                >
                  Suspended
                </span>
              </button>
            </div>
          </div>

          <div className="remardi">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Remark</label>
              <textarea
                onChange={(event) => {
                  // console.log(" testing ", event.target.value);
                  setRemark(event.target.value);
                }}
                value={remark}
                className="form-control textacont"
                rows="3"
              ></textarea>
              {showAleart ? (
                <p className="requiredRemark">Remark is required...</p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={updateMerchantStatus} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}

      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={priceGridIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Pricing Grid</h4>
        <div className="modalUserStatus">
          <div className="tablePricingGrid">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Cash Discount</th>
                  <th>Initiation Fees</th>
                  <th>Value Added Service</th>
                  <th>Facilitation Fees</th>
                  <th>Rate Of Interest</th>
                  <th>Tenure</th>
                  <th>Processing Fees</th>
                  <th>Transaction Fees</th>
                  <th>Grace Period</th>
                  <th>Stale Period</th>
                  <th>Staus</th>
                </tr>
              </thead>
              <tbody>
                {pricingGridData &&
                  pricingGridData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          name="pricingGrid"
                          checked={selectedRowIndex === index}
                          onChange={() => handleCheckboxChange(index, item.id)}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.cashDiscount}</td>
                      <td>{item.initiationFees}</td>
                      <td>{item.valueAddedService}</td>
                      <td>{item.facilitationFees}</td>
                      <td>{item.rateOfInterest}</td>
                      <td>{item.tenure}</td>
                      <td>{item.processingFees}</td>
                      <td>{item.transactionFees}</td>
                      <td>{item.gracePeriod}</td>
                      <td>{item.stalePeriod}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={handleAddPricingGrid} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}

      {/* For Financier */}
      <Modal
        isOpen={financierModalIsOpen}
        onRequestClose={closeFinancierModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeFinancierModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Add Financier</h4>
        <div className="modalUserStatus">
          <div className="tablePricingGrid">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>NBFC Name</th>
                  <th>Address</th>
                  <th>Grievance Name</th>
                  <th>Grievance Email</th>
                  <th>Grievance Mobile</th>
                  <th>Grievance Address</th>
                </tr>
              </thead>
              <tbody>
                {financierData &&
                  financierData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          name="financierId"
                          checked={selectedFinancierRowIndex === index}
                          onChange={() =>
                            handleFinancierCheckboxChange(index, item.id)
                          }
                        />
                      </td>
                      <td>{item.nameNBFC}</td>
                      <td>{item.addressNBFC}</td>

                      <td>{item.grivenceName}</td>
                      <td>{item.grivenceEmail}</td>
                      <td>{item.grivenceMobileno}</td>
                      <td>{item.grivenceAddress}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button
              onClick={handleAddFinancierData}
              className="btn btn-success"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MerchantTable;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

