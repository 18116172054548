import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import { formatDateTimeSecond } from "../component/common";
import Swal from "sweetalert2";
import Table from "../pages/ReactTable";

const userRole = localStorage.getItem("USER_ROLE");
console.log("Test i UseEffect", userRole);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    maxWidth: "50%",
  },
};

function EmployeeList() {
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [userTypeDetailsData, setUserTypeDetailsData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [designationIdInfo, setDesignationIdInfo] = useState("");
  const [reportingManagerNameValue, setReportingManagerNameValue] = useState(
    []
  );
  const [userRoleData, setUserRoleData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    mobileNumber: "",
    password: "",
    roleId: "",
    designation: "",
    department: "",
    status: "",
    employeeCode: "",
    reportingManagerName: "",
    reportingManager: "",
    panelAccess: "",
  });
  console.log("formData+++++", formData);
  const [emailVerified, setEmailVerified] = useState(false);
  const [departmentsLookupData, setDepartmentsLookupData] = useState([]);

  console.log("userRoleData++++++", userRoleData);

  useEffect(() => {
    if (userRoleData) {
      setFormData({
        name: userRoleData.name || "",
        emailId: userRoleData.emailId || "",
        mobileNumber: userRoleData.mobileNumber || "",
        // password: userRoleData.password || "",
        roleId: userRoleData.roleId || "",
        department: userRoleData.department || "",
        designation: userRoleData.designation || "",
        employeeCode: userRoleData.employeeCode || "",
        status: userRoleData.status || "",
        reportingManagerName: userRoleData.reportingManagerName || "",
        reportingManager: userRoleData.reportingManager || "",
        panelAccess: userRoleData.panelAccess || "",
      });
    }
  }, [userRoleData]);

  const handleInputChange = (e) => {
    if (merchantRefrenceType === "MobileNumber") {
      const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };

  const handelUpdateSubmit = async (event) => {
    event.preventDefault();
    if (!formData.name) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Employee Name!",
      });
    }
    if (!formData.emailId) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Employee Email Id!",
      });
    }
    if (!formData.mobileNumber) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Enter Mobile Number!",
      });
    }
    if (!formData.roleId) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select User Role!",
      });
    }
     let tempData = { 
      employeeId:"",
      ...formData };

    if (formData.password) {
      tempData.password = formData.password;
    }
    if (
      formData &&
      userRoleData &&
      userRoleData.name &&
      userRoleData.name.length > 0
    ) {
      tempData.employeeId = userRoleData.id;
      setIsLoadingOn(true);
      const res = await postData(`employee/updateEmployee`, tempData);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: res.message || "Data updated successfully!",
        });
        updateOrderStatus();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: res?.data?.errors?.msg || "Something went wrong",
        });
      }
    } else {
      setIsLoadingOn(true);
      const res = await postData(`employee/createEmployee`, formData);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        setIsOpen(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: res.message || "Data inserted successfully!",
        });
        updateOrderStatus();
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: res?.data?.errors?.msg || "Something went wrong",
        });
      }
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // if (name === "emailId") {
    //   if (value.includes("@daqi.in")) {
    //     setEmailVerified(true);
    //   } else {
    //     setEmailVerified(false);
    //   }
    // }
  };

  // useEffect(() => {
  //   if (
  //     userRoleData &&
  //     userRoleData.emailId &&
  //     userRoleData.emailId.length > 0 &&
  //     userRoleData.emailId.includes("@daqi.in")
  //   ) {
  //     setEmailVerified(true);
  //   } else {
  //     setEmailVerified(false);
  //   }
  // }, [userRoleData]);

  const deleteData = async (id) => {
    const data = {
      employeeId: id,
    };
    try {
      const response = await postData(`employee/deleteEmployee`, data);
      if (response.statusCode === 200) {
        return true;
      } else {
        console.error("Error response from server:", response);
        return true;
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      return false;
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const success = await deleteData(id);
        if (success) {
          updateOrderStatus();
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
        } else {
          Swal.fire("Failed!", "Your data could not be deleted.", "error");
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );
  const handleGotoPage = (page) => {
      if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
      let newOrderBy = "asc";
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    updateOrderStatus(pageIndex, pageSize, column.id, newOrderBy);
  };
  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "UserName") {
      filterData = {
        name: searchBoxValue,
      };
    } else if (merchantRefrenceType === "MobileNumber") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`employee/getEmployee?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      });
    }
  };

  const history = useHistory();

  const updateOrderStatus = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);
    let apiUrl = `employee/getEmployee?page=${page + 1}&pageSize=${pageSize}`;
    
      if (sortBy && orderBy) {
          apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
      }
    const tempData = await getData(apiUrl)
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    }
  };

  const getReportingManager = async (designationId, departmentId) => {
    let tepmReportingData = [];
  
    const fetchReportingManagerData = async (url) => {
      setIsLoadingOn(true);
      const tempData = await getData(url);
      setIsLoadingOn(false);
  
      if (tempData.statusCode === 200) {
        tepmReportingData = tempData.data.map((element) => ({
          label: element.name,
          value: element.id,
        }));
        setReportingManagerNameValue(tepmReportingData);
      }
    };
  
    if (designationId) {
      await fetchReportingManagerData(`employee/getReportingManager/na/?designationId=${designationId}`);
    } else if (departmentId && designationIdInfo) {
      await fetchReportingManagerData(`employee/getReportingManager/na/?designationId=${designationIdInfo}&departmentId=${departmentId}`);
    } else if (departmentId) {
      await fetchReportingManagerData(`employee/getReportingManager/na/?departmentId=${departmentId}`);
    } else {
      await fetchReportingManagerData("employee/getReportingManager/params");
    }
  };
  

   const getLookupData = async () => {
    let tempDesignations = [];
    let tempDepartments = [];

    try {
      setIsLoadingOn(true);
      const getLookupResult = await getData("lookup?attribute=Department,Designation");
      
      if (getLookupResult.statusCode == 200) {
        tempDesignations = getLookupResult.data
          .filter(lookup => lookup.attribute === "Designation")
          .map(designation => {
            return {
              label: designation.code,
              value: designation.id,
            }
          });
        
        tempDepartments = getLookupResult.data
          .filter(lookup => lookup.attribute === "Department")
          .map(department => {
            return {
              label: department.code,
              value: department.id,
            }
          });
        
        setUserTypeDetailsData(tempDesignations);
        setDepartmentsLookupData(tempDepartments);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Something went wrong to fetch departments and designations data.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong to fetch departments and designations data.",
      });
    } finally {
      setIsLoadingOn(false);
    }
    
  };

  useEffect(() => {
    updateOrderStatus(pageIndex, pageSize, sortBy, orderBy);
    getReportingManager();
    getLookupData();
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const closeModal = () => {
    setUserRoleData({});
    setIsOpen(false);
    setEmailVerified(false);
  };

  const roleOptions = [
    { label: "Admin", value: 3 },
    { label: "Operation", value: 5 },
    { label: "Sales", value: 6 },
    { label: "Credit (Tentative)", value: 7 },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
      },
      {
        Header: "Email Id",
        accessor: "emailId",
      },
      {
        Header: "User Role",
        accessor: "role",
      },
      {
        Header: "Designation",
        accessor: "designationName",
      },
      {
        Header: "Department",
        accessor: "departmentName",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ cell }) => {
          const { id } = cell.row.original;
          return (
            <>
              <div className="loanIdDownloadBtnDiv d-flex justify-content-evenly align-items-center">
                <button
                  className="btn-sm btn-warning "
                  onClick={() => {
                    setUserRoleData(cell.row.original);
                    setIsOpen(true);
                  }}
                >
                  <span>Edit</span>
                </button>

                <button
                  className="btn-sm btn-danger"
                  onClick={() => {
                    handleDelete(id);
                  }}
                >
                  <span>Delete</span>
                </button>
              </div>
            </>
          );
        },
      },
    ],
    [pageIndex,pageSize]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const merchantOption = [
    { value: "MobileNumber", label: "Mobile Number" },
    { value: "UserName", label: "Name" },
  ];

  const activeDropStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const panelAccessStatus = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Added Brand" />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Employee List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={merchantRefrenceType === "MobileNumber" ? "tel" : "text"}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //     setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            <div className="col-lg-4 text-end">
              {userRole === "Admin" && (
                <button
                  className="btn btnAddCredit"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
          <Table 
          columns={columns} 
          data={merchantUserData} 
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage} 
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">
          {userRoleData && userRoleData.name && userRoleData.name.length > 0
            ? "Edit Employee"
            : "Add Employee"}
        </h4>
        <div className="modalUserPaymentStatusAddBrand">
          <form onSubmit={handelUpdateSubmit}>
            <div className="row">
              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter User Name"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleUpdateChange}
                    aria-describedby="textHelp"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="emailId" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter User Email"
                    className={`form-control`}
                    id="emailId"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleUpdateChange}
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="mobileNumber" className="form-label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    className="form-control"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleUpdateChange}
                    aria-describedby="textHelp"
                    required
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="roleId" className="form-label">
                    Role
                  </label>
                  <Select
                    placeholder="Select"
                    name="roleId"
                    required
                    options={roleOptions}
                    value={roleOptions.find(
                      (option) => option.value === formData.roleId
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, roleId: selectedOption.value })
                    }
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="designation" className="form-label">
                    Designation
                  </label>
                  <Select
                    placeholder="Select"
                    name="designation"
                    required
                    options={userTypeDetailsData}
                    value={userTypeDetailsData.find(
                      (option) => option.value == formData.designation
                    )}
                    onChange={(selectedOption) =>{
                      setFormData({ ...formData, designation: selectedOption.value })
                      getReportingManager(selectedOption.value);
                      setDesignationIdInfo(selectedOption.value);
                    }
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="department" className="form-label">
                    Department
                  </label>
                  <Select
                    placeholder="Select Department"
                    id="department"
                    name="department"
                    required
                    options={departmentsLookupData}
                    value={departmentsLookupData.find(
                      (option) => option.value == formData.department
                    )}
                    onChange={(selectedOption) =>{
                      setFormData({ ...formData, department: selectedOption.value })
                      getReportingManager(null, selectedOption.value);
                    }
                    }
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="employeeCode" className="form-label">
                    Employee Code
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Employee Code"
                    className="form-control"
                    id="employeeCode"
                    name="employeeCode"
                    value={formData.employeeCode}
                    onChange={handleUpdateChange}
                    aria-describedby="textHelp"
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="reportingManagerName" className="form-label">
                    Reporting Manager Name
                  </label>
                  <Select
                    placeholder="Select"
                    name="reportingManagerName"
                    required
                    options={reportingManagerNameValue}
                    value={reportingManagerNameValue.find(
                      (option) => option.value === formData.reportingManager
                    )}
                    onChange={(selectedOption) => {
                      setFormData({
                        ...formData,
                        reportingManager: selectedOption.value,
                        reportingManagerName: selectedOption.label,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <Select
                    placeholder="Select"
                    name="status"
                    required
                    options={activeDropStatus}
                    value={activeDropStatus.find(
                      (option) => option.value === formData.status
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, status: selectedOption.value })
                    }
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="panelAccess" className="form-label">
                    Panel Access
                  </label>
                  <Select
                    placeholder="Select"
                    name="panelAccess"
                    required
                    options={panelAccessStatus}
                    value={panelAccessStatus.find(
                      (option) => option.value === formData.panelAccess
                    )}
                    onChange={(selectedOption) =>
                      setFormData({
                        ...formData,
                        panelAccess: selectedOption.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-md-6 changeProductPro">
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleUpdateChange}
                    aria-describedby="textHelp"
        
                  />
                </div>
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-success text-center">
                  {userRoleData &&
                  userRoleData.name &&
                  userRoleData.name.length > 0
                    ? "Update"
                    : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default EmployeeList;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = (val) => typeof val !== "number";
